import React from 'react';
import { Box, Card, CardActions, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Category } from '../../../../types/states/CategoryState';
import { apiUrl } from '../../../../main.tsx';

interface CategoryCardProps {
  category: Category;
  index: number;
  onEdit: (category: Category) => void;
  onDelete: (category: Category) => void;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
  index,
  onEdit,
  onDelete,
}) => {
  return (
    <Card
      elevation={2}
      sx={{
        backgroundColor: '#1a1a1a',
        height: '100%',
        maxWidth: '280px',
        margin: '0 auto',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        transform: index % 2 === 0 ? 'rotate(-1deg)' : 'rotate(1deg)',
        '&:hover': {
          transform:
            index % 2 === 0
              ? 'translateY(-4px) rotate(0deg)'
              : 'translateY(-4px) rotate(0deg)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          '& .card-icon': {
            transform: 'scale(1.1)',
          },
          '& .card-title': {
            color: 'primary.main',
          },
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            mb: 2,
          }}
        >
          <Box
            className="card-icon"
            sx={{
              mb: 2,
              transition: 'transform 0.3s ease-in-out',
              '& > svg': {
                fontSize: 56,
              },
            }}
          >
            {category.imageUri ? (
              <img
                src={`${apiUrl}/files/${category.imageUri}`}
                alt={category.name}
                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
              />
            ) : (
              <BrokenImageIcon sx={{ fontSize: 56, color: 'primary.main' }} />
            )}
          </Box>
          <Typography
            className="card-title"
            variant="h6"
            component="h2"
            align="center"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              letterSpacing: '0.05em',
              transition: 'color 0.3s ease-in-out',
              fontSize: '1.1rem',
              textTransform: 'uppercase',
              mb: 1,
            }}
          >
            {category.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            align="center"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.875rem',
              lineHeight: 1.4,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minHeight: '3.6em',
            }}
          >
            {category.shortDescription}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.875rem',
              lineHeight: 1.4,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minHeight: '3.6em',
            }}
          >
            {category.description}
          </Typography>
        </Box>
        <CardActions sx={{ justifyContent: 'center', gap: 1, mt: 'auto' }}>
          <IconButton
            onClick={() => onEdit(category)}
            color="primary"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => onDelete(category)}
            color="error"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  );
};
