import React, { FC, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NewEventStepProps } from '../index.tsx';
import { Category } from '../../../../types/states/CategoryState.ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCategories,
  selectCategories,
} from '../../../../store/slices/categorySlice';
import { AppDispatch } from '../../../../store/store.ts';
import { EventCategoryCard } from './CategoryCard.tsx';

export interface ServicesStepProps extends NewEventStepProps {
  setEventCategories: (categories: Category[]) => void;
  eventCategories: Category[];
  setSelectedServices: (services: any) => void;
  selectedServices: any;
}

const ServicesStep: FC<ServicesStepProps> = ({
  handleNext,
  handleBack,
  setEventCategories,
  eventCategories,
  setSelectedServices,
  selectedServices,
}: ServicesStepProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector(selectCategories);
  const [categorySelections, setCategorySelections] = useState<{
    [categoryId: string]: {
      subCategories: any[];
      categoryServices: any[];
    };
  }>(selectedServices || {});

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  // Initialize selections from props
  useEffect(() => {
    if (selectedServices && Object.keys(selectedServices).length > 0) {
      setCategorySelections(selectedServices);
    }
  }, [selectedServices]);

  const handleCategorySelection = (categoryId: string, selections: any) => {
    const newSelections = {
      ...categorySelections,
      [categoryId]: selections,
    };
    setCategorySelections(newSelections);
    console.log('newSelections', newSelections);
    // Update selected categories and services
    const selectedCategories = categories.filter(
      (cat) =>
        newSelections[cat.id] &&
        (newSelections[cat.id].subCategories.some(
          (sub: any) => sub.isSelected
        ) ||
          newSelections[cat.id].categoryServices.some(
            (service: any) => service.isSelected
          ))
    );
    console.log('selectedCategories', selectedCategories);
    setEventCategories(selectedCategories);
    setSelectedServices(newSelections);
  };

  const isNextButtonDisabled =
    Object.keys(categorySelections).length === 0 ||
    !Object.values(categorySelections).some(
      (selection) =>
        selection.subCategories.some((sub: any) => sub.isSelected) ||
        selection.categoryServices.some((service: any) => service.isSelected)
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {categories.map((category, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={category.id}>
            <EventCategoryCard
              category={category}
              index={index}
              onSelectionChange={handleCategorySelection}
              savedSelections={categorySelections[category.id]}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4,
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 120 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isNextButtonDisabled}
          sx={{ minWidth: 120 }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default ServicesStep;
