import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';

interface Option {
  id: string;
  name: string;
}

interface MultiSelectChipsProps {
  options: Option[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  label: string;
}

export const MultiSelectChips: React.FC<MultiSelectChipsProps> = ({
  options,
  selectedValues,
  onChange,
  label,
}) => {
  const handleToggle = (value: string) => {
    const currentIndex = selectedValues.indexOf(value);
    const newValues = [...selectedValues];

    if (currentIndex === -1) {
      newValues.push(value);
    } else {
      newValues.splice(currentIndex, 1);
    }

    onChange(newValues);
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        p: 2,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'rgba(212, 175, 55, 0.2)',
        background: 'rgba(0, 0, 0, 0.05)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          borderColor: 'rgba(212, 175, 55, 0.4)',
          background: 'rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          mb: 2,
          color: '#D4AF37',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
      <Grid container spacing={1}>
        {options.map((option) => {
          const isSelected = selectedValues.includes(option.id);
          return (
            <Grid item key={option.id}>
              <Chip
                label={option.name}
                onClick={() => handleToggle(option.id)}
                color={isSelected ? 'primary' : 'default'}
                variant={isSelected ? 'filled' : 'outlined'}
                sx={{
                  fontSize: '0.9rem',
                  height: 32,
                  '& .MuiChip-label': {
                    px: 1.5,
                  },
                  '&.MuiChip-filled': {
                    bgcolor: '#D4AF37',
                    color: '#000',
                  },
                  '&:hover': {
                    bgcolor: isSelected ? '#D4AF37' : 'rgba(0, 0, 0, 0.08)',
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
