import { useSearchParams } from 'react-router-dom';

export interface MessagePageParams {
  mode?: 'newChat' | 'view';
  companyId?: string;
  companyName?: string;
  listingId?: string;
  listingName?: string;
  isCompany?: boolean;
}

export function useMessagePageParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: MessagePageParams = {
    mode: (searchParams.get('mode') as 'newChat' | 'view') || undefined,
    companyId: searchParams.get('companyId') || undefined,
    companyName: searchParams.get('companyName') || undefined,
    listingId: searchParams.get('listingId') || undefined,
    listingName: searchParams.get('listingName') || undefined,
    isCompany: searchParams.get('isCompany') === 'true',
  };

  const clearParams = () => {
    setSearchParams({});
  };

  return {
    params,
    clearParams,
    setSearchParams,
  };
}
