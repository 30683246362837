import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  createCategory,
  selectCurrentCategory,
  updateCategory,
} from '../../../../store/slices/categorySlice';
import {
  CreateCategoryRequest,
  UpdateCategoryRequest,
} from '../../../../types/requests/CategoryRequests';
import { ImageCropInput } from '../../../../components/inputs/ImageCropInput';

interface CategoryFormProps {
  onClose: () => void;
}

export const CategoryForm: React.FC<CategoryFormProps> = ({ onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentCategory = useSelector(selectCurrentCategory);
  const [imageId, setImageId] = useState<string | null>(
    currentCategory?.imageUri || null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentCategory?.name || '',
      shortDescription: currentCategory?.shortDescription || '',
      description: currentCategory?.description || '',
    },
  });
  const onSubmit = async (data: any) => {
    try {
      const categoryData = {
        ...data,
        imageUri: imageId,
      };

      if (currentCategory) {
        const updateRequest: UpdateCategoryRequest = {
          id: currentCategory.id,
          ...categoryData,
          imageUri: imageId,
        };
        await dispatch(updateCategory(updateRequest)).unwrap();
      } else {
        const createRequest: CreateCategoryRequest = categoryData;
        createRequest.imageUri = imageId;
        await dispatch(createCategory(createRequest)).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Failed to save category:', error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography variant="h6" gutterBottom>
        {currentCategory ? 'Edit Category' : 'Create New Category'}
      </Typography>

      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Name"
          {...register('name', {
            required: 'Name is required',
            maxLength: {
              value: 100,
              message: 'Name cannot exceed 100 characters',
            },
          })}
          error={!!errors.name}
          helperText={errors.name?.message as string}
        />

        <TextField
          fullWidth
          label="Short Description"
          {...register('shortDescription', {
            required: 'Short description is required',
            maxLength: {
              value: 200,
              message: 'Short description cannot exceed 200 characters',
            },
          })}
          error={!!errors.shortDescription}
          helperText={errors.shortDescription?.message as string}
        />

        <Box>
          <FormLabel sx={{ mb: 1, display: 'block' }}>Category Image</FormLabel>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ImageCropInput
              aspectRatio={1 / 1}
              onImageChange={setImageId}
              initialImageId={currentCategory?.imageUri}
              sx={{ width: '100%', maxWidth: '300px' }}
            />
          </Box>
        </Box>

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          {...register('description', {
            required: 'Description is required',
          })}
          error={!!errors.description}
          helperText={errors.description?.message as string}
        />

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            {currentCategory ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
