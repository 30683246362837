import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubCategoryForm } from './SubCategoryForm.tsx';

interface SubCategoryDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SubCategoryDialog: React.FC<SubCategoryDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SubCategoryForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};
