import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Header from '../../../components/header';
import { SectionHeader } from '../../../components/dashboard/SectionHeader';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { CategoryList } from './components/CategoryList';
import { CategoryGrid } from './components/CategoryGrid';
import { CategoryDialog } from './components/CategoryDialog';
import { DeleteConfirmationDialog } from './components/DeleteConfirmationDialog';
import { Category } from '../../../types/states/CategoryState';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import {
  clearCurrentCategory,
  fetchCategories,
} from '../../../store/slices/categorySlice.ts';

type ViewMode = 'list' | 'grid';

const AdminCategories: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(
    null
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const handleViewModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode | null
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleAddCategory = () => {
    dispatch(clearCurrentCategory());
    setIsDialogOpen(true);
  };

  const handleEditCategory = () => {
    setIsDialogOpen(true);
  };

  const handleDeleteCategory = (category: Category) => {
    setCategoryToDelete(category);
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    dispatch(clearCurrentCategory());
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCategoryToDelete(null);
  };

  return (
    <Box>
      <Header
        title="Categories"
        subtitle="Create, edit, and manage your categories"
        showBackButton={true}
      />
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <SectionHeader
            title="Manage Categories"
            actions={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                  >
                    <ToggleButton value="list" aria-label="list view">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid view">
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddCategory}
                >
                  Add Category
                </Button>
              </Box>
            }
            icon={undefined}
          />
        </Box>

        {viewMode === 'list' ? (
          <CategoryList
            onEdit={handleEditCategory}
            onDelete={handleDeleteCategory}
          />
        ) : (
          <CategoryGrid
            onEdit={handleEditCategory}
            onDelete={handleDeleteCategory}
          />
        )}

        <CategoryDialog open={isDialogOpen} onClose={handleCloseDialog} />

        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          category={categoryToDelete}
        />
      </Container>
    </Box>
  );
};
export default AdminCategories;
