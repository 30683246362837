import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  fetchCategories,
  selectCategories,
} from '../../../../store/slices/categorySlice';
import {
  createCategoryService,
  selectCurrentCategoryService,
  updateCategoryService,
} from '../../../../store/slices/categoryServiceSlice.ts';
import {
  CreateCategoryServiceRequest,
  UpdateCategoryServiceRequest,
} from '../../../../types/requests/CategoryServiceRequests.ts';

interface SubCategoryFormProps {
  onClose: () => void;
}

export const CategoryServiceForm: React.FC<SubCategoryFormProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentCategoryService = useSelector(selectCurrentCategoryService);
  const categories = useSelector(selectCategories);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(
    currentCategoryService?.categoryId || ''
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryId: currentCategoryService?.categoryId || '',
      name: currentCategoryService?.name || '',
      groupName: currentCategoryService?.groupName || '',
      shortDescription: currentCategoryService?.shortDescription || '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const categoryServiceData = {
        ...data,
        categoryId: selectedCategoryId, // Ensure categoryId is explicitly set
      };

      if (currentCategoryService) {
        const updateRequest: UpdateCategoryServiceRequest = {
          id: currentCategoryService.id,
          ...categoryServiceData,
        };
        await dispatch(updateCategoryService(updateRequest)).unwrap();
      } else {
        const createRequest: CreateCategoryServiceRequest = {
          ...categoryServiceData,
        };
        await dispatch(createCategoryService(createRequest)).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Failed to save sub-category:', error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography variant="h6" gutterBottom>
        {currentCategoryService
          ? 'Edit Category Service'
          : 'Create New Category Service'}
      </Typography>

      <Stack spacing={3}>
        <FormControl fullWidth error={!!errors.categoryId}>
          <InputLabel id="category-select-label">Category *</InputLabel>
          <Controller
            name="categoryId"
            control={control}
            rules={{ required: 'Please select a category first' }}
            render={({ field }) => (
              <Select
                {...field}
                labelId="category-select-label"
                label="Category *"
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategoryId(e.target.value as string);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select a category</em>
                </MenuItem>
                {categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>
            {(errors.categoryId?.message as string) ||
              (!selectedCategoryId &&
                'Please select a category to enable other fields')}
          </FormHelperText>
        </FormControl>

        <TextField
          fullWidth
          label="Name"
          disabled={!selectedCategoryId}
          {...register('name', {
            required: 'Name is required',
            maxLength: {
              value: 100,
              message: 'Name cannot exceed 100 characters',
            },
          })}
          error={!!errors.name}
          helperText={errors.name?.message as string}
        />

        <TextField
          fullWidth
          label="Group Name"
          disabled={!selectedCategoryId}
          {...register('groupName')}
          // error={!!errors.groupName}
          // helperText={errors.groupName?.message as string}
        />

        <TextField
          fullWidth
          label="Short Description"
          disabled={!selectedCategoryId}
          {...register('shortDescription')}

          // error={!!errors.shortDescription}
          // helperText={errors.shortDescription?.message as string}
        />

        {/*THIS CODE MAKES SHORT DESCRIPTION MANDATORY*/}
        {/*{...register('shortDescription', {*/}
        {/*    required: 'Short description is required', maxLength: {*/}
        {/*        value: 200, message: 'Short description cannot exceed 200 characters'*/}
        {/*    }*/}
        {/*})}*/}

        {/*{selectedCategoryId && (<Box>*/}
        {/*        <FormLabel sx={{mb: 1, display: 'block'}}>Category Image</FormLabel>*/}
        {/*        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>*/}
        {/*            <ImageCropInput*/}
        {/*                aspectRatio={1 / 1}*/}
        {/*                onImageChange={setImageId}*/}
        {/*                initialImageId={currentCategoryService?.imageUri}*/}
        {/*                sx={{width: '100%', maxWidth: "300px"}}*/}
        {/*            />*/}
        {/*        </Box>*/}
        {/*    </Box>)}*/}

        {/*<TextField*/}
        {/*    fullWidth*/}
        {/*    label="Description"*/}
        {/*    multiline*/}
        {/*    rows={4}*/}
        {/*    disabled={!selectedCategoryId}*/}
        {/*    {...register('description', {*/}
        {/*        required: 'Description is required'*/}
        {/*    })}*/}
        {/*    error={!!errors.description}*/}
        {/*    helperText={errors.description?.message as string}*/}
        {/*/>*/}

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            {currentCategoryService ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
