import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { searchListings } from '../store/slices/listingSlice';
import i18next from '../utilis/i18next';
import Grid from '@mui/material/Grid2';
import { ListingCard } from '../pages/ListingsPage/ListingCard.tsx';
import {
  fetchCategories,
  selectCategories,
} from '../store/slices/categorySlice';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../store/slices/subCategorySlice';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../store/slices/subCategoryServiceSlice';
import { v4 as uuidv4 } from 'uuid';

const goldGradientStyle = {
  background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const InfiniteScrolls = () => {
  const dispatch = useDispatch<AppDispatch>();
  const listings = useSelector((state: RootState) => state.listings.listings);
  const searchParams = useSelector(
    (state: RootState) => state.listings.searchParams
  );
  const status = useSelector((state: RootState) => state.listings.status);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const observerRef = useRef<HTMLDivElement>(null);
  const [autoLoadCount, setAutoLoadCount] = useState(0);
  const MAX_AUTO_LOADS = 2;

  // Generate a random GUID on component mount
  useEffect(() => {
    // Initial load with random GUID seed
    const seed = uuidv4();
    dispatch(
      searchListings({
        pageNumber: 1,
        pageSize: 9,
        seed: seed,
      })
    );
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    dispatch(fetchSubCategoryServices());
  }, [dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          status !== 'pending' &&
          searchParams.hasNextPage
        ) {
          const newSearchParams = { ...searchParams };
          newSearchParams.pageNumber += 1;
          console.log('Load more listings', newSearchParams);
          dispatch(searchListings(newSearchParams));
          setAutoLoadCount((prev) => prev + 1);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [dispatch, status, searchParams]);

  if (status === 'pending' && listings.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        pt: { xs: 8, md: 12 },
        pb: { xs: 8, md: 12 },
        overflow: 'hidden',
        minHeight: '100vh',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={4} alignItems="center">
          <Typography
            variant="h2"
            align="center"
            sx={{
              ...goldGradientStyle,
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 600,
              mb: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
          >
            {i18next.t('Views.HomePage.InfiniteScroll.HeadLine')}
          </Typography>

          <Container maxWidth="xl">
            <Box sx={{ py: 4 }}>
              <Container maxWidth="lg" sx={{ py: 4 }}>
                <Box>
                  <Grid container spacing={1}>
                    {listings.map((listing) => (
                      <Grid size={{ xs: 12, sm: 12, md: 4 }} key={listing.id}>
                        <ListingCard
                          listing={listing}
                          categories={categories}
                          subCategories={subCategories}
                          subCategoryServices={subCategoryServices}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {searchParams.hasNextPage && (
                    <Box mt={4} display="flex" justifyContent="center">
                      {autoLoadCount >= MAX_AUTO_LOADS ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newSearchParams = { ...searchParams };
                            newSearchParams.pageNumber += 1;
                            dispatch(searchListings(newSearchParams));
                          }}
                          sx={{
                            background:
                              'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
                            color: 'black',
                            '&:hover': {
                              background:
                                'linear-gradient(45deg, #FFD700 30%, #D4AF37 90%)',
                            },
                          }}
                        >
                          {i18next.t('Views.HomePage.InfiniteScroll.Button')}
                        </Button>
                      ) : (
                        <div
                          ref={observerRef}
                          style={{ height: '20px', width: '100%' }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Container>
            </Box>
          </Container>
        </Stack>
      </Container>
    </Box>
  );
};

export default InfiniteScrolls;
