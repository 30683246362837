import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Header from '../../../components/header';
import { SectionHeader } from '../../../components/dashboard/SectionHeader';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { SubCategoryDialog } from './components/SubCategoryDialog.tsx';
import { useDispatch } from 'react-redux';
import {
  clearCurrentCategory,
  fetchCategories,
} from '../../../store/slices/categorySlice';
import { AppDispatch } from '../../../store/store';
import { SubCategoryList } from './components/SubCategoryList.tsx';
import { SubCategoryGrid } from './components/SubCategoryGrid.tsx';
import { DeleteSubCategoryConfirmationDialog } from './components/DeleteSubCategoryConfirmationDialog.tsx';
import {
  clearCurrentSubCategory,
  fetchSubCategories,
} from '../../../store/slices/subCategorySlice.ts';
import { SubCategory } from '../../../types/states/SubCategoryState.ts';

type ViewMode = 'list' | 'grid';

const AdminSubCategories: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [subCategoryToDelete, setSubCategoryToDelete] =
    useState<SubCategory | null>(null);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
  }, []);

  const handleViewModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode | null
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleAddSubCategory = () => {
    dispatch(clearCurrentSubCategory());
    setIsDialogOpen(true);
  };

  const handleEditSubCategory = () => {
    setIsDialogOpen(true);
  };

  const handleDeleteSubCategory = (subCategory: SubCategory) => {
    setSubCategoryToDelete(subCategory);
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    dispatch(clearCurrentCategory());
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSubCategoryToDelete(null);
  };

  return (
    <Box>
      <Header
        title="Sub-Categories"
        subtitle="Create, edit, and manage your sub-categories"
        showBackButton={true}
      />

      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <SectionHeader
            title="Manage Sub-Categories"
            actions={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                  >
                    <ToggleButton value="list" aria-label="list view">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid view">
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddSubCategory}
                >
                  Add Sub-Category
                </Button>
              </Box>
            }
            icon={undefined}
          />
        </Box>

        {viewMode === 'list' ? (
          <SubCategoryList
            onEdit={handleEditSubCategory}
            onDelete={handleDeleteSubCategory}
          />
        ) : (
          <SubCategoryGrid
            onEdit={handleEditSubCategory}
            onDelete={handleDeleteSubCategory}
          />
        )}

        <SubCategoryDialog open={isDialogOpen} onClose={handleCloseDialog} />

        <DeleteSubCategoryConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          subCategory={subCategoryToDelete}
        />
      </Container>
    </Box>
  );
};
export default AdminSubCategories;
