import { CompanyStyleVariant } from '../../store/slices/companySlice.ts';

export interface CompaniesState {
  companies: Company[];
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
  currentCompany: Company | null;
  stylePreference: CompanyStyleVariant;
}

export enum CompanyStatus {
  Active = 100,
  AwaitingApproval = 200,
  Rejected = 250,
  Deleted = 300,
}

// export interface Link {
//     url: string;
//     label: string;
// }

export interface Company {
  id: string;
  name: string;
  shortDescription: string;
  description: string;

  organizationNumber?: number;

  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  youtubeUrl?: string;

  logoImageUri: string;
  bannerImageUri: string;
  createdById: string;
  createdAt: string;
  updatedAt: string;

  // Company Status
  status: CompanyStatus;

  // Stripe Integration
  stripeCustomerId?: string;

  // Active Subscription Details
  activeSubscriptionId?: string;
  activeProductId?: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;

  // Purchase History References
  subscriptionHistory: string[];
  oneTimePurchaseHistory: string[];

  // Current Resource Quotas
  listingTokens: number;
  eventClickTokens_Subscription: number;
  eventClickTokens_Extras: number;
}
