import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Stack, Toolbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCurrentListing,
  createListing,
  fetchListing,
  selectCurrentListing,
  updateListing,
} from '../../../store/slices/listingSlice.ts';
import { ListingView } from '../../../components/listings/ListingView.tsx';
import { CompanySideCard } from '../../../components/listings/CompanySideCard.tsx';
import { AppDispatch } from '../../../store/store.ts';
import {
  clearCurrentCompany,
  fetchCompanyById,
} from '../../../store/slices/companySlice.ts';
import { fetchCategories } from '../../../store/slices/categorySlice.ts';
import { fetchSubCategories } from '../../../store/slices/subCategorySlice.ts';
import { ListingStatus } from '../../../types/states/ListingState.ts';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import { ImageCropInput } from '../../../components/inputs/ImageCropInput.tsx';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ImageView } from '../../../components/inputs/ImageView.tsx';

const CompanyListingPage: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');
  const listingId = searchParams.get('listingId');
  const mode = searchParams.get('mode');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentListing = useSelector(selectCurrentListing);
  const [localListing, setLocalListing] = useState<any>(
    mode === 'create'
      ? {
          name: '',
          shortDescription: '',
          description: '',
          price: 0,
          categoryId: '',
          subCategoryId: '',
          subCategoryServiceIds: [],
          bannerImageUri: '',
          cardImageUri: '',
          galleryImageIds: [],
          videoLinks: [],
        }
      : currentListing
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
    // Clear current listing when creating a new one
    if (mode === 'create') {
      dispatch(clearCurrentListing());
    } else if (listingId) {
      dispatch(fetchListing(listingId));
    }
    if (typeof companyId === 'string') {
      dispatch(fetchCompanyById(companyId));
    }
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    if (!companyId) {
      navigate('/');
      return;
    }

    // Cleanup function
    return () => {
      dispatch(clearCurrentCompany());
      dispatch(clearCurrentListing());
    };
  }, [mode, listingId, companyId, dispatch, navigate]);

  useEffect(() => {
    if (currentListing && mode !== 'create') {
      dispatch(fetchCompanyById(currentListing.companyId));
      setLocalListing({
        ...currentListing,
        categoryId: currentListing.categoryId || '',
        subCategoryId: currentListing.subCategoryId || '',
        subCategoryServiceIds: currentListing.subCategoryServiceIds || [],
        bannerImageUri: currentListing.bannerImageUri || '',
        cardImageUri: currentListing.cardImageUri || '',
        galleryImageIds: currentListing.galleryImageIds || [],
        videoLinks: currentListing.videoLinks || [],
      });
    }
  }, [currentListing, mode, dispatch]);

  const [modes, setToggleMode] = useState<'view' | 'edit'>(
    mode === 'create' ? 'edit' : (mode as 'view' | 'edit') || 'view'
  );

  const [showImageFields, setShowImageFields] = useState(true);

  const [validationErrors, setValidationErrors] = useState<{
    categoryId: string;
    subCategoryId: string;
    name: string;
    shortDescription: string;
  }>({
    categoryId: '',
    subCategoryId: '',
    name: '',
    shortDescription: '',
  });

  const handleListingChange = useCallback((updatedFields: any) => {
    console.log('Updated fields:', updatedFields);
    setLocalListing((prev) => ({
      ...prev,
      ...updatedFields,
    }));

    // Clear validation errors if fields are valid
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      if (updatedFields.categoryId) {
        newErrors.categoryId = '';
      }
      if (updatedFields.subCategoryId) {
        newErrors.subCategoryId = '';
      }
      if (updatedFields.name) {
        newErrors.name = '';
      }
      if (updatedFields.shortDescription) {
        newErrors.shortDescription = '';
      }
      return newErrors;
    });
  }, []);

  const listingViewProps = useMemo(
    () => ({
      mode: modes,
      listingId,
      companyId,
      onListingChange: handleListingChange,
      showImageFields,
      validationErrors,
    }),
    [
      modes,
      listingId,
      companyId,
      handleListingChange,
      showImageFields,
      validationErrors,
    ]
  );

  const handleBannerImageChange = (imageId: string | null) => {
    handleListingChange({
      bannerImageUri: imageId || '',
    });
  };

  const handleCardImageChange = (imageId: string | null) => {
    handleListingChange({
      cardImageUri: imageId || '',
    });
  };

  const handlePriceChange = (price: number) => {
    if (localListing) {
      const updatedListing = { ...localListing, price };
      setLocalListing(updatedListing);
    }
  };

  const handleSave = async (status: ListingStatus) => {
    const errors = {
      categoryId: '',
      subCategoryId: '',
      name: '',
      shortDescription: '',
    };

    if (!localListing.name) {
      errors.name = 'Name is required.';
    } else if (localListing.name.length < 3) {
      errors.name = 'Name must be at least 3 characters.';
    } else if (localListing.name.length > 50) {
      errors.name = 'Name must be less than 50 characters.';
    }

    if (!localListing.shortDescription) {
      errors.shortDescription = 'Short description is required.';
    } else if (localListing.shortDescription.length < 10) {
      errors.shortDescription =
        'Short description must be at least 10 characters.';
    } else if (localListing.shortDescription.length > 150) {
      errors.shortDescription =
        'Short description must be less than 150 characters.';
    }

    if (!localListing.categoryId) {
      errors.categoryId = 'Category is required.';
    }
    if (!localListing.subCategoryId) {
      errors.subCategoryId = 'Subcategory is required.';
    }

    setValidationErrors(errors);

    if (
      errors.categoryId ||
      errors.subCategoryId ||
      errors.name ||
      errors.shortDescription
    ) {
      return;
    }

    if (!localListing || !companyId) return;

    const listingData = {
      ...localListing,
      companyId,
      status,
      videoLinks: localListing.videoLinks.filter(
        (link: string) => link.trim() !== ''
      ),
    };
    console.log('Listing data:', listingData);

    try {
      if (modes === 'edit' && listingId) {
        await dispatch(updateListing({ ...listingData, id: listingId }));
        // Refetch the listing to get the updated data
        await dispatch(fetchListing(listingId));
      } else {
        const result = await dispatch(createListing(listingData));
        if (result.meta.requestStatus === 'fulfilled') {
          navigate(`/company/listings?companyId=${companyId}`);
        }
      }
      setToggleMode('view');
    } catch (error) {
      console.error('Error saving listing:', error);
    }
  };

  const toggleMode = () => {
    if (modes === 'edit') {
      // When exiting edit mode, refetch the listing to ensure we have the latest data
      if (listingId) {
        dispatch(fetchListing(listingId));
      }
    }
    setToggleMode(modes === 'view' ? 'edit' : 'view');
  };
  console.log('Rendering CompanyListingPage');
  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        {modes === 'edit' ? (
          <ImageCropInput
            initialImageId={
              isMobile
                ? localListing?.cardImageUri || ''
                : localListing?.bannerImageUri || ''
            }
            onImageChange={
              isMobile ? handleCardImageChange : handleBannerImageChange
            }
            aspectRatio={isMobile ? 1 : 3 / 1}
          />
        ) : (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: isMobile ? '300px' : '400px',
            }}
          >
            <ImageView
              fileId={
                isMobile
                  ? localListing?.cardImageUri
                  : localListing?.bannerImageUri
              }
              alt={isMobile ? 'Listing card image' : 'Listing banner'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              sx={{
                transition: 'all 0.3s ease',
                filter: 'brightness(0.9)',
                '&:hover': {
                  filter: 'brightness(1.1)',
                },
              }}
            />
            {/*<FileImage*/}
            {/*  fileId={*/}
            {/*    isMobile*/}
            {/*      ? localListing?.cardImageUri*/}
            {/*      : localListing?.bannerImageUri*/}
            {/*  }*/}
            {/*  forceUpdate={false}*/}
            {/*  alt={isMobile ? 'Listing card image' : 'Listing banner'}*/}
            {/*  style={{*/}
            {/*    width: '100%',*/}
            {/*    height: '100%',*/}
            {/*    objectFit: 'cover',*/}
            {/*  }}*/}
            {/*/>*/}
          </Box>
        )}
      </Box>

      <Box>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 12, md: 9 }}>
            <Box sx={{ position: 'sticky', top: '64px', zIndex: 1000, mb: 2 }}>
              <Box
                component={'div'}
                position="static"
                color="inherit"
                sx={{
                  borderBottom: '2px solid',
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  backdropFilter: 'blur(8px)', // background: 'rgba(0, 0, 0, 0.85)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    borderColor: '#D4AF37',
                    boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
                  },
                }}
              >
                <Container maxWidth="lg">
                  <Toolbar
                    sx={{
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Stack direction="row" spacing={2} sx={{}}>
                      {modes === 'edit' && (
                        <Button
                          variant={showImageFields ? 'contained' : 'outlined'}
                          onClick={() => setShowImageFields(!showImageFields)}
                          startIcon={<ImageIcon />}
                          color={showImageFields ? 'primary' : 'inherit'}
                          size="small"
                          sx={{
                            borderColor: 'rgba(212, 175, 55, 0.3)',
                            borderWidth: '1px',
                            py: 0.5,
                            px: 2,
                            fontSize: '0.875rem',
                            '&:hover': {
                              borderColor: '#D4AF37',
                              backgroundColor: 'rgba(212, 175, 55, 0.05)',
                            },
                          }}
                        >
                          Show Image Edit Options
                        </Button>
                      )}
                      <Button
                        variant={modes === 'view' ? 'contained' : 'outlined'}
                        onClick={toggleMode}
                        startIcon={
                          modes === 'view' ? <EditIcon /> : <CloseIcon />
                        }
                        color={modes === 'view' ? 'primary' : 'inherit'}
                        size="small"
                        sx={{
                          borderColor: 'rgba(212, 175, 55, 0.3)',
                          borderWidth: '1px',
                          py: 0.5,
                          px: 2,
                          fontSize: '0.875rem',
                          '&:hover': {
                            borderColor: '#D4AF37',
                            backgroundColor: 'rgba(212, 175, 55, 0.05)',
                          },
                        }}
                      >
                        {modes === 'view' ? 'Edit' : 'Cancel'}
                      </Button>
                      {modes === 'edit' && (
                        <>
                          <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => handleSave(ListingStatus.DRAFT)}
                            startIcon={<DraftsIcon />}
                            size="small"
                            sx={{
                              borderColor: 'rgba(212, 175, 55, 0.3)',
                              borderWidth: '1px',
                              py: 0.5,
                              px: 2,
                              fontSize: '0.875rem',
                              '&:hover': {
                                borderColor: '#D4AF37',
                                backgroundColor: 'rgba(212, 175, 55, 0.05)',
                              },
                            }}
                          >
                            Save as Draft
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleSave(ListingStatus.PENDING_APPROVAL)
                            }
                            startIcon={<SendIcon />}
                            size="small"
                            sx={{
                              py: 0.5,
                              px: 2,
                              fontSize: '0.875rem',
                            }}
                          >
                            Request Approval
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Toolbar>
                </Container>
              </Box>
            </Box>
            <Box sx={{ px: 3 }}>
              <ListingView {...listingViewProps} />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 3 }}>
            <CompanySideCard mode={modes} onPriceChange={handlePriceChange} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyListingPage;
