import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectCompanyStatus,
  selectCurrentCompany,
} from '../../store/slices/companySlice.ts';
import { selectCategories } from '../../store/slices/categorySlice.ts';
import { selectSubCategories } from '../../store/slices/subCategorySlice.ts';
import { selectCategoryServices } from '../../store/slices/categoryServiceSlice.ts';
import { selectSubCategoryServices } from '../../store/slices/subCategoryServiceSlice.ts';
import { ImageView } from '../inputs/ImageView.tsx';
import { selectCurrentListing } from '../../store/slices/listingSlice.ts';

interface CompanySideCardProps {
  mode: 'view' | 'edit';
  onPriceChange?: (price: number) => void;
}

export const CompanySideCard: React.FC<CompanySideCardProps> = ({
  mode,
  onPriceChange,
}) => {
  const company = useSelector(selectCurrentCompany);
  const companyStatus = useSelector(selectCompanyStatus);
  const listing = useSelector(selectCurrentListing);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const categoryServices = useSelector(selectCategoryServices);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const category = categories.find((cat) => cat.id === listing?.categoryId);
  const subCategory = subCategories.find(
    (subCat) => subCat.id === listing?.subCategoryId
  );
  const [localPrice, setLocalPrice] = useState<number>(listing?.price || 0);
  const navigate = useNavigate();

  const handleStartChat = () => {
    if (company?.name && listing?.id && listing?.name) {
      const params = new URLSearchParams({
        mode: 'newChat',
        companyName: company.name,
        listingId: listing.id,
        listingName: listing.name,
        isCompany: 'false',
      });
      navigate(`/messages?${params.toString()}`);
    }
  };

  useEffect(() => {
    if (listing?.price !== undefined) {
      setLocalPrice(listing.price);
    }
  }, [listing?.price]);

  const getCategoryServiceById = (id: string) => {
    return categoryServices.find((catService) => catService.id === id);
  };

  const getSubCategoryServiceById = (id: string) => {
    return subCategoryServices.find((subCatService) => subCatService.id === id);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = Number(event.target.value);
    if (!isNaN(newPrice)) {
      setLocalPrice(newPrice);
      onPriceChange?.(newPrice);
    }
  };

  const theme = useTheme();

  if (companyStatus === 'pending') {
    return (
      <Card
        sx={{
          width: '100%',
          background: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(10px)',
          border: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.2)',
          p: 2,
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color="primary">Loading company information...</Typography>
      </Card>
    );
  }

  if (!company) {
    return (
      <Card
        sx={{
          width: '100%',
          background: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(10px)',
          border: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.2)',
          p: 2,
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color="error">Company not found</Typography>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: '100%',
        background: 'rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(10px)',
        border: '2px solid',
        borderColor: 'rgba(212, 175, 55, 0.2)',
        transition: 'all 0.4s ease-in-out',
        '&:hover': {
          borderWidth: '3px',
          borderColor: 'rgba(212, 175, 55, 0.6)',
          boxShadow: '0px 8px 16px rgba(212, 175, 55, 0.3)',
          transform: 'translateY(-4px)',
        },
      }}
    >
      <CardContent>
        {/* Company Title */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: '#D4AF37',
            mb: 3,
            textAlign: 'center',
            textShadow: '0 2px 4px rgba(0,0,0,0.3)',
          }}
        >
          {company?.name}
        </Typography>

        {/* Company Logo */}
        <Box
          sx={{
            width: '100%',
            height: 200,
            mb: 3,
            overflow: 'hidden',
            borderRadius: 1,
            border: '1px solid rgba(212, 175, 55, 0.3)',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0))',
              pointerEvents: 'none',
            },
          }}
        >
          <ImageView
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'all 0.3s ease',
              filter: 'brightness(0.9)',
            }}
            fileId={company?.logoImageUri}
            alt={category?.name}
          />
        </Box>

        {/* Price Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 3,
          }}
        >
          {mode === 'edit' ? (
            <TextField
              fullWidth
              label="Price"
              type="number"
              value={localPrice}
              onChange={handlePriceChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  color: '#D4AF37',
                },
                '& .MuiInputLabel-root': {
                  color: '#D4AF37',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgba(212, 175, 55, 0.5)',
                  },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: '#D4AF37',
                  },
              }}
            />
          ) : (
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: '#D4AF37',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
              }}
            >
              {localPrice?.toLocaleString() || '0'} kr
            </Typography>
          )}
        </Box>

        {/* Social Media Links */}
        {(company?.websiteUrl ||
          company?.facebookUrl ||
          company?.instagramUrl ||
          company?.twitterUrl ||
          company?.youtubeUrl) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 1,
              mb: 3,
            }}
          >
            {company?.websiteUrl && (
              <Tooltip title="Visit Website">
                <IconButton
                  component="a"
                  href={company.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.8)',
                    '&:hover': {
                      color: '#D4AF37',
                      transform: 'translateY(-2px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <LanguageIcon />
                </IconButton>
              </Tooltip>
            )}
            {company?.facebookUrl && (
              <Tooltip title="Facebook">
                <IconButton
                  component="a"
                  href={company.facebookUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.8)',
                    '&:hover': {
                      color: '#D4AF37',
                      transform: 'translateY(-2px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <FacebookIcon />
                </IconButton>
              </Tooltip>
            )}
            {company?.instagramUrl && (
              <Tooltip title="Instagram">
                <IconButton
                  component="a"
                  href={company.instagramUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.8)',
                    '&:hover': {
                      color: '#D4AF37',
                      transform: 'translateY(-2px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              </Tooltip>
            )}
            {company?.twitterUrl && (
              <Tooltip title="Twitter">
                <IconButton
                  component="a"
                  href={company.twitterUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.8)',
                    '&:hover': {
                      color: '#D4AF37',
                      transform: 'translateY(-2px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <TwitterIcon />
                </IconButton>
              </Tooltip>
            )}
            {company?.youtubeUrl && (
              <Tooltip title="YouTube">
                <IconButton
                  component="a"
                  href={company.youtubeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.8)',
                    '&:hover': {
                      color: '#D4AF37',
                      transform: 'translateY(-2px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <YouTubeIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}

        {/* Description Section */}
        {company?.description && (
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                lineHeight: 1.6,
                textAlign: 'justify',
              }}
            >
              {company.description}
            </Typography>
          </Box>
        )}

        {/* Categories and Services Section */}
        {listing && (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'rgba(212, 175, 55, 0.9)',
                mb: 1,
                fontWeight: 600,
              }}
            >
              Services & Categories
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                mb: 3,
              }}
            >
              {/* Main Categories */}
              <Box sx={{ width: '100%', mb: 1 }}>
                {category && (
                  <Chip
                    label={category.name}
                    variant="outlined"
                    sx={{
                      borderColor: '#D4AF37',
                      borderWidth: '1.5px',
                      color: '#D4AF37',
                      height: '28px',
                      mr: 0.5,
                      fontWeight: 500,
                      '&:hover': {
                        borderColor: '#D4AF37',
                        backgroundColor: 'rgba(212, 175, 55, 0.08)',
                      },
                    }}
                  />
                )}
                {subCategory && (
                  <Chip
                    label={subCategory.name}
                    variant="outlined"
                    sx={{
                      borderColor: '#D4AF37',
                      borderWidth: '1.5px',
                      color: '#D4AF37',
                      height: '28px',
                      fontWeight: 500,
                      '&:hover': {
                        borderColor: '#D4AF37',
                        backgroundColor: 'rgba(212, 175, 55, 0.08)',
                      },
                    }}
                  />
                )}
              </Box>

              {/* Services */}
              {listing.categoryServiceIds?.map((serviceId) => {
                const service = getCategoryServiceById(serviceId);
                return (
                  service && (
                    <Chip
                      key={serviceId}
                      label={service.name}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderColor: '#D4AF37',
                        borderWidth: '1.5px',
                        color: '#D4AF37',
                        height: '24px',
                        fontWeight: 500,
                        '&:hover': {
                          borderColor: '#D4AF37',
                          backgroundColor: 'rgba(212, 175, 55, 0.08)',
                        },
                      }}
                    />
                  )
                );
              })}
              {listing.subCategoryServiceIds?.map((serviceId) => {
                const service = getSubCategoryServiceById(serviceId);
                return (
                  service && (
                    <Chip
                      key={serviceId}
                      label={service.name}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderColor: '#D4AF37',
                        borderWidth: '1.5px',
                        color: '#D4AF37',
                        height: '24px',
                        fontWeight: 500,
                        '&:hover': {
                          borderColor: '#D4AF37',
                          backgroundColor: 'rgba(212, 175, 55, 0.08)',
                        },
                      }}
                    />
                  )
                );
              })}
            </Box>
          </Box>
        )}

        {/* Chat Button */}
        <Button
          fullWidth
          variant="contained"
          startIcon={<ChatIcon />}
          onClick={handleStartChat}
          sx={{
            bgcolor: '#D4AF37',
            color: 'black',
            border: '1px solid rgba(212, 175, 55, 0.25)',
            transition: 'all 0.3s ease',
            '&:hover': {
              bgcolor: '#B69121',
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 12px rgba(212, 175, 55, 0.3)',
            },
          }}
        >
          Start Chat
        </Button>
      </CardContent>
    </Card>
  );
};
