import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SubscriptionState } from '../../types/subscription';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';

const initialState: SubscriptionState = {
  subscriptions: [],
  currentSubscription: null,
  status: 'idle',
  error: null,
};

export const fetchSubscriptionStatus = createAsyncThunk(
  'subscription/fetchStatus',
  async (id: string) => {
    const response = await axiosInstance.get(`${apiUrl}/subscription/${id}`);
    return response.data;
  }
);

export const pauseSubscription = createAsyncThunk(
  'subscription/pause',
  async ({
    purchasedProductId,
    reason,
  }: {
    purchasedProductId: string;
    reason: string;
  }) => {
    const response = await axiosInstance.post(
      `${apiUrl}/subscription/${purchasedProductId}/pause`,
      { reason }
    );
    return response.data;
  }
);

export const resumeSubscription = createAsyncThunk(
  'subscription/resume',
  async (purchasedProductId: string) => {
    const response = await axiosInstance.post(
      `${apiUrl}/subscription/${purchasedProductId}/resume`
    );
    return response.data;
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async (purchasedProductId: string) => {
    const response = await axiosInstance.post(
      `${apiUrl}/subscription/${purchasedProductId}/cancel`
    );
    return response.data;
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    clearSubscriptionError: (state) => {
      state.error = null;
    },
    setCurrentSubscription: (state, action) => {
      state.currentSubscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Subscription Status
      .addCase(fetchSubscriptionStatus.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchSubscriptionStatus.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentSubscription = action.payload;
      })
      .addCase(fetchSubscriptionStatus.rejected, (state, action) => {
        state.status = 'rejected';
        state.currentSubscription = null;
        state.error =
          action.error.message || 'Failed to fetch subscription status';
      })
      // Pause Subscription
      .addCase(pauseSubscription.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(pauseSubscription.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentSubscription = action.payload;
      })
      .addCase(pauseSubscription.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to pause subscription';
      })
      // Resume Subscription
      .addCase(resumeSubscription.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(resumeSubscription.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentSubscription = action.payload;
      })
      .addCase(resumeSubscription.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to resume subscription';
      })
      // Cancel Subscription
      .addCase(cancelSubscription.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentSubscription = action.payload;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to cancel subscription';
      });
  },
});

export const { clearSubscriptionError, setCurrentSubscription } =
  subscriptionSlice.actions;

export const selectSubscriptions = (state: {
  subscription: SubscriptionState;
}) => state.subscription.subscriptions;
export const selectCurrentSubscription = (state: {
  subscription: SubscriptionState;
}) => state.subscription.currentSubscription;
export const selectSubscriptionStatus = (state: {
  subscription: SubscriptionState;
}) => state.subscription.status;
export const selectSubscriptionError = (state: {
  subscription: SubscriptionState;
}) => state.subscription.error;

export default subscriptionSlice.reducer;
