import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { NewEventStepProps } from '../index.tsx';
import { Region } from '../../../../types/locations.ts';
import {
  fetchLocations,
  selectRegions,
} from '../../../../store/slices/locationSlice';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AppDispatch } from '../../../../store/store.ts';

export interface LocationConfiguratorStepProps extends NewEventStepProps {
  callback: (locations: Region[]) => void;
  selectedLocations?: Region[];
}

const LocationConfiguratorStep: FC<LocationConfiguratorStepProps> = ({
  handleNext,
  handleBack,
  callback,
  selectedLocations,
}: LocationConfiguratorStepProps) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const regions = useSelector(selectRegions);
  const [locations, setLocations] = React.useState<Region[]>([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      console.log('Initializing locations with:', { regions, selectedLocations });
      
      const initializedLocations = regions.map((region) => {
        const savedRegion = selectedLocations?.find(saved => saved.id === region.id);
        
        return {
          ...region,
          isSelected: savedRegion?.isSelected || false,
          counties: region.counties.map(county => {
            const savedCounty = savedRegion?.counties?.find(c => c.id === county.id);
            return {
              ...county,
              isSelected: savedCounty?.isSelected || false
            };
          })
        };
      });

      console.log('Initialized locations:', initializedLocations);
      setLocations(initializedLocations);
    }
  }, [regions, selectedLocations]);

  useEffect(() => {
    const hasSelections = locations.some(
      location => location.isSelected || location.counties.some(county => county.isSelected)
    );
    setIsNextButtonDisabled(!hasSelections);
  }, [locations]);

  const handleLocationSelect = (regionId: string, countyId?: string) => {
    console.log('Selecting location:', { regionId, countyId });
    
    const updatedLocations = locations.map((region) => {
      if (region.id === regionId) {
        if (countyId) {
          const updatedCounties = region.counties.map((county) => ({
            ...county,
            isSelected: county.id === countyId ? !county.isSelected : county.isSelected,
          }));
          
          const anyCountySelected = updatedCounties.some(c => c.isSelected);
          
          return {
            ...region,
            counties: updatedCounties,
            isSelected: anyCountySelected
          };
        } else {
          const newIsSelected = !region.isSelected;
          return {
            ...region,
            isSelected: newIsSelected,
            counties: region.counties.map((county) => ({
              ...county,
              isSelected: newIsSelected,
            })),
          };
        }
      }
      return region;
    });

    console.log('Updated locations:', updatedLocations);
    setLocations(updatedLocations);

    const selectedLocations = updatedLocations
      .filter((region) => region.isSelected || region.counties.some((county) => county.isSelected))
      .map((region) => ({
        ...region,
        counties: region.counties.filter((county) => county.isSelected),
      }));

    console.log('Selected locations for callback:', selectedLocations);
    callback(selectedLocations);
    setIsNextButtonDisabled(selectedLocations.length === 0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {locations.map((region, regionIndex) => (
          <Grid item xs={12} md={6} lg={4} key={region.id}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: theme.palette.background.paper,
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[8],
                },
                border: region.isSelected
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" component="div">
                    {region.name}
                  </Typography>
                  <Chip
                    icon={<LocationOnIcon />}
                    label={region.isSelected ? 'Selected' : 'Select All'}
                    onClick={() => handleLocationSelect(region.id)}
                    color={region.isSelected ? 'primary' : 'default'}
                    sx={{ cursor: 'pointer' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}
                >
                  {region.counties.map((county, countyIndex) => (
                    <Chip
                      key={county.id}
                      label={county.name}
                      onClick={() => handleLocationSelect(region.id, county.id)}
                      color={county.isSelected ? 'primary' : 'default'}
                      variant={county.isSelected ? 'filled' : 'outlined'}
                      sx={{
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: county.isSelected
                            ? theme.palette.primary.dark
                            : theme.palette.action.hover,
                        },
                      }}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4,
          gap: 2,
        }}
      >
        <Button variant="outlined" onClick={handleBack} sx={{ minWidth: 120 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isNextButtonDisabled}
          sx={{ minWidth: 120 }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default LocationConfiguratorStep;
