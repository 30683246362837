import { Box, Container } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EventIcon from '@mui/icons-material/Event';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ClassIcon from '@mui/icons-material/Class';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MapIcon from '@mui/icons-material/Map';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import React from 'react';
import Header from '../../components/header';
import { DashboardSection } from '../../components/dashboard/DashboardSection';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import CategoryIcon from '@mui/icons-material/Category';
import BusinessIcon from '@mui/icons-material/Business';

export interface AdminCenteItem {
  name: string;
  link: string;
  icon: JSX.Element;
  description: string;
  section?: string;
}

// Sample admin center items - you can move this to a separate file or fetch from an API
const adminItems: AdminCenteItem[] = [
  {
    name: 'Company Approvals',
    link: '/admin/approvals/companies',
    icon: <BusinessIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Review and manage company approval requests',
    section: 'Approvals',
  },
  {
    name: 'Listing Approvals',
    link: '/admin/approvals/listings',
    icon: <ListAltIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Review and manage listing approval requests',
    section: 'Approvals',
  },
  {
    name: 'Event Approvals',
    link: '/admin/approvals/events',
    icon: <EventIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Review and manage event approval requests',
    section: 'Approvals',
  },
  {
    name: 'Subscription Approvals',
    link: '/admin/approvals/subscriptions',
    icon: <SubscriptionsIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Review and manage subscription requests',
    section: 'Approvals',
  },
  {
    name: 'Categories',
    link: '/admin/categories',
    icon: <CategoryIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Manage categories',
    section: 'Group filters',
  },
  {
    name: 'Category Services',
    link: '/admin/category-services',
    icon: (
      <MiscellaneousServicesIcon sx={{ fontSize: 56, color: 'primary.main' }} />
    ),
    description: 'Manage category services',
    section: 'Group filters',
  },
  {
    name: 'Subcategories',
    link: '/admin/subcategories',
    icon: <ClassIcon sx={{ fontSize: 56, color: 'secondary.main' }} />,
    description: 'Manage subcategories',
    section: 'Group filters',
  },
  {
    name: 'Sub-Category Services',
    link: '/admin/subcategory-services',
    icon: (
      <MiscellaneousServicesIcon
        sx={{ fontSize: 56, color: 'secondary.main' }}
      />
    ),
    description: 'Manage sub-category services',
    section: 'Group filters',
  },
  {
    name: 'Regions',
    link: '/admin/regions',
    icon: <MapIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Manage regions for listings',
    section: 'Location filters',
  },
  {
    name: 'Products',
    link: '/admin/products',
    icon: (
      <AccountBalanceSharpIcon sx={{ fontSize: 56, color: 'primary.main' }} />
    ),
    description: 'Manage products and subscriptions',
    section: 'Settings',
  },
  {
    name: 'Settings',
    link: '/admin/settings',
    icon: <SettingsIcon sx={{ fontSize: 56, color: 'primary.main' }} />,
    description: 'Configure system settings and preferences',
    section: 'Settings',
  },
];

const getSectionIcon = (section: string) => {
  switch (section) {
    case 'Approvals':
      return (
        <TaskAltIcon sx={{ fontSize: 32, color: 'primary.main', mr: 1 }} />
      );
    case 'Group filters':
      return (
        <FilterAltIcon sx={{ fontSize: 32, color: 'primary.main', mr: 1 }} />
      );
    case 'Location filters':
      return (
        <LocationCityIcon sx={{ fontSize: 32, color: 'primary.main', mr: 1 }} />
      );
    case 'Settings':
      return (
        <SettingsIcon sx={{ fontSize: 32, color: 'primary.main', mr: 1 }} />
      );
    default:
      return null;
  }
};

const AdminCenter: React.FC = () => {
  // Group items by section
  const groupedItems = adminItems.reduce(
    (acc, item) => {
      const section = item.section || 'Other';
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    },
    {} as Record<string, AdminCenteItem[]>
  );

  return (
    <Box>
      <Header
        title="Admin Center"
        subtitle="Manage your system settings and approvals"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box>
          {Object.entries(groupedItems).map(([section, items]) => (
            <DashboardSection
              key={section}
              title={section}
              icon={getSectionIcon(section)}
              items={items}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default AdminCenter;
