import { Grid } from '@mui/material';
import HeroSection from './sections/HeroSection';
import banner from '/public/images/banner.jpg';
// import InfiniteScroll from "../../components/InfiniteScroll.tsx";
import EventPlannerSection from './sections/EventPlannerSection.tsx';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store.ts';
import { useEffect } from 'react';
import { fetchCategories } from '../../store/slices/categorySlice.ts';
import { fetchSubCategories } from '../../store/slices/subCategorySlice.ts';
import InfiniteScrolls from '../../components/InfiniteScrolls.tsx';
// import ScrollUpButton from "../../components/ScrollUpButton.tsx";

const HomePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
  }, []);
  return (
    <>
      {/*<Container>*/}
      <Grid
        container
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        }}
      >
        <Grid item xs={12} sx={{ backgroundColor: 'rgb(78, 70, 58, 85%)' }}>
          <HeroSection />
        </Grid>
        <Grid item xs={12}>
          {/*<InfoSection/>*/}
          <EventPlannerSection />
        </Grid>
        <Grid item xs={12}>
          <InfiniteScrolls />
          {/*<InfoSection/>*/}
          {/*<InfiniteScroll/>*/}
          {/*<ScrollUpButton/>*/}
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <ServicesSection/>*/}
        {/*</Grid>*/}
      </Grid>
      {/*</Container>*/}
    </>
  );
};

export default HomePage;
