import React, { useEffect } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CategoryFilterSection from './CategoryFilterSection';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store.ts';
import { searchListings } from '../../store/slices/listingSlice.ts';

interface FilterContentProps {
  selectedCategoryId: string | null;
  onClose?: () => void;
  variant: 'sidebar' | 'dialog';
}

const FilterContent: React.FC<FilterContentProps> = ({
  selectedCategoryId,
  onClose,
  variant,
}) => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      searchListings({
        categoryId: selectedCategoryId,
        searchQuery: searchQuery,
      })
    );
  }, [selectedCategoryId, searchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const CloseButton = variant === 'dialog' ? CloseIcon : ChevronRightIcon;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom:
            variant === 'dialog' ? '1px solid rgba(212, 175, 55, 0.3)' : 'none',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            fontWeight: 600,
          }}
        >
          Filters
        </Typography>
        {onClose && (
          <IconButton
            onClick={onClose}
            sx={{
              color: 'rgba(212, 175, 55, 0.8)',
              backgroundColor:
                variant === 'sidebar' ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
              transition: theme.transitions.create(
                ['transform', 'color', 'background-color'],
                {
                  duration: theme.transitions.duration.standard,
                }
              ),
              transform: variant === 'sidebar' ? 'rotate(180deg)' : 'none',
              '&:hover': {
                color: '#D4AF37',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              },
            }}
          >
            <CloseButton />
          </IconButton>
        )}
      </Box>

      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <Box sx={{ mb: 3 }}>
          <CategoryFilterSection selectedCategoryId={selectedCategoryId} />
        </Box>
        <TextField
          fullWidth
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search listings..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'rgba(212, 175, 55, 0.8)' }} />
              </InputAdornment>
            ),
            sx: {
              color: '#D4AF37',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(212, 175, 55, 0.3)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(212, 175, 55, 0.5)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D4AF37',
              },
              '&::placeholder': {
                color: 'rgba(212, 175, 55, 0.5)',
                opacity: 1,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FilterContent;
