import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveCompany,
  fetchCompanyById,
  selectCurrentCompany,
} from '../../store/slices/companySlice';
import { apiUrl } from '../../main';
import { AppDispatch } from '../../store/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyStatus } from '../../types/states/CompanyState';
import Header from '../../components/header';
import { selectAuth } from '../../store/slices/authSlice';
import { useSnackbar } from 'notistack';

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const CompanyOverviewPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const company = useSelector(selectCurrentCompany);
  const auth = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadCompany = async () => {
      if (companyId) {
        setIsLoading(true);
        try {
          await dispatch(fetchCompanyById(companyId)).unwrap();
        } catch (error) {
          console.error('Error loading company:', error);
        }
        setIsLoading(false);
      }
    };

    loadCompany();
  }, [companyId, dispatch]);

  if (isLoading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!companyId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">No company ID provided</Typography>
      </Box>
    );
  }

  if (!company) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Company not found</Typography>
      </Box>
    );
  }

  const getStatusColor = (status: CompanyStatus) => {
    switch (status) {
      case CompanyStatus.Active:
        return {
          background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
          text: 'Active',
        };
      case CompanyStatus.AwaitingApproval:
        return {
          background: 'linear-gradient(45deg, #FFA726 30%, #FFB74D 90%)',
          text: 'Awaiting Approval',
        };
      case CompanyStatus.Deleted:
        return {
          background: 'linear-gradient(45deg, #f44336 30%, #e57373 90%)',
          text: 'Deleted',
        };
      case CompanyStatus.Rejected:
        return {
          background: 'linear-gradient(45deg, #f44336 30%, #e57373 90%)',
          text: 'Rejected',
        };
      default:
        return {
          background: 'linear-gradient(45deg, #9E9E9E 30%, #BDBDBD 90%)',
          text: 'Unknown',
        };
    }
  };

  const statusStyle = getStatusColor(company.status);

  const handleApprove = async () => {
    if (company) {
      try {
        await dispatch(
          approveCompany({
            id: company.id,
            approved: true,
          })
        ).unwrap();
        enqueueSnackbar('Company approved successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        setTimeout(() => {
          navigate('/admin/approvals/companies'); // Go back to the previous page
        }, 300);
      } catch (error) {
        console.error('Error approving company:', error);
        enqueueSnackbar('Failed to approve company', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    }
  };

  const handleReject = async () => {
    if (company) {
      try {
        await dispatch(
          approveCompany({
            id: company.id,
            approved: false,
          })
        ).unwrap();
        enqueueSnackbar('Company rejected successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        setTimeout(() => {
          navigate('/admin/approvals/companies'); // Go back to the previous page
        }, 300);
      } catch (error) {
        console.error('Error rejecting company:', error);
        enqueueSnackbar('Failed to reject company', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    }
  };

  return (
    <Box>
      <Header
        title="Company Overview"
        subtitle="View all company details in one place"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Basic Information */}
        <Card sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
            {/* Company Logo */}
            <Box
              sx={{
                flex: '0 0 300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              {company.logoImageUri ? (
                <Box
                  component="img"
                  src={`${apiUrl}/files/${company.logoImageUri}`}
                  alt={`${company.name} logo`}
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    borderRadius: 1,
                    boxShadow: theme.shadows[1],
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    height: '169px',
                    borderRadius: 1,
                    bgcolor: 'grey.100',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color="text.secondary">No logo</Typography>
                </Box>
              )}
            </Box>

            {/* Company Details */}
            <Box sx={{ flex: '1 1 300px' }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}
              >
                <Typography variant="h5">{company.name}</Typography>
                <Chip
                  label={statusStyle.text}
                  sx={{
                    background: statusStyle.background,
                    color: '#fff',
                    fontWeight: 500,
                  }}
                />
              </Box>

              <Typography
                variant="subtitle1"
                sx={{ mb: 2, color: 'text.secondary', fontWeight: 500 }}
              >
                {company.shortDescription || 'No short description provided'}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'pre-wrap',
                  color: 'text.secondary',
                  mb: 2,
                }}
              >
                {company.description || 'No description provided'}
              </Typography>

              {/* Admin Actions */}
              {auth.user?.roles.includes('Admin') &&
                company.status === CompanyStatus.AwaitingApproval && (
                  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleApprove}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>
        </Card>

        {/* Subscription & Resource Information */}
        <Grid container spacing={3}>
          {/* Subscription Details */}
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Subscription Details
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Active Subscription
                  </Typography>
                  <Typography>
                    {company.activeSubscriptionId || 'No active subscription'}
                  </Typography>
                </Box>
                {company.currentPeriodStart && company.currentPeriodEnd && (
                  <>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Current Period
                      </Typography>
                      <Typography>
                        {formatDate(company.currentPeriodStart)} -{' '}
                        {formatDate(company.currentPeriodEnd)}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Stripe Customer ID
                  </Typography>
                  <Typography>
                    {company.stripeCustomerId || 'Not connected to Stripe'}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* Resource Quotas */}
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Resource Quotas
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Listing Tokens
                  </Typography>
                  <Typography>{company.listingTokens}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Event Click Tokens (Subscription)
                  </Typography>
                  <Typography>
                    {company.eventClickTokens_Subscription}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Event Click Tokens (Extras)
                  </Typography>
                  <Typography>{company.eventClickTokens_Extras}</Typography>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* History & Timestamps */}
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                History & Timestamps
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Created At
                    </Typography>
                    <Typography>{formatDate(company.createdAt)}</Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Updated
                    </Typography>
                    <Typography>{formatDate(company.updatedAt)}</Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Created By
                    </Typography>
                    <Typography>{company.createdById}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Subscription History
                    </Typography>
                    {company.subscriptionHistory.length > 0 ? (
                      company.subscriptionHistory.map((sub, index) => (
                        <Typography key={index}>{sub}</Typography>
                      ))
                    ) : (
                      <Typography>No subscription history</Typography>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      One-Time Purchase History
                    </Typography>
                    {company.oneTimePurchaseHistory.length > 0 ? (
                      company.oneTimePurchaseHistory.map((purchase, index) => (
                        <Typography key={index}>{purchase}</Typography>
                      ))
                    ) : (
                      <Typography>No one-time purchase history</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyOverviewPage;
