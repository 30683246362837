import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import {
  fetchCompanyById,
  selectCompanyError,
  selectCompanyStatus,
  selectCurrentCompany,
} from '../../../store/slices/companySlice';

import { CompanyOptionsGrid } from './components/CompanyOptionsGrid.tsx';
import { CompanyForm } from './components/CompanyForm.tsx';
import Header from '../../../components/header';
import { AppDispatch } from '../../../store/store.ts';
import { CompanySubscriptions } from './components/CompanySubscriptions.tsx';
import {
  fetchActiveProducts,
  selectProducts,
} from '../../../store/slices/productSlice.ts';
import { CompanyListings } from '../CompanyListings';

export const CompanyDetails = () => {
  // const {id} = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const currentCompany = useSelector(selectCurrentCompany);
  const status = useSelector(selectCompanyStatus);
  const error = useSelector(selectCompanyError);
  const location = useLocation();
  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId'); // Gets the 'view' query parameter
  const view = searchParams.get('view'); // Gets the 'view' query parameter
  const productId = searchParams.get('productId'); // Gets the 'view' query parameter
  const clientSecret = searchParams.get('clientSecret'); // Gets the 'view' query parameter
  const products = useSelector(selectProducts);
  const product = products.find((p) => p.id === productId);
  useEffect(() => {
    console.log('companyId:', companyId);
    console.log('View:', view);
    console.log('Product ID:', productId);
    console.log('Client Secret:', clientSecret);
    console.log('Current Company:', currentCompany);
    console.log('Product:', product);
    console.log('Status:', status);
    console.log('Error:', error);

    if (companyId) {
      dispatch(fetchCompanyById(companyId));
      dispatch(fetchActiveProducts());
    }
  }, [companyId]);

  const renderContent = () => {
    if (!companyId) {
      return <CompanyForm mode="create" />;
    }
    switch (view) {
      case 'details':
        return <CompanyForm mode="edit" />;

      case 'listings':
        return <CompanyListings />;

      case 'subscription':
        return <CompanySubscriptions />;

      default:
        return <CompanyOptionsGrid />;
    }
  };

  if (status === 'pending') {
    return <CircularProgress />;
  } else if (status === 'fulfilled') {
    return (
      <Box
        sx={{
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
            opacity: 0.7,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background:
              'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
          },
        }}
      >
        <Header
          title={companyId ? currentCompany?.name : 'Create New Company'}
          subtitle={
            companyId
              ? currentCompany?.shortDescription
              : 'Enter company details below'
          }
          showBackButton={true}
        />
        <Box
          sx={{
            position: 'relative',
            background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            minHeight: 'calc(100vh - 64px)',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
              opacity: 0.7,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '2px',
              background:
                'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
            },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              py: { xs: 6, md: 8 },
              position: 'relative',
              zIndex: 1,
              animation: 'fadeIn 0.8s ease-out',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {renderContent()}
          </Container>
        </Box>
      </Box>
    );
  }

  return <>Something went wrong</>;
};

export default CompanyDetails;
