import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  clearListingState,
  deleteListing,
  fetchListingsByCompany,
  selectListingError,
  selectListings,
  selectListingStatus,
} from '../../../store/slices/listingSlice';
import { AppDispatch } from '../../../store/store';
import {
  fetchCategories,
  selectCategories,
} from '../../../store/slices/categorySlice';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../../../store/slices/subCategorySlice';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../../store/slices/subCategoryServiceSlice';
import { CompanyListingCard } from './CompanyListingCard';

export const CompanyListings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const listings = useSelector(selectListings);
  const status = useSelector(selectListingStatus);
  const error = useSelector(selectListingError);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');

  useEffect(() => {
    if (!companyId) {
      navigate('/companies');
      return;
    }
    console.log('Fetching data...');
    dispatch(fetchListingsByCompany(companyId));
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    dispatch(fetchSubCategoryServices());
  }, [dispatch, companyId, navigate]);

  useEffect(() => {
    console.log('Current listings:', listings);
    console.log('Current categories:', categories);
    console.log('Current subCategories:', subCategories);
    console.log('Current subCategoryServices:', subCategoryServices);
  }, [listings, categories, subCategories, subCategoryServices]);

  const handleEdit = (id: string) => {
    navigate(
      `/company/listing?companyId=${companyId}&listingId=${id}&mode=view`
    );
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      await dispatch(deleteListing(id));
      dispatch(fetchListingsByCompany(companyId));
    }
  };

  const handleCreate = () => {
    dispatch(clearListingState());
    navigate(`/company/listing?companyId=${companyId}&mode=create`);
  };

  if (!companyId) {
    return null;
  }

  if (status === 'idle') {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Listings
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create New Listing
        </Button>
      </Box>

      <Grid container spacing={3}>
        {listings.map((listing) => (
          <Grid item xs={12} sm={6} md={4} key={listing.id}>
            <CompanyListingCard
              listing={listing}
              categories={categories}
              subCategories={subCategories}
              subCategoryServices={subCategoryServices}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
