// src/pages/AdminCenter/AdminCategories/components/SubCategoryList.tsx

import React from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';

import { SubCategory } from '../../../../types/states/SubCategoryState.ts';
import {
  selectSubCategories,
  selectSubCategoryError,
  selectSubCategoryStatus,
  setCurrentSubCategory,
} from '../../../../store/slices/subCategorySlice.ts';

interface SubCategoryListProps {
  onEdit: (subCategory: SubCategory) => void;
  onDelete: (subCategory: SubCategory) => void;
}

export const SubCategoryList: React.FC<SubCategoryListProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const subCategory = useSelector(selectSubCategories);
  const status = useSelector(selectSubCategoryStatus);
  const error = useSelector(selectSubCategoryError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (subCategory.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No sub-categories found
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Short Description</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subCategory.map((subCategory) => (
            <TableRow key={subCategory.id}>
              <TableCell>{subCategory.name}</TableCell>
              <TableCell>{subCategory.shortDescription}</TableCell>
              <TableCell>{subCategory.description}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    dispatch(setCurrentSubCategory(subCategory));
                    onEdit(subCategory);
                  }}
                  color="primary"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => onDelete(subCategory)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
