import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Close as CloseIcon,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import { apiUrl } from '../../main';
import { ImageView } from './ImageView.tsx';

interface GalleryViewProps {
  imageIds: string[];
}

const MAX_VISIBLE_IMAGES = 7;

export const GalleryView: React.FC<GalleryViewProps> = ({ imageIds }) => {
  const theme = useTheme();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevious = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex(
      selectedImageIndex === 0 ? imageIds.length - 1 : selectedImageIndex - 1
    );
  };

  const handleNext = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex(
      selectedImageIndex === imageIds.length - 1 ? 0 : selectedImageIndex + 1
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handlePrevious();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const remainingCount = Math.max(0, imageIds.length - MAX_VISIBLE_IMAGES);
  const visibleImages = imageIds.slice(0, MAX_VISIBLE_IMAGES);

  return (
    <Box>
      <Grid container spacing={2}>
        {visibleImages.map((id, index) => (
          <Grid item xs={6} sm={4} md={3} key={id}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                border: '2px solid',
                borderColor: 'rgba(212, 175, 55, 0.3)',
                transition: 'all 0.3s ease-in-out',
                animation: 'fadeIn 0.8s ease-out',
                '@keyframes fadeIn': {
                  from: { opacity: 0, transform: 'translateY(20px)' },
                  to: { opacity: 1, transform: 'translateY(0)' },
                },
                '&:hover': {
                  borderColor: '#D4AF37',
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
                  '& .hover-overlay': {
                    opacity: 1,
                  },
                  '& img': {
                    filter: 'brightness(1.1)',
                  },
                },
              }}
              onClick={() => handleImageClick(index)}
            >
              <Box
                component="img"
                src={`${apiUrl}/files/${id}`}
                alt="Gallery image"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'all 0.3s ease',
                  filter: 'brightness(0.9)',
                }}
              />
              <Box
                className="hover-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'white', fontWeight: 'bold' }}
                >
                  Click to view
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        {remainingCount > 0 && (
          <Grid item xs={6} sm={4} md={3}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '100%',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                background:
                  'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))',
                border: '2px solid',
                borderColor: 'rgba(212, 175, 55, 0.3)',
                transition: 'all 0.3s ease-in-out',
                animation: 'fadeIn 0.8s ease-out',
                '@keyframes fadeIn': {
                  from: { opacity: 0, transform: 'translateY(20px)' },
                  to: { opacity: 1, transform: 'translateY(0)' },
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
                  transition: 'opacity 0.3s ease',
                },
                '&:hover': {
                  borderColor: '#D4AF37',
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
                  '&::before': {
                    opacity: 0.8,
                  },
                  '& .content-wrapper': {
                    transform: 'translateY(-5px)',
                  },
                },
              }}
              onClick={() => handleImageClick(MAX_VISIBLE_IMAGES)}
            >
              <Box
                className="content-wrapper"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  transition: 'transform 0.3s ease',
                  animation: 'fadeIn 0.8s ease-out',
                  '@keyframes fadeIn': {
                    from: { opacity: 0, transform: 'translateY(20px)' },
                    to: { opacity: 1, transform: 'translateY(0)' },
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    background:
                      'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundSize: '200% auto',
                    animation: 'shine 3s linear infinite',
                    textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                    mb: 1,
                    '@keyframes shine': {
                      to: {
                        backgroundPosition: '200% center',
                      },
                    },
                  }}
                >
                  + {remainingCount}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'rgba(212, 175, 55, 0.9)',
                    textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                  }}
                >
                  More Photos
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <Modal
        open={selectedImageIndex !== null}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            maxWidth: '90vw',
            maxHeight: '90vh',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column'
          }}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          {selectedImageIndex !== null && (
            <>
              <Box
                sx={{
                  position: 'relative',
                  height: 48,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 2,
                  zIndex: 1,
                }}
              >
                <Typography sx={{ color: 'white' }}>
                  Image {selectedImageIndex + 1} of {imageIds.length}
                </Typography>
                <IconButton onClick={handleClose} sx={{ color: 'white' }}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'black',
                  position: 'relative',
                  p: 2
                }}
              >
                <ImageView
                  fileId={imageIds[selectedImageIndex]}
                  alt="Gallery image"
                  style={{
                    maxWidth: 'calc(90vw - 32px)',
                    maxHeight: 'calc(90vh - 80px)',
                    width: 'auto',
                    height: 'auto',
                    objectFit: 'contain'
                  }}
                />
                <IconButton
                  onClick={handlePrevious}
                  sx={{
                    position: 'absolute',
                    left: 16,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                  }}
                >
                  <NavigateBefore />
                </IconButton>

                <IconButton
                  onClick={handleNext}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
