import { Box, IconButton, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import { MenuItemLink, MenuItems } from './renderMenu.tsx';

interface MobileNavbarProps {
  leftColumnItems: MenuItemLink[];
  rightColumnItems: MenuItemLink[];
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  leftColumnItems,
  rightColumnItems,
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        ml: 'auto',
      }}
    >
      <IconButton
        size="large"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{
          color: 'rgba(255, 255, 255, 0.9)',
          transition: 'all 0.3s ease',
          '&:hover': {
            color: '#D4AF37',
            transform: 'scale(1.1)',
          },
        }}
      >
        <MenuIcon sx={{ fontSize: 32 }} />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiPaper-root': {
            background: 'rgba(0, 0, 0, 0.85)',
            backdropFilter: 'blur(8px)',
            borderBottom: '2px solid',
            borderColor: 'rgba(212, 175, 55, 0.3)',
            transition: 'all 0.3s ease-in-out',
            borderRadius: '0',
            mt: 0,
            width: '100vw',
            left: '0 !important',
            right: '0 !important',
            top: '64px !important',
            maxWidth: '100%',
            position: 'fixed',
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            '&:hover': {
              borderColor: '#D4AF37',
              boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
            },
          },
        }}
      >
        <MenuItems
          items={[...leftColumnItems, ...rightColumnItems]}
          handleClose={handleCloseNavMenu}
        />
      </Menu>
    </Box>
  );
};

export default MobileNavbar;
