import React from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { SubCategoryCard } from './SubCategoryCard';
import {
  selectSubCategories,
  selectSubCategoryError,
  selectSubCategoryStatus,
  setCurrentSubCategory,
} from '../../../../store/slices/subCategorySlice.ts';
import { SubCategory } from '../../../../types/states/SubCategoryState.ts';

interface SubCategoryGridGridProps {
  onEdit: (subCategory: SubCategory) => void;
  onDelete: (subCategory: SubCategory) => void;
}

export const SubCategoryGrid: React.FC<SubCategoryGridGridProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const subCategories = useSelector(selectSubCategories);
  const status = useSelector(selectSubCategoryStatus);
  const error = useSelector(selectSubCategoryError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (subCategories.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No sub-categories found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={4} padding={2}>
      {subCategories.map((subCategory, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={subCategory.id}>
          <SubCategoryCard
            subCategory={subCategory}
            index={index}
            onEdit={() => {
              dispatch(setCurrentSubCategory(subCategory));
              onEdit(subCategory);
            }}
            onDelete={() => onDelete(subCategory)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
