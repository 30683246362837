import * as signalR from '@microsoft/signalr';
import store from '../store/store';
import { addMessage, updateChat } from '../store/slices/chatSlice';

class SignalRService {
  private hubConnection: signalR.HubConnection | null = null;
  private connectionPromise: Promise<void> | null = null;

  public async startConnection() {
    try {
      if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
        console.log('Already connected to SignalR hub');
        return;
      }

      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      console.log('Starting SignalR connection...');
      const VITE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${VITE_API_BASE_URL}/chathub`, {
          accessTokenFactory: () => token,
          skipNegotiation: false,
          transport:
            signalR.HttpTransportType.WebSockets |
            signalR.HttpTransportType.ServerSentEvents |
            signalR.HttpTransportType.LongPolling,
        })
        .withAutomaticReconnect([0, 2000, 5000, 10000, 20000])
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.setupMessageHandler();

      console.log('Attempting to start connection...');
      await this.hubConnection.start();
      console.log('SignalR Connected successfully!');

      this.connectionPromise = null;
    } catch (err) {
      console.error('Error while establishing SignalR connection:', err);
      this.connectionPromise = null;
      throw err;
    }
  }

  public async stopConnection() {
    if (this.hubConnection) {
      try {
        await this.hubConnection.stop();
        console.log('SignalR connection stopped');
      } catch (err) {
        console.error('Error stopping SignalR connection:', err);
      } finally {
        this.hubConnection = null;
        this.connectionPromise = null;
      }
    }
  }

  public async joinChat(chatId: string) {
    try {
      await this.ensureConnected();
      if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
        await this.hubConnection.invoke('JoinChat', chatId);
        console.log('Successfully joined chat:', chatId);
      } else {
        throw new Error(
          'Cannot join chat: SignalR connection is not established'
        );
      }
    } catch (err) {
      console.error('Error joining chat:', err);
      throw err;
    }
  }

  public async leaveChat(chatId: string) {
    try {
      if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
        await this.hubConnection.invoke('LeaveChat', chatId);
        console.log('Successfully left chat:', chatId);
      }
    } catch (err) {
      console.error('Error leaving chat:', err);
    }
  }

  public async sendMessage(chatId: string, text: string) {
    try {
      await this.ensureConnected();
      if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
        await this.hubConnection.invoke('SendMessage', chatId, text);
        console.log('Successfully sent message to chat:', chatId);
      } else {
        throw new Error(
          'Cannot send message: SignalR connection is not established'
        );
      }
    } catch (err) {
      console.error('Error sending message:', err);
      throw err;
    }
  }

  private async ensureConnected() {
    if (this.hubConnection?.state === signalR.HubConnectionState.Connected) {
      return;
    }

    if (!this.connectionPromise) {
      this.connectionPromise = this.startConnection();
    }

    await this.connectionPromise;
  }

  private setupMessageHandler() {
    if (!this.hubConnection) {
      console.error('Cannot setup message handler: hubConnection is null');
      return;
    }

    // Remove any existing handlers to prevent duplicates
    this.hubConnection.off('ReceiveMessage');

    this.hubConnection.on('ReceiveMessage', (chatId: string, message: any) => {
      console.log('Received message:', message);

      // Add message to redux store
      store.dispatch(addMessage({ chatId, message }));
      store.dispatch(
        updateChat({
          chatId,
          lastMessage: message.text,
          timestamp: message.timestamp,
        })
      );
    });

    this.hubConnection.onclose((error) => {
      console.log('SignalR connection closed:', error);
      this.connectionPromise = null;
    });

    this.hubConnection.onreconnecting((error) => {
      console.log('SignalR reconnecting:', error);
    });

    this.hubConnection.onreconnected((connectionId) => {
      console.log('SignalR reconnected. ConnectionId:', connectionId);
      // Re-setup message handler after reconnection
      this.setupMessageHandler();
    });
  }
}

export const signalRService = new SignalRService();
