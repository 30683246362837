import axios, { AxiosError } from 'axios';
import { Event } from '../types/event';

const apiUrl = import.meta.env.VITE_API_URL + '/PlannerEvent';

const handleApiError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    const errorMessage = axiosError.response?.data?.message || axiosError.message;
    console.error('API Error:', {
      status: axiosError.response?.status,
      data: axiosError.response?.data,
      message: errorMessage
    });
    throw new Error(`API Error: ${errorMessage}`);
  }
  throw error;
};

export const eventService = {
  getEvents: async () => {
    try {
      console.log('Fetching events from:', apiUrl);
      const response = await axios.get<Event[]>(apiUrl);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  getEventById: async (id: string) => {
    try {
      console.log('Getting event by id:', id, 'from URL:', `${apiUrl}/${id}`);
      const response = await axios.get<Event>(`${apiUrl}/${id}`);
      console.log('Response data:', response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  createEvent: async (event: Event) => {
    try {
      const response = await axios.post<Event>(apiUrl, event);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  updateEvent: async (event: Event) => {
    try {
      const response = await axios.put<Event>(`${apiUrl}/${event.id}`, event);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  deleteEvent: async (id: string) => {
    try {
      await axios.delete(`${apiUrl}/${id}`);
    } catch (error) {
      return handleApiError(error);
    }
  },
};
