import { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  Subscription,
  SubscriptionStatus,
} from '../../../../types/subscription';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { cancelSubscription } from '../../../../store/slices/subscriptionSlice';
import { selectProducts } from '../../../../store/slices/productSlice.ts';

interface SubscriptionManagementProps {
  subscription: Subscription;
  onClose: () => void;
  open: boolean;
}

export const SubscriptionManagement: FC<SubscriptionManagementProps> = ({
  subscription,
  onClose,
  open,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const products = useSelector(selectProducts);
  const product = products.find((p) => p.id === subscription.productId);
  const handleCancel = async () => {
    setIsProcessing(true);
    try {
      await dispatch(cancelSubscription(subscription.id)).unwrap();
      setConfirmOpen(false);
      onClose();
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            background: 'rgba(18, 18, 18, 0.95)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(212, 175, 55, 0.1)',
            minWidth: { xs: '90%', sm: '500px' },
          },
        }}
      >
        <DialogTitle sx={{ color: 'primary.main' }}>
          Manage Subscription
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ color: '#fff', mb: 1 }}>
              Current Plan: {product?.name}
            </Typography>
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Status: {SubscriptionStatus[subscription.status]}
              {subscription.status === SubscriptionStatus.Cancelled &&
                subscription.periodEnd &&
                new Date(subscription.periodEnd) > new Date() && (
                  <span style={{ color: '#ffd700' }}>
                    {' '}
                    (Benefits active until period end)
                  </span>
                )}
            </Typography>
            {subscription.periodEnd && (
              <Typography
                sx={{
                  color:
                    subscription.status === SubscriptionStatus.Cancelled
                      ? new Date(subscription.periodEnd) > new Date()
                        ? '#ffd70,0'
                        : 'rgba(255, 87, 34, 0.8)'
                      : 'rgba(255, 255, 255, 0.7)',
                  mt: 1,
                }}
              >
                {subscription.status === SubscriptionStatus.Cancelled
                  ? 'Benefits active until: '
                  : 'Valid until: '}
                {new Date(subscription.periodEnd).toLocaleDateString()}
              </Typography>
            )}
          </Box>

          <Box sx={{ mt: 4 }}>
            {subscription.status !== SubscriptionStatus.Cancelled ? (
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => setConfirmOpen(true)}
                sx={{
                  borderColor: 'rgba(255, 87, 34, 0.5)',
                  color: '#ff5722',
                  '&:hover': {
                    borderColor: '#ff5722',
                    background: 'rgba(255, 87, 34, 0.08)',
                  },
                }}
              >
                Cancel Subscription
              </Button>
            ) : (
              <Typography
                sx={{ color: 'rgba(255, 87, 34, 0.8)', textAlign: 'center' }}
              >
                Subscription has been cancelled
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={onClose} sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => !isProcessing && setConfirmOpen(false)}
        PaperProps={{
          sx: {
            background: 'rgba(18, 18, 18, 0.95)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(212, 175, 55, 0.1)',
          },
        }}
      >
        <DialogTitle sx={{ color: '#ff5722' }}>
          Cancel Subscription?
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Are you sure you want to cancel your subscription? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setConfirmOpen(false)}
            disabled={isProcessing}
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            No, Keep It
          </Button>
          <Button
            onClick={handleCancel}
            disabled={isProcessing}
            color="error"
            variant="contained"
          >
            {isProcessing ? 'Cancelling...' : 'Yes, Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
