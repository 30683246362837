import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Currency, Product, ProductType } from '../../../types/product';
import {
  selectProductError,
  selectProducts,
  selectProductStatus,
} from '../../../store/slices/productSlice';

interface ProductListProps {
  onEdit: (product: Product) => void;
  onDelete: (product: Product) => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  onEdit,
  onDelete,
}) => {
  const products = useSelector(selectProducts);
  const status = useSelector(selectProductStatus);
  const error = useSelector(selectProductError);

  if (status === 'loading' && !products.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Listings Tokens</TableCell>
            <TableCell>Event Click Tokens</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell>${product.price.toFixed(2)}</TableCell>
              <TableCell>{Currency[product.currency]}</TableCell>
              <TableCell>{product.listingsTokens}</TableCell>
              <TableCell>{product.eventClickTokens}</TableCell>
              <TableCell>
                <Typography
                  color={product.isActive ? 'success.main' : 'error.main'}
                >
                  {product.isActive ? 'Active' : 'Inactive'}
                </Typography>
              </TableCell>
              <TableCell>
                {product.type === ProductType.OneTime
                  ? 'One Time'
                  : 'Subscription'}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  size="small"
                  onClick={() => onEdit(product)}
                  disabled={status === 'loading'}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => onDelete(product)}
                  disabled={status === 'loading'}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
