// src/store/slices/companySlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import { CompaniesState } from '../../types/states/CompanyState.ts';
import {
  CreateCompanyRequest,
  UpdateCompanyRequest,
} from '../../types/requests/CompanyRequests.ts';

export type CompanyStyleVariant = 'default' | 'minimal' | 'elegant' | 'modern';

const initialState: CompaniesState = {
  companies: [],
  status: 'idle',
  error: null,
  currentCompany: null,
  stylePreference: 'default',
};

// Async thunks
export const fetchCompanies = createAsyncThunk(
  'companies/fetchAll',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/company`);
    return response.data;
  }
);
// Async thunks
export const fetchCompaniesByUser = createAsyncThunk(
  'companies/fetchAllByUser',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/company/by-user`);
    return response.data;
  }
);

export const fetchCompanyById = createAsyncThunk(
  'companies/fetchById',
  async (id: string) => {
    const response = await axiosInstance.get(`${apiUrl}/company/${id}`);
    return response.data;
  }
);

export const fetchCompanyApprovals = createAsyncThunk(
  'companies/fetchApprovals',
  async () => {
    const response = await axiosInstance.get(`${apiUrl}/approval/100`);
    return response.data;
  }
);

export const createCompany = createAsyncThunk(
  'companies/create',
  async (companyData: CreateCompanyRequest) => {
    console.log(companyData);
    const response = await axiosInstance.post(`${apiUrl}/company`, companyData);
    return response.data;
  }
);

export const approveCompany = createAsyncThunk(
  'companies/approve',
  async ({ id, approved }: { id: string; approved: boolean }) => {
    const response = await axiosInstance.post(`${apiUrl}/approval`, {
      id,
      approved,
      type: 100, // 100 for company,
    });
    return response.data;
  }
);

export const updateCompany = createAsyncThunk(
  'companies/update',
  async (companyData: UpdateCompanyRequest) => {
    const response = await axiosInstance.put(
      `${apiUrl}/company/${companyData.id}`,
      companyData
    );
    return response.data;
  }
);

export const deleteCompany = createAsyncThunk(
  'companies/delete',
  async (id: string) => {
    await axiosInstance.delete(`${apiUrl}/company/${id}`);
    return id;
  }
);

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearCompanyState(state) {
      state.companies = [];
      state.status = 'idle';
      state.error = null;
      state.currentCompany = null;
      state.stylePreference = 'default';
    },
    setCurrentCompany(state, action) {
      state.currentCompany = action.payload;
    },
    clearCurrentCompany(state) {
      state.currentCompany = null;
    },
    setStylePreference: (state, action) => {
      state.stylePreference = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch companies
      .addCase(fetchCompanies.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.companies = action.payload;
        state.error = null;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch companies';
      })
      // Fetch companies by user
      .addCase(fetchCompaniesByUser.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCompaniesByUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.companies = action.payload;
        state.error = null;
      })
      .addCase(fetchCompaniesByUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch companies';
      })
      // Fetch company by id
      .addCase(fetchCompanyById.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentCompany = action.payload;
        state.error = null;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to fetch company';
      })
      // Create company
      .addCase(createCompany.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.companies.push(action.payload);
        state.error = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to create company';
      })
      // Update company
      .addCase(updateCompany.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.companies.findIndex(
          (cat) => cat.id === action.payload.id
        );
        if (index !== -1) {
          state.companies[index] = action.payload;
        }
        state.currentCompany = action.payload;
        state.error = null;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to update company';
      })
      // Fetch company approvals
      .addCase(fetchCompanyApprovals.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchCompanyApprovals.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.companies = action.payload;
        state.error = null;
      })
      .addCase(fetchCompanyApprovals.rejected, (state, action) => {
        state.status = 'rejected';
        state.error =
          action.error.message || 'Failed to fetch company approvals';
      })
      // Approve company
      .addCase(approveCompany.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(approveCompany.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.companies.findIndex(
          (cat) => cat.id === action.payload.id
        );
        if (index !== -1) {
          state.companies[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(approveCompany.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to approve company';
      })

      // Delete company
      .addCase(deleteCompany.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.companies = state.companies.filter(
          (cat) => cat.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message || 'Failed to delete company';
      });
  },
});

export const {
  clearCompanyState,
  setCurrentCompany,
  clearCurrentCompany,
  setStylePreference,
} = companySlice.actions;

// Selectors
export const selectCompanies = (state: RootState) => state.companies.companies;
export const selectCompanyStatus = (state: RootState) => state.companies.status;
export const selectCompanyError = (state: RootState) => state.companies.error;
export const selectCurrentCompany = (state: RootState) =>
  state.companies.currentCompany;
export const selectStylePreference = (state: RootState) =>
  state.companies.stylePreference;

export default companySlice.reducer;
