import i18next from 'i18next';
import ns1_en from '../../public/locales/en/ns1.json';
import ns1_no from '../../public/locales/no/ns1.json';
import enFallback from '../../public/locales/en/fallback.json';
import LanguageDetector from 'i18next-browser-languagedetector';

export const defaultNS = 'ns1';
export const fallbackNS = 'fallback';

i18next.use(LanguageDetector).init({
  // @ts-expect-error - This is a valid configuration
  localStorageKey: 'i18nextLng',
  debug: false,
  fallbackLng: 'no',
  defaultNS,
  fallbackNS,
  resources: {
    nn: {
      // "nn" is the code for "Nynorsk" (Norwegian)
      ns1: ns1_no,
    },
    'nn-NO': {
      // "nn-NO" is the code for "Nynorsk" (Norwegian)
      ns1: ns1_no,
    },
    no: {
      ns1: ns1_no,
    },
    en: {
      ns1: ns1_en,
      fallback: enFallback,
    },
  },
});

export default i18next;
