import { Box, Container, Fab, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import CategoryHeader from '../../components/header/CategoryHeader.tsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCategories,
  selectCategories,
} from '../../store/slices/categorySlice.ts';
import { useSearchParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import FilterDialog from './FilterDialog';
import FilterSidebar from './FilterSidebar';
import { selectListings } from '../../store/slices/listingSlice.ts';
import { AppDispatch } from '../../store/store.ts';
import { ListingCard } from './ListingCard.tsx';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../../store/slices/subCategorySlice.ts';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../store/slices/subCategoryServiceSlice.ts';
import Grid from '@mui/material/Grid2';

const DRAWER_WIDTH = 320;
const COLLAPSED_WIDTH = 56;

const ListingsPage = () => {
  const [searchParams] = useSearchParams();
  const selectedCategoryId = searchParams.get('categoryId');
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const category =
    categories.find((category: any) => category.id === selectedCategoryId) ||
    null;
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const listings = useSelector(selectListings);

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubCategories());
    dispatch(fetchSubCategoryServices());
    // if (selectedCategoryId) {
    //   console.log('fetching by search parameters');
    //   dispatch(searchListings({ categoryId: selectedCategoryId }));
    // }
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <FilterSidebar
        selectedCategoryId={selectedCategoryId}
        open={sidebarOpen}
        onToggle={handleToggleSidebar}
      />

      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          zIndex: 0,
          ml: { xs: 0, md: `${COLLAPSED_WIDTH}px` },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          ...(sidebarOpen && {
            ml: { xs: 0, md: `${DRAWER_WIDTH}px` },
          }),
        }}
      >
        {category && (
          <CategoryHeader category={category} showBackButton={true} />
        )}
        <Container maxWidth="xl">
          <Box sx={{ py: 4 }}>
            <Container maxWidth="lg" sx={{ py: 4 }}>
              <Box>
                <Grid container spacing={1}>
                  {listings.map((listing) => (
                    <Grid size={{ xs: 12, sm: 12, md: 4 }}>
                      <ListingCard
                        listing={listing}
                        categories={categories}
                        subCategories={subCategories}
                        key={listing.id}
                        subCategoryServices={subCategoryServices}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </Box>
        </Container>

        {/* Mobile Filter Button */}
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            position: 'fixed',
            bottom: { xs: 16, sm: 24 },
            right: { xs: 16, sm: 24 },
            zIndex: theme.zIndex.speedDial,
          }}
        >
          <Fab
            onClick={handleOpenFilterDialog}
            sx={{
              bgcolor: 'rgba(212, 175, 55, 0.9)',
              color: '#000',
              '&:hover': {
                bgcolor: '#D4AF37',
              },
            }}
          >
            <TuneIcon />
          </Fab>
        </Box>

        {/* Mobile Filter Dialog */}
        <FilterDialog
          open={filterDialogOpen}
          onClose={handleCloseFilterDialog}
          selectedCategoryId={selectedCategoryId}
        />
      </Box>
    </Box>
  );
};

export default ListingsPage;
