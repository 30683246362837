import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  useTheme,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { selectRegions } from '../../store/slices/locationSlice';
import { County, Region } from '../../types/locations';

interface LocationFilterProps {
  selectedLocations: Region[];
  onLocationSelect: (region: Region, county?: County) => void;
}

export const LocationFilter = React.memo(
  ({ selectedLocations, onLocationSelect }: LocationFilterProps) => {
    const theme = useTheme();
    const regions = useSelector(selectRegions);

    const findRegionInSelection = useCallback(
      (regionId: string): Region | undefined => {
        return selectedLocations.find((location) => location.id === regionId);
      },
      [selectedLocations]
    );

    const handleRegionSelect = useCallback(
      (selectedRegion: Region, shouldSelect: boolean) => {
        const updatedRegion = { ...selectedRegion };
        updatedRegion.counties = selectedRegion.counties.map((county) => ({
          ...county,
          isSelected: shouldSelect,
        }));
        updatedRegion.isSelected = shouldSelect;
        onLocationSelect(updatedRegion);
      },
      [onLocationSelect]
    );

    const handleCountySelect = useCallback(
      (selectedRegion: Region, county: County, isChecked: boolean) => {
        const updatedRegion = { ...selectedRegion };
        const updatedCounty = { ...county, isSelected: !isChecked };

        const countyIndex = updatedRegion.counties.findIndex(
          (c) => c.id === county.id
        );
        if (countyIndex !== -1) {
          updatedRegion.counties[countyIndex] = updatedCounty;
        }

        updatedRegion.isSelected = updatedRegion.counties.every(
          (c) => c.isSelected
        );

        onLocationSelect(updatedRegion, updatedCounty);
      },
      [onLocationSelect]
    );

    const isRegionSelected = useCallback(
      (region: Region): boolean => {
        const selectedRegion = findRegionInSelection(region.id);
        return selectedRegion?.isSelected || false;
      },
      [findRegionInSelection]
    );

    const isCountySelected = useCallback(
      (region: Region, countyId: string): boolean => {
        const selectedRegion = findRegionInSelection(region.id);
        if (!selectedRegion) return false;
        const county = selectedRegion.counties.find((c) => c.id === countyId);
        return county?.isSelected || false;
      },
      [findRegionInSelection]
    );

    const areAllCountiesSelected = useCallback(
      (region: Region): boolean => {
        const selectedRegion = findRegionInSelection(region.id);
        if (!selectedRegion) return false;
        return selectedRegion.counties.every((county) => county.isSelected);
      },
      [findRegionInSelection]
    );

    const isPartiallySelected = useCallback(
      (region: Region): boolean => {
        const selectedRegion = findRegionInSelection(region.id);
        if (!selectedRegion) return false;
        const hasSelectedCounties = selectedRegion.counties.some(
          (county) => county.isSelected
        );
        return hasSelectedCounties && !areAllCountiesSelected(region);
      },
      [findRegionInSelection, areAllCountiesSelected]
    );

    const regionCards = useMemo(
      () =>
        regions?.map((region) => {
          const selectedRegion = findRegionInSelection(region.id);
          if (!selectedRegion) return null;

          const isSelected = isRegionSelected(region);
          const allCountiesSelected = areAllCountiesSelected(region);
          const partiallySelected = isPartiallySelected(region);

          return (
            <Card
              key={region.id}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                },
                border:
                  isSelected || allCountiesSelected
                    ? `2px solid ${theme.palette.primary.main}`
                    : partiallySelected
                      ? `2px solid ${theme.palette.grey[400]}`
                      : 'none',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" component="div">
                    {region.name}
                  </Typography>
                  <Chip
                    icon={<LocationOnIcon />}
                    label={allCountiesSelected ? 'Selected' : 'Select All'}
                    onClick={() =>
                      handleRegionSelect(selectedRegion, !allCountiesSelected)
                    }
                    color={allCountiesSelected ? 'primary' : 'default'}
                    sx={{ cursor: 'pointer' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}
                >
                  {selectedRegion.counties.map((county) => {
                    const isCountyChecked = isCountySelected(region, county.id);
                    return (
                      <Chip
                        key={county.id}
                        label={county.name}
                        onClick={() =>
                          handleCountySelect(
                            selectedRegion,
                            county,
                            isCountyChecked
                          )
                        }
                        color={isCountyChecked ? 'primary' : 'default'}
                        variant={isCountyChecked ? 'filled' : 'outlined'}
                        sx={{ cursor: 'pointer' }}
                      />
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          );
        }),
      [
        regions,
        theme,
        findRegionInSelection,
        isRegionSelected,
        isCountySelected,
        areAllCountiesSelected,
        isPartiallySelected,
        handleRegionSelect,
        handleCountySelect,
      ]
    );

    return <Box sx={{ width: '100%' }}>{regionCards}</Box>;
  }
);

LocationFilter.displayName = 'LocationFilter';
