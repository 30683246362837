import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator,
} from '@mui/icons-material';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { fileService } from '../../services/files';
import { useSnackbar } from 'notistack';
import { apiUrl } from '../../main';

interface GalleryUploadProps {
  imageIds: string[];
  onImagesChange: (imageIds: string[]) => void;
}

interface SortableImageProps {
  id: string;
  onRemove: (id: string) => Promise<void>;
}

const SortableImage = ({ id, onRemove }: SortableImageProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      ref={setNodeRef}
      style={style}
      data-dragging={isDragging}
    >
      <Box
        sx={{
          position: 'relative',
          paddingTop: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          component="img"
          src={`${apiUrl}/files/${id}`}
          alt={`Gallery image ${id}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <IconButton
          {...attributes}
          {...listeners}
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            padding: '12px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            '@media (max-width: 600px)': {
              padding: '16px',
            },
          }}
        >
          <DragIndicator sx={{ color: 'white' }} />
        </IconButton>
        <IconButton
          onClick={() => onRemove(id)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <DeleteIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
    </Grid>
  );
};

export const GalleryUpload: React.FC<GalleryUploadProps> = ({
  imageIds,
  onImagesChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: isMobileDevice()
        ? {
            delay: 150,
            tolerance: 5,
            distance: 1,
          }
        : undefined,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const preventScroll = (e: TouchEvent) => {
      if (document.querySelector('[data-dragging="true"]')) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventScroll, { passive: false });
    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);
      const uploadPromises: Promise<string>[] = [];
      const failedUploads: string[] = [];

      // Start all uploads in parallel
      newFiles.forEach(file => {
        const promise = fileService.uploadFile(file, file.name)
          .catch(error => {
            console.error('Error uploading image:', error);
            failedUploads.push(file.name);
            return null; // Return null for failed uploads
          });
        uploadPromises.push(promise);
      });

      try {
        // Wait for all uploads to complete
        const results = await Promise.all(uploadPromises);
        
        // Filter out failed uploads (null values)
        const successfulUploads = results.filter((id): id is string => id !== null);
        
        if (successfulUploads.length > 0) {
          // Force update the manifest to ensure new files are included
          await fileService.forceUpdateManifest();
          
          // Update state once with all successful uploads
          onImagesChange([...imageIds, ...successfulUploads]);
          
          // Show success message
          enqueueSnackbar(
            successfulUploads.length === newFiles.length
              ? 'All images uploaded successfully!'
              : `${successfulUploads.length} of ${newFiles.length} images uploaded successfully`,
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }
          );
        }

        // Show error message if any uploads failed
        if (failedUploads.length > 0) {
          enqueueSnackbar(
            `Failed to upload ${failedUploads.length} image${failedUploads.length > 1 ? 's' : ''}`,
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }
          );
        }
      } catch (error) {
        console.error('Error handling file uploads:', error);
        enqueueSnackbar('An error occurred while uploading images', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    }
  };

  const handleRemoveImage = async (imageId: string) => {
    try {
      await fileService.deleteFile(imageId);
      onImagesChange(imageIds.filter((id) => id !== imageId));
      enqueueSnackbar('Image removed successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error: any) {
      console.error('Failed to remove image:', error);
      if (error?.response?.data?.message === 'File not found') {
        enqueueSnackbar('Image reference already removed from the server', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        onImagesChange(imageIds.filter((id) => id !== imageId));
      } else {
        enqueueSnackbar('Failed to remove image', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = imageIds.indexOf(active.id.toString());
      const newIndex = imageIds.indexOf(over.id.toString());
      onImagesChange(arrayMove(imageIds, oldIndex, newIndex));
    }
  };

  return (
    <Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Grid container spacing={2}>
          <SortableContext
            items={imageIds}
            strategy={horizontalListSortingStrategy}
          >
            {imageIds.map((id) => (
              <SortableImage key={id} id={id} onRemove={handleRemoveImage} />
            ))}
          </SortableContext>
          <Grid item xs={6} sm={4} md={3}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                width: '100%',
                height: 0,
                paddingTop: '100%',
                position: 'relative',
                borderStyle: 'dashed',
              }}
            >
              <input
                type="file"
                hidden
                multiple
                onChange={handleFileChange}
                accept="image/*"
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AddIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
                <Typography variant="body2" color="text.secondary">
                  Add Images
                </Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
      </DndContext>
    </Box>
  );
};
