import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { FlagIcon } from 'react-flag-kit';
import i18next from '../../utilis/i18next.ts';

const Footer: React.FC = () => {
  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng).then(() => {
      window.location.reload();
    });
  };

  return (
    <Box
      component="footer"
      sx={{
        // mt: '1rem',
        backgroundColor: '#000000',
        color: 'white',
        padding: '2rem',
        position: 'relative',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* "Om oss" Section */}
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {i18next.t('Layout.Footer.AboutUs')}
          </Typography>
          <Typography variant="body1">
            {' '}
            {i18next.t('Layout.Footer.OrgNo')}
          </Typography>
        </Grid>

        {/* "Ta kontakt" Section */}
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {i18next.t('Layout.Footer.Contact')}
          </Typography>
          <Typography variant="body1">
            {i18next.t('Layout.Footer.Email')}:{' '}
            <Link
              href="mailto:info@festportalen.no"
              color="inherit"
              underline="hover"
            >
              info@festportalen.no
            </Link>
          </Typography>
        </Grid>

        {/* "Informasjon" Section */}
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {i18next.t('Layout.Footer.Information')}
          </Typography>
          <Typography variant="body1">
            <Link href="#" color="inherit" underline="hover">
              {i18next.t('Layout.Footer.PrivacyStatement')}
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link href="#" color="inherit" underline="hover">
              {i18next.t('Layout.Footer.PrivacyPolicy')}
            </Link>
          </Typography>
        </Grid>

        {/* Language Changer Section */}
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {i18next.t('Layout.Footer.Language')}
          </Typography>
          <Box mt={1} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => changeLanguage('en')}
              sx={{ mr: 1, display: 'flex', alignItems: 'center' }}
            >
              <FlagIcon code="GB" size={24} style={{ marginRight: '0.5rem' }} />
              English
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => changeLanguage('no')}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <FlagIcon code="NO" size={24} style={{ marginRight: '0.5rem' }} />
              Norsk
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body2" color="inherit">
          {i18next.t('Layout.Footer.Copyright')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
