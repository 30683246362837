import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { AppDispatch } from '../../store/store';
import {
  fetchProducts,
  setCurrentProduct,
} from '../../store/slices/productSlice';
import { Product } from '../../types/product';
import Header from '../../components/header';
import { SectionHeader } from '../../components/dashboard/SectionHeader';
import { ProductList } from './components/ProductList';
import { ProductGrid } from './components/ProductGrid';
import { ProductDialog } from './components/ProductDialog';
import { DeleteConfirmationDialog } from './components/DeleteConfirmationDialog';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

type ViewMode = 'list' | 'grid';

export const ProductsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState<Product | null>(null);

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const handleViewModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode | null
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleAddProduct = () => {
    dispatch(setCurrentProduct(null));
    setIsDialogOpen(true);
  };

  const handleEditProduct = (product: Product) => {
    dispatch(setCurrentProduct(product));
    setIsDialogOpen(true);
  };

  const handleDeleteProduct = (product: Product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    dispatch(setCurrentProduct(null));
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProductToDelete(null);
  };

  return (
    <Box>
      <Header
        title="Products"
        subtitle="Create, edit, and manage your products"
        showBackButton={true}
      />
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <SectionHeader
            title="Manage Products"
            icon={<ViewModuleIcon />}
            actions={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box>
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                    size="small"
                  >
                    <ToggleButton value="list" aria-label="list view">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid view">
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddProduct}
                >
                  Add Product
                </Button>
              </Box>
            }
          />
        </Box>

        {viewMode === 'list' ? (
          <ProductList
            onEdit={handleEditProduct}
            onDelete={handleDeleteProduct}
          />
        ) : (
          <ProductGrid
            onEdit={handleEditProduct}
            onDelete={handleDeleteProduct}
          />
        )}

        <ProductDialog open={isDialogOpen} onClose={handleCloseDialog} />

        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          product={productToDelete}
        />
      </Container>
    </Box>
  );
};

export default ProductsPage;
