import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Company, CompanyStatus } from '../../../types/states/CompanyState.ts';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompany } from '../../../store/slices/companySlice.ts';
import { AppDispatch } from '../../../store/store.ts';
import {
  fetchListingsByCompany,
  selectListings,
  selectListingStatus,
} from '../../../store/slices/listingSlice.ts';
import {
  fetchSubscriptionStatus,
  selectCurrentSubscription,
  selectSubscriptionStatus,
} from '../../../store/slices/subscriptionSlice.ts';
import { apiUrl } from '../../../main.tsx';

const statusDisplayMap: Record<number, { text: string; gradient: string }> = {
  [CompanyStatus.Active]: {
    text: 'Active',
    gradient: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
  },
  [CompanyStatus.AwaitingApproval]: {
    text: 'Awaiting Approval',
    gradient: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  },
  [CompanyStatus.Rejected]: {
    text: 'Rejected',
    gradient: 'linear-gradient(45deg, #F44336 30%, #E57373 90%)',
  },
  [CompanyStatus.Deleted]: {
    text: 'Deleted',
    gradient: 'linear-gradient(45deg, #EF5350 30%, #E57373 90%)',
  },
};

export interface CompanyCardProps {
  company: Company;
  index: number;
  variant?: 'default' | 'minimal' | 'elegant' | 'modern';
}

export const CompanyCard = (props: CompanyCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [imageError, setImageError] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const { variant = 'default' } = props;
  console.log('Company Card Props:', props);
  const listings = useSelector(selectListings);
  const listingStatus = useSelector(selectListingStatus);
  const subscription = useSelector(selectCurrentSubscription);
  const subscriptionStatus = useSelector(selectSubscriptionStatus);

  useEffect(() => {
    if (deleteDialogOpen) {
      dispatch(fetchListingsByCompany(props.company.id));
      if (props.company.activeSubscriptionId) {
        dispatch(fetchSubscriptionStatus(props.company.activeSubscriptionId));
      }
    }
  }, [deleteDialogOpen, props.company.id]);

  useEffect(() => {
    console.log('Listings:', listings);
    console.log('Listing Status:', listingStatus);
    console.log('Subscription:', subscription);
    console.log('Subscription Status:', subscriptionStatus);
  }, [listings, listingStatus, subscription, subscriptionStatus]);

  console.log('Company Status:', props.company.status);
  console.log(
    'Status Gradient:',
    statusDisplayMap[props.company.status]?.gradient
  );

  // Shared styles
  const baseCardStyle = {
    position: 'relative',
    height: '100%',
    width: '100%',
    maxWidth: '320px',
    margin: '0 auto',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    animation: `fadeSlideIn 0.6s ease-out ${props.index * 0.1}s both`,
    '@keyframes fadeSlideIn': {
      from: {
        opacity: 0,
        transform: 'translateY(20px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    '& .company-logo': {
      borderRadius: '10px',
    },
  };

  // Style variants
  const cardStyles = {
    default: {
      ...baseCardStyle,
      minHeight: '280px',
      bgcolor: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(8px)',
      borderRadius: '16px',
      border: '2px solid rgba(212, 175, 55, 0.3)',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        transform: 'translateY(-8px)',
        borderColor: '#D4AF37',
        boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
        '& .company-logo': {
          transform: 'scale(1.05)',
        },
        '& .company-name': {
          color: '#D4AF37',
        },
      },
    },
    minimal: {
      ...baseCardStyle,
      minHeight: '240px',
      bgcolor: 'transparent',
      borderRadius: '8px',
      border: '1px solid rgba(212, 175, 55, 0.15)',
      transition: 'all 0.2s ease',
      '&:hover': {
        bgcolor: 'rgba(212, 175, 55, 0.05)',
        '& .company-logo': {
          filter: 'brightness(1.2)',
        },
        '& .company-name': {
          background: 'linear-gradient(to right, #D4AF37, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
    },
    elegant: {
      ...baseCardStyle,
      minHeight: '300px',
      background:
        'linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.95) 100%)',
      borderRadius: '20px',
      padding: '2px',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '20px',
        padding: '2px',
        background:
          'linear-gradient(135deg, rgba(212, 175, 55, 0.5), rgba(212, 175, 55, 0.1))',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
      },
      '&:hover': {
        transform: 'translateY(-4px)',
        '& .company-logo': {
          transform: 'scale(1.05)',
        },
        '& .company-name': {
          letterSpacing: '1px',
          color: '#D4AF37',
        },
      },
    },
    modern: {
      ...baseCardStyle,
      minHeight: '260px',
      bgcolor: 'rgba(18, 18, 18, 0.95)',
      borderRadius: '12px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '120px',
        background:
          'linear-gradient(45deg, rgba(212, 175, 55, 0.1), rgba(255, 215, 0, 0.05))',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
      },
      '&:hover': {
        transform: 'scale(1.02)',
        '& .company-logo': {
          transform: 'scale(1.1)',
        },
        '& .company-name': {
          color: '#D4AF37',
        },
      },
    },
  };

  const logoContainerStyles = {
    default: {
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '8px',
    },
    minimal: {
      background: 'transparent',
    },
    elegant: {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '12px',
    },
    modern: {
      background: 'transparent',
      position: 'relative',
      zIndex: 1,
    },
  };

  return (
    <>
      <Card elevation={0} sx={cardStyles[variant]}>
        <Box
          onClick={() => navigate(`/company?companyId=${props.company.id}`)}
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              ...logoContainerStyles[variant],
            }}
          >
            {props.company && !imageError ? (
              <Box
                component="img"
                src={`${apiUrl}/files/${props.company.logoImageUri}`}
                alt={`${props?.company?.name} logo`}
                onError={() => setImageError(true)}
                className="company-logo"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  transition: 'all 0.3s ease',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                }}
              />
            ) : (
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(212, 175, 55, 0.3)',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                }}
              >
                {props?.company?.name.charAt(0)}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              className="company-name"
              sx={{
                color:
                  variant === 'minimal' ? 'rgba(212, 175, 55, 0.9)' : '#fff',
                fontWeight: 500,
                mb: 1,
                transition: 'all 0.3s ease',
                textAlign: 'center',
              }}
            >
              {props?.company?.name}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Chip
                variant="filled"
                label={
                  statusDisplayMap[props.company.status]?.text || 'Unknown'
                }
                size="small"
                sx={{
                  background:
                    statusDisplayMap[props.company.status]?.gradient ||
                    'linear-gradient(45deg, #9E9E9E 30%, #BDBDBD 90%)',
                  color: '#fff',
                  fontWeight: 500,
                  textTransform: 'none',
                  '& .MuiChip-label': {
                    px: 2,
                  },
                  opacity: 0.9,
                  '&:hover': {
                    opacity: 1,
                  },
                  transition: 'all 0.2s ease-in-out',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                }}
              />
            </Box>

            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                textAlign: 'center',
                lineHeight: 1.6,
                mt: variant === 'elegant' ? 2 : 1,
              }}
            >
              {props.company.shortDescription}
            </Typography>
          </Box>
        </Box>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            padding: '16px',
            borderTop: '1px solid rgba(212, 175, 55, 0.1)',
            bgcolor: 'rgba(0, 0, 0, 0.2)',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            onClick={() => setDeleteDialogOpen(true)}
            startIcon={<DeleteIcon />}
            sx={{
              color: 'rgba(212, 175, 55, 0.7)',
              '&:hover': {
                color: '#D4AF37',
                bgcolor: 'rgba(212, 175, 55, 0.1)',
              },
            }}
            size="small"
          >
            Delete
          </Button>
        </CardActions>
      </Card>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setConfirmationText('');
        }}
        PaperProps={{
          sx: {
            bgcolor: '#1A1A1A',
            border: '1px solid rgba(212, 175, 55, 0.3)',
            borderRadius: '12px',
            color: '#fff',
            maxWidth: '500px',
            width: '100%',
          },
        }}
      >
        <DialogTitle
          sx={{
            color: '#D4AF37',
            borderBottom: '1px solid rgba(212, 175, 55, 0.1)',
            padding: '16px 24px',
          }}
        >
          Delete Company
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {listingStatus === 'pending' || subscriptionStatus === 'pending' ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress sx={{ color: '#D4AF37' }} />
            </Box>
          ) : (
            <>
              <Typography sx={{ mb: 2 }}>
                Are you sure you want to delete{' '}
                <strong>{props.company.name}</strong>?
              </Typography>

              {subscription && (
                <Box
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: 'rgba(255, 87, 34, 0.1)',
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 87, 34, 0.3)',
                  }}
                >
                  <Typography color="error" variant="subtitle2" sx={{ mb: 1 }}>
                    ⚠️ Active Subscription
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >
                    This company has an active subscription. Deleting the
                    company will cancel all active subscriptions.
                  </Typography>
                </Box>
              )}

              {listings.length > 0 && (
                <Box
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: 'rgba(255, 152, 0, 0.1)',
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 152, 0, 0.3)',
                  }}
                >
                  <Typography
                    color="warning"
                    variant="subtitle2"
                    sx={{ mb: 1 }}
                  >
                    ⚠️ Active Listings
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >
                    This company has {listings.length} active listing
                    {listings.length !== 1 ? 's' : ''}. Deleting the company
                    will remove all associated listings.
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: 'rgba(33, 150, 243, 0.1)',
                  borderRadius: '8px',
                  border: '1px solid rgba(33, 150, 243, 0.3)',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: '#2196F3' }}
                >
                  ℹ️ Data Retention
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                >
                  The company and all its data will be retained for 30 days
                  before being permanently deleted. During this period, the data
                  can be restored if needed.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, color: 'rgba(255, 255, 255, 0.9)' }}
                >
                  To confirm deletion, please type{' '}
                  <strong>{props.company.name}</strong> below:
                </Typography>
                <TextField
                  fullWidth
                  value={confirmationText}
                  onChange={(e) => setConfirmationText(e.target.value)}
                  placeholder="Enter company name"
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(212, 175, 55, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D4AF37',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                />
              </Box>

              <Typography
                variant="body2"
                sx={{ color: 'rgba(255, 255, 255, 0.6)', mt: 2 }}
              >
                This action cannot be undone after the 30-day retention period.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: '16px 24px',
            borderTop: '1px solid rgba(212, 175, 55, 0.1)',
            gap: 1,
          }}
        >
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setConfirmationText('');
            }}
            sx={{
              color: '#D4AF37',
              '&:hover': {
                bgcolor: 'rgba(212, 175, 55, 0.1)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteCompany(props.company.id));
              setDeleteDialogOpen(false);
              setConfirmationText('');
            }}
            variant="contained"
            disabled={confirmationText !== props.company.name}
            sx={{
              bgcolor: '#D32F2F',
              '&:hover': {
                bgcolor: '#B71C1C',
              },
              '&.Mui-disabled': {
                bgcolor: 'rgba(211, 47, 47, 0.5)',
                color: 'rgba(255, 255, 255, 0.5)',
              },
            }}
          >
            Delete Company
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
