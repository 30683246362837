import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Category as CategoryIcon,
  CheckCircle as CheckCircleIcon,
  LocationOn as LocationOnIcon,
  Notes as NotesIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Header from '../../../components/header';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledContainer from '../../../components/StyledContainer.tsx';
import EventTypePicker from './components/EventTypePicker.tsx';
import React from 'react';
import { EventPlannerTypeEnum } from '../../../types/eventPlanner.ts';
import EventConfiguratorStep from './components/EventConfiguratorStep.tsx';
import LocationConfiguratorStep from './components/LocationConfiguratorStep.tsx';
import ServicesStep from './components/ServicesStep.tsx';
import type { Event, EventConfig, EventStatus } from '../../../types/event';
import { createEmptyEvent } from '../../../types/event';
import type { Category } from '../../../types/states/CategoryState';
import type { Region } from '../../../types/locations';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTempEvent,
  createEvent,
  fetchEventById,
  selectCurrentEvent,
  selectEventById,
  selectTempEvent,
  setCurrentEvent,
  setTempEvent,
  updateEventAsync,
} from '../../../store/slices/eventSlice';
import { AppDispatch } from '../../../store/store';

export interface NewEventStepProps {
  handleNext: () => void;
  handleBack: () => void;
}

const PlannerEventPage = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const params = new URLSearchParams(location.search);
  const eventId = params.get('id');
  const navigate = useNavigate();

  const currentEvent = useSelector(selectCurrentEvent);
  const tempEvent = useSelector(selectTempEvent);
  const existingEvent = eventId
    ? useSelector((state) => selectEventById(state, eventId))
    : null;

  const [activeStep, setActiveStep] = React.useState(0);
  const [eventConfig, setEventConfig] = React.useState<EventConfig>(
    createEmptyEvent().eventConfig
  );
  const [eventCategories, setEventCategories] = React.useState<Category[]>([]);
  const [selectedServices, setSelectedServices] = React.useState<{
    [categoryId: string]: { subCategories: any[]; categoryServices: any[] };
  }>({});
  const [eventType, setEventType] = React.useState<EventPlannerTypeEnum>(
    EventPlannerTypeEnum.None
  );
  const [eventLocations, setEventLocations] = React.useState<Region[]>([]);
  const [showSuccess, setShowSuccess] = React.useState(false);

  // Load event data
  React.useEffect(() => {
    if (eventId) {
      // Load existing event
      dispatch(fetchEventById(eventId));
    } else {
      // Reset state for new event
      dispatch(setCurrentEvent(null));
      const emptyEvent = createEmptyEvent();
      setEventType(emptyEvent.eventType);
      setEventConfig(emptyEvent.eventConfig);
      setEventLocations(emptyEvent.eventLocations);
      setEventCategories(emptyEvent.eventCategories);
      setSelectedServices(emptyEvent.selectedServices);
    }
  }, [eventId, dispatch]);

  // Update local state when currentEvent changes
  React.useEffect(() => {
    if (currentEvent) {
      console.log('Updating local state with current event:', currentEvent);
      setEventType(currentEvent.eventType);
      setEventConfig(currentEvent.eventConfig);
      setEventLocations(currentEvent.eventLocations);
      setEventCategories(currentEvent.eventCategories);
      setSelectedServices(currentEvent.selectedServices);
    }
  }, [currentEvent]);

  // Save temporary data while editing
  React.useEffect(() => {
    if (!eventId) {
      const tempEventData: Event = {
        id: `temp_${Date.now()}`,
        eventType,
        eventConfig,
        eventLocations,
        eventCategories,
        selectedServices,
        status: 'draft',
        lastUpdated: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      };
      dispatch(setTempEvent(tempEventData));
    }
  }, [
    eventType,
    eventConfig,
    eventLocations,
    eventCategories,
    selectedServices,
    eventId,
    dispatch,
  ]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    const emptyEvent = createEmptyEvent();
    setEventType(emptyEvent.eventType);
    setEventConfig(emptyEvent.eventConfig);
    setEventLocations(emptyEvent.eventLocations);
    setEventCategories(emptyEvent.eventCategories);
    setSelectedServices(emptyEvent.selectedServices);
    dispatch(clearTempEvent());
  };

  const saveEventData = async (status: EventStatus) => {
    const currentId = eventId || Date.now().toString();
    const eventData: Event = {
      id: currentId,
      eventType,
      eventConfig,
      eventLocations,
      eventCategories,
      selectedServices,
      status,
      lastUpdated: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    };
    console.log('Saving event data:', eventData);
    try {
      if (eventId) {
        await dispatch(updateEventAsync(eventData)).unwrap();
      } else {
        await dispatch(createEvent(eventData)).unwrap();
      }
      dispatch(clearTempEvent());
      return currentId;
    } catch (error) {
      console.error('Failed to save event:', error);
      // Handle error appropriately
      throw error;
    }
  };

  const saveAsDraft = async () => {
    await saveEventData('draft');
    setShowSuccess(true);
    setTimeout(() => {
      navigate('/planner');
    }, 5000);
  };

  const saveForApproval = async () => {
    await saveEventData('pending');
    setShowSuccess(true);
    setTimeout(() => {
      navigate('/planner');
    }, 5000);
  };

  const DetailItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: '1px solid rgba(255, 215, 0, 0.3)',
    '& .MuiTypography-label': {
      color: '#FFD700',
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    '& .MuiTypography-value': {
      color: '#fff',
      fontSize: '1rem',
    },
  }));

  const ServiceCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: '1px solid rgba(255, 215, 0, 0.3)',
    borderLeft: '4px solid #FFD700',
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateX(4px)',
      boxShadow: '0 4px 20px rgba(255, 215, 0, 0.1)',
    },
  }));

  const EventOverviewStep = ({
    eventType,
    eventConfig,
    eventLocations,
    selectedServices,
    handleBack,
    handleNext,
  }: {
    eventType: EventPlannerTypeEnum;
    eventConfig: EventConfig;
    eventLocations: Region[];
    selectedServices: {
      [categoryId: string]: { subCategories: any[]; categoryServices: any[] };
    };
    handleBack: () => void;
    handleNext: () => void;
  }) => {
    const theme = useTheme();

    return (
      <Box sx={{ width: '100%' }}>
        {/* Navigation */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              color: '#FFD700',
              borderColor: 'rgba(255, 215, 0, 0.3)',
              '&:hover': {
                borderColor: '#FFD700',
                bgcolor: 'rgba(255, 215, 0, 0.1)',
              },
            }}
          >
            Back
          </Button>
        </Box>

        {/* Completion Message */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '2px solid rgba(255, 215, 0, 0.3)',
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <CheckCircleIcon 
            sx={{ 
              fontSize: 64, 
              color: '#FFD700',
              mb: 2
            }} 
          />
          <Typography
            variant="h5"
            sx={{
              color: '#FFD700',
              fontWeight: 600,
              mb: 1,
            }}
          >
            Event Planning Complete!
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 3,
            }}
          >
            You've successfully configured all aspects of your event. Please review the details below and choose how you'd like to save it.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ mb: 2 }}
          >
            <Button
              variant="outlined"
              onClick={saveAsDraft}
              startIcon={<SaveIcon />}
              sx={{
                color: '#FFD700',
                borderColor: 'rgba(255, 215, 0, 0.3)',
                px: 4,
                '&:hover': {
                  borderColor: '#FFD700',
                  bgcolor: 'rgba(255, 215, 0, 0.1)',
                },
              }}
            >
              Save as Draft
            </Button>
            <Button
              variant="contained"
              onClick={saveForApproval}
              startIcon={<CheckCircleIcon />}
              sx={{
                bgcolor: 'rgba(255, 215, 0, 0.2)',
                color: '#FFD700',
                borderColor: 'rgba(255, 215, 0, 0.3)',
                px: 4,
                '&:hover': {
                  bgcolor: 'rgba(255, 215, 0, 0.3)',
                },
              }}
            >
              Save for Approval
            </Button>
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                borderColor: 'rgba(255, 255, 255, 0.3)',
                px: 4,
                '&:hover': {
                  borderColor: 'rgba(255, 255, 255, 0.7)',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
            >
              Reset
            </Button>
          </Stack>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  bgcolor: 'rgba(0, 0, 0, 0.8)',
                  border: '2px solid #FFD700',
                  borderRadius: 1,
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: '#FFD700', fontWeight: 600 }}
                >
                  {EventPlannerTypeEnum[eventType] || 'No Type Selected'}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Event Name
                </Typography>
                <Typography className="MuiTypography-value">
                  {eventConfig?.eventName || 'Untitled Event'}
                </Typography>
              </DetailItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Start Date
                </Typography>
                <Typography className="MuiTypography-value">
                  {eventConfig?.startDate
                    ? new Date(eventConfig.startDate).toLocaleDateString()
                    : 'No Date Set'}
                </Typography>
              </DetailItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DetailItem>
                <Typography className="MuiTypography-label">
                  Number of Attendees
                </Typography>
                <Typography className="MuiTypography-value">
                  {eventConfig?.numberOfAttendees?.toString() || '0'}
                </Typography>
              </DetailItem>
            </Grid>

            {eventConfig?.budget && (
              <Grid item xs={12} sm={6}>
                <DetailItem>
                  <Typography className="MuiTypography-label">
                    Budget
                  </Typography>
                  <Typography className="MuiTypography-value">
                    ${eventConfig.budget.toLocaleString()}
                  </Typography>
                </DetailItem>
              </Grid>
            )}
          </Grid>
        </Paper>

        {eventConfig?.description && (
          <Paper
            elevation={0}
            sx={{
              p: 3,
              bgcolor: 'rgba(0, 0, 0, 0.9)',
              border: '1px solid rgba(255, 215, 0, 0.2)',
              borderRadius: 2,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" mb={2}>
              <NotesIcon sx={{ color: '#FFD700' }} />
              <Typography variant="h6" sx={{ color: '#FFD700' }}>
                Description
              </Typography>
            </Stack>
            <Typography sx={{ color: '#fff' }}>
              {eventConfig.description}
            </Typography>
          </Paper>
        )}

        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <LocationOnIcon sx={{ color: '#FFD700' }} />
            <Typography variant="h6" sx={{ color: '#FFD700' }}>
              Event Locations
            </Typography>
          </Stack>

          <Stack spacing={2}>
            {eventLocations.map((region, index) => {
              const selectedCounties = region.counties.filter(
                (county) => county.isSelected
              );
              if (selectedCounties.length === 0) return null;

              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    bgcolor: 'rgba(0, 0, 0, 0.8)',
                    border: '1px solid rgba(255, 215, 0, 0.3)',
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFD700',
                      fontWeight: 500,
                      mb: 2,
                    }}
                  >
                    {region.name}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="wrap"
                    sx={{ gap: 1 }}
                  >
                    {selectedCounties.map((county, cIndex) => (
                      <Chip
                        key={cIndex}
                        label={county.name}
                        sx={{
                          bgcolor: 'rgba(255, 215, 0, 0.1)',
                          border: '1px solid rgba(255, 215, 0, 0.3)',
                          color: '#fff',
                          '& .MuiChip-label': {
                            color: '#fff',
                          },
                          '&:hover': {
                            bgcolor: 'rgba(255, 215, 0, 0.2)',
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            border: '1px solid rgba(255, 215, 0, 0.2)',
            borderRadius: 2,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <CategoryIcon sx={{ color: '#FFD700' }} />
            <Typography variant="h6" sx={{ color: '#FFD700' }}>
              Selected Services
            </Typography>
          </Stack>

          <Stack spacing={4}>
            {Object.entries(selectedServices).map(
              ([categoryId, categoryData]) => {
                const { subCategories, categoryServices } = categoryData;
                const hasSelectedServices = categoryServices.some(
                  (service) => service.isSelected
                );
                const hasSelectedSubCategories = subCategories.some((sub) =>
                  sub.services.some((service) => service.isSelected)
                );

                if (!hasSelectedServices && !hasSelectedSubCategories)
                  return null;

                return (
                  <Box
                    key={categoryId}
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(0, 0, 0, 0.8)',
                      border: '1px solid rgba(255, 215, 0, 0.3)',
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: '#FFD700',
                        fontWeight: 600,
                        mb: 3,
                        pb: 1,
                        borderBottom: '1px solid rgba(255, 215, 0, 0.2)',
                      }}
                    >
                      {
                        eventCategories.find((cat) => cat.id === categoryId)
                          .name
                      }
                    </Typography>

                    <Stack spacing={2}>
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        sx={{ gap: 1 }}
                      >
                        {categoryServices
                          .filter((service) => service.isSelected)
                          .map((service, sIndex) => (
                            <Chip
                              key={sIndex}
                              label={
                                <>
                                  {service.name}
                                  {service.groupName && (
                                    <Typography
                                      component="span"
                                      sx={{
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        ml: 1,
                                        fontSize: '0.85em',
                                      }}
                                    >
                                      ({service.groupName})
                                    </Typography>
                                  )}
                                </>
                              }
                              sx={{
                                bgcolor: 'rgba(255, 215, 0, 0.1)',
                                border: '1px solid rgba(255, 215, 0, 0.3)',
                                color: '#fff',
                                height: 'auto',
                                '& .MuiChip-label': {
                                  display: 'flex',
                                  alignItems: 'center',
                                  px: 2,
                                  py: 1,
                                },
                                '&:hover': {
                                  bgcolor: 'rgba(255, 215, 0, 0.2)',
                                },
                              }}
                            />
                          ))}
                      </Stack>

                      {subCategories
                        .filter((sub) =>
                          sub.services.some((service) => service.isSelected)
                        )
                        .map((subCategory, subIndex) => (
                          <Box key={subIndex}>
                            <Typography
                              sx={{
                                color: '#FFD700',
                                fontWeight: 500,
                                mb: 1,
                                pl: 1,
                                fontSize: '1rem',
                              }}
                            >
                            {subCategory.name}
                            </Typography>
                            <Stack
                              direction="row"
                              spacing={1}
                              flexWrap="wrap"
                              sx={{ pl: 2, gap: 1 }}
                            >
                              {subCategory.services
                                .filter((service) => service.isSelected)
                                .map((service, serviceIndex) => (
                                  <Chip
                                    key={serviceIndex}
                                    label={service.name}
                                    sx={{
                                      bgcolor: 'rgba(255, 215, 0, 0.08)',
                                      border:
                                        '1px solid rgba(255, 215, 0, 0.2)',
                                      color: '#fff',
                                      '& .MuiChip-label': {
                                        color: '#fff',
                                      },
                                      '&:hover': {
                                        bgcolor: 'rgba(255, 215, 0, 0.15)',
                                      },
                                    }}
                                  />
                                ))}
                            </Stack>
                          </Box>
                        ))}
                    </Stack>
                  </Box>
                );
              }
            )}
          </Stack>
        </Paper>
      </Box>
    );
  };

  const steps = [
    {
      label: 'Welcome to Event Planner',
      description: `Please start your planning by selecting a type of event you would like to organize.`,
      component: (
        <EventTypePicker
          eventType={eventType}
          setEventType={setEventType}
          handleNext={handleNext}
        />
      ),
    },
    {
      label: `Configure your ${EventPlannerTypeEnum[eventType]} event`,
      description: `Please start your planning by selecting a type of event you would like to organize.`,
      component: (
        <EventConfiguratorStep
          eventConfig={eventConfig}
          handleBack={handleBack}
          handleNext={handleNext}
          setEventConfig={setEventConfig}
          eventType={eventType}
        />
      ),
    },
    {
      label: 'Select your locations',
      description:
        'Choose the regions and counties where your event will take place',
      component: (
        <LocationConfiguratorStep
          callback={(locations) => {
            setEventLocations(locations);
          }}
          selectedLocations={eventLocations}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      ),
    },
    {
      label: 'Select your services',
      description: `Select your services`,
      component: (
        <ServicesStep
          setEventCategories={setEventCategories}
          eventCategories={eventCategories != undefined ? eventCategories : []}
          setSelectedServices={setSelectedServices}
          selectedServices={selectedServices}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      ),
    },
    {
      label: 'Overview',
      description: 'Review your event details before saving',
      component: (
        <EventOverviewStep
          eventType={eventType}
          eventConfig={eventConfig}
          eventLocations={eventLocations}
          selectedServices={selectedServices}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        bgcolor: '#121212',
        color: '#fff',
      }}
    >
      {showSuccess ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#121212',
            zIndex: 1000,
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 80,
              color: '#D4AF37',
              mb: 3,
              animation: 'fadeIn 0.5s ease-in',
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: '#D4AF37',
              mb: 2,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.2s',
              animationFillMode: 'both',
            }}
          >
            Your Event Has Been Created!
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.4s',
              animationFillMode: 'both',
            }}
          >
            Redirecting you back to the planner in 5 seconds...
          </Typography>
        </Box>
      ) : (
        <>
          <Header
            title={eventId ? 'Edit Event' : 'Create Event'}
            subtitle={eventId ? 'Modify your event details' : 'Create a new event'}
            showBackButton={true}
          />
          <StyledContainer>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    {step.component}
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            {activeStep === steps.length && (
              <EventOverviewStep
                eventType={eventType}
                eventConfig={eventConfig}
                eventLocations={eventLocations}
                selectedServices={selectedServices}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            )}
          </StyledContainer>
        </>
      )}
    </Box>
  );
};

export default PlannerEventPage;
