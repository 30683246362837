import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { CategoryService } from '../../../../types/states/CategoryServiceState.ts';
import { deleteCategoryService } from '../../../../store/slices/categoryServiceSlice.ts';

interface DeleteCategoryServiceConfirmationDialogProps {
  open: boolean;
  categoryService: CategoryService | null;
  onClose: () => void;
}

export const DeleteCategoryServiceConfirmationDialog: React.FC<
  DeleteCategoryServiceConfirmationDialogProps
> = ({ open, categoryService, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = async () => {
    if (categoryService) {
      try {
        await dispatch(deleteCategoryService(categoryService.id)).unwrap();
        onClose();
      } catch (error) {
        console.error('Failed to delete Category Service:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Sub-Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the Category Service "
          {categoryService?.name}"? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
