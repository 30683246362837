import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { apiUrl } from '../../main';

interface ImageViewProps {
  fileId?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  sx?: any;
}

const StyledImage = styled('img')<{ customSx?: any }>(
  ({ theme, customSx }) => ({
    transition: 'all 0.3s ease',
    ...(customSx || {}),
  })
);

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImageView: React.FC<ImageViewProps> = ({
  fileId,
  alt = '',
  className = '',
  style = {},
  sx = {},
}) => {
  const [hasError, setHasError] = useState(false);

  if (!fileId || hasError) {
    return (
      <ImageContainer style={style}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            ...sx,
          }}
        >
          <BrokenImageIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
        </Box>
      </ImageContainer>
    );
  }

  return (
    <ImageContainer style={style}>
      <StyledImage
        src={`${apiUrl}/files/${fileId}`}
        alt={alt}
        className={className}
        style={{
          ...style,
          objectFit: style.objectFit || 'cover'
        }}
        customSx={sx}
        onError={() => setHasError(true)}
      />
    </ImageContainer>
  );
};
