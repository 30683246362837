import React from 'react';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface DashboardCardProps {
  name: string;
  link: string;
  icon: React.ReactElement;
  description: string;
  index: number;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  name,
  link,
  icon,
  description,
  index,
}) => {
  return (
    <Card
      elevation={2}
      sx={{
        backgroundColor: '#1a1a1a',
        height: '100%',
        maxWidth: '280px',
        margin: '0 auto',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        transform: index % 2 === 0 ? 'rotate(-1deg)' : 'rotate(1deg)',
        '&:hover': {
          transform:
            index % 2 === 0
              ? 'translateY(-4px) rotate(0deg)'
              : 'translateY(-4px) rotate(0deg)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          '& .card-icon': {
            transform: 'scale(1.1)',
          },
          '& .card-title': {
            color: 'primary.main',
          },
        },
      }}
    >
      <CardActionArea
        component={Link}
        to={link}
        sx={{
          height: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            mb: 2,
          }}
        >
          <Box
            className="card-icon"
            sx={{
              mb: 2,
              transition: 'transform 0.3s ease-in-out',
              '& > svg': {
                fontSize: 56,
              },
            }}
          >
            {React.cloneElement(icon, { ...icon.props })}
          </Box>
          <Typography
            className="card-title"
            variant="h6"
            component="h2"
            align="center"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              letterSpacing: '0.05em',
              transition: 'color 0.3s ease-in-out',
              fontSize: '1.1rem',
              textTransform: 'uppercase',
              mb: 1,
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.875rem',
              lineHeight: 1.4,
            }}
          >
            {description}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};
