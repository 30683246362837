import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Header from '../../../components/header';
import { SectionHeader } from '../../../components/dashboard/SectionHeader';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { CategoryServiceDialog } from './components/CategoryServiceDialog.tsx';
import { useDispatch } from 'react-redux';
import {
  clearCurrentCategoryService,
  fetchCategoryServices,
} from '../../../store/slices/categoryServiceSlice.ts';
import { AppDispatch } from '../../../store/store';
import { CategoryServiceList } from './components/CategoryServiceList.tsx';
import { CategoryServiceGrid } from './components/CategoryServiceGrid.tsx';
import { DeleteCategoryServiceConfirmationDialog } from './components/DeleteCategoryServiceConfirmationDialog.tsx';
import { fetchCategories } from '../../../store/slices/categorySlice.ts';
import { CategoryService } from '../../../types/states/CategoryServiceState.ts';

type ViewMode = 'list' | 'grid';

const AdminCategoryServices: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryServiceToDelete, setCategoryServiceToDelete] =
    useState<CategoryService | null>(null);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchCategoryServices());
  }, []);

  const handleViewModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode | null
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleAddCategoryService = () => {
    dispatch(clearCurrentCategoryService());
    setIsDialogOpen(true);
  };

  const handleEditCategoryService = () => {
    setIsDialogOpen(true);
  };

  const handleDeleteCategoryService = (categoryService: CategoryService) => {
    setCategoryServiceToDelete(categoryService);
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    dispatch(clearCurrentCategoryService());
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCategoryServiceToDelete(null);
  };

  return (
    <Box>
      <Header
        title="Category-Services"
        subtitle="Create, edit, and manage your category services"
        showBackButton={true}
      />

      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <SectionHeader
            title="Manage Category Services"
            actions={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                  >
                    <ToggleButton value="list" aria-label="list view">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid view">
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddCategoryService}
                >
                  Add Category Service
                </Button>
              </Box>
            }
            icon={undefined}
          />
        </Box>

        {viewMode === 'list' ? (
          <CategoryServiceList
            onEdit={handleEditCategoryService}
            onDelete={handleDeleteCategoryService}
          />
        ) : (
          <CategoryServiceGrid
            onEdit={handleEditCategoryService}
            onDelete={handleDeleteCategoryService}
          />
        )}

        <CategoryServiceDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
        />

        <DeleteCategoryServiceConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          categoryService={categoryServiceToDelete}
        />
      </Container>
    </Box>
  );
};
export default AdminCategoryServices;
