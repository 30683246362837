import Header from '../../components/header';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StyledContainer from '../../components/StyledContainer.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chat,
  createChat,
  fetchChats,
  selectAllChats,
  selectChatsError,
  selectChatsStatus,
} from '../../store/slices/chatSlice';
import { AppDispatch } from '../../store/store';
import { signalRService } from '../../services/signalRService';
import { selectAuth } from '../../store/slices/authSlice';
import { useMessagePageParams } from './hooks/useMessagePageParams';
import { formatChatListTime, formatMessageTime } from '../../utils/dateUtils';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

const MessagesPage = () => {
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [messageInput, setMessageInput] = useState('');
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const chats = useSelector(selectAllChats);
  const status = useSelector(selectChatsStatus);
  const error = useSelector(selectChatsError);
  const auth = useSelector(selectAuth);
  const currentUserId = auth.user?.id;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { params, clearParams } = useMessagePageParams();

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, []);

  // Scroll to bottom when messages change or chat is selected
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, selectedChat, chats]);

  // Fetch chats when component mounts
  useEffect(() => {
    if (status === 'idle') {
      // If companyId is specified in URL, fetch chats for that company
      dispatch(
        fetchChats({
          companyId: params.companyId,
          isCompany: params.isCompany,
        })
      );
    }
  }, [status, dispatch, params.companyId, params.isCompany]);

  // Initialize SignalR connection
  useEffect(() => {
    const initializeSignalR = async () => {
      try {
        await signalRService.startConnection();
      } catch (err) {
        console.error('Failed to initialize SignalR:', err);
      }
    };

    initializeSignalR();

    return () => {
      signalRService.stopConnection();
    };
  }, []);

  // Handle URL parameters
  useEffect(() => {
    const handleUrlParams = async () => {
      if (status !== 'succeeded') return;

      if (params.mode === 'newChat') {
        try {
          const existingChat = params.listingId
            ? chats.find((chat) => chat.listingId === params.listingId)
            : null;

          if (existingChat) {
            setSelectedChat(existingChat.id);
            await signalRService.joinChat(existingChat.id);
          } else if (params.companyName && params.listingId) {
            const actionResult = await dispatch(
              createChat({
                companyName: params.companyName,
                listingId: params.listingId,
                listingName: params.listingName || '',
              })
            );

            if (createChat.fulfilled.match(actionResult)) {
              setSelectedChat(actionResult.payload.id);
              await signalRService.joinChat(actionResult.payload.id);
            }
          }
        } catch (error) {
          console.error('Failed to handle chat creation:', error);
        }
        clearParams();
      }
    };

    handleUrlParams();
  }, [status, params, dispatch, chats, clearParams]);

  const handleCreateChat = async (
    listingId: string,
    listingName: string,
    companyName: string,
    userName?: string
  ) => {
    try {
      const result = await dispatch(
        createChat({
          companyName,
          listingId,
          listingName,
          userName,
        })
      ).unwrap();

      if (result) {
        await signalRService.joinChat(result.id);
        setSelectedChat(result.id);
      }
    } catch (error) {
      console.error('Failed to create chat:', error);
    }
  };

  const getChatName = (chat: Chat) => {
    if (params.companyId) {
      return chat.userFullName || 'User';
    } else {
      return chat.companyName;
    }
  };

  const getFilteredChats = () => {
    let filteredChats = chats;
    if (params.companyId) {
      filteredChats = chats.filter(
        (chat) => chat.companyId === params.companyId
      );
    } else {
      // If viewing as user, show only user's chats
      filteredChats = chats.filter((chat) => chat.userId === auth.user?.id);
    }
    return filteredChats;
  };

  // Show loading
  if (status === 'loading') {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{ color: '#D4AF37' }} />
      </Box>
    );
  }

  // Show error
  if (status === 'failed' && error) {
    return (
      <Box
        sx={{
          p: 3,
          background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))`,
          minHeight: '100vh',
        }}
      >
        <Alert
          severity="error"
          sx={{
            bgcolor: 'rgba(211, 47, 47, 0.1)',
            color: '#ff8a80',
            border: '1px solid rgba(211, 47, 47, 0.3)',
          }}
        >
          {error}
          <Button
            color="inherit"
            size="small"
            onClick={() => dispatch(fetchChats())}
            sx={{ ml: 2 }}
          >
            Retry
          </Button>
        </Alert>
      </Box>
    );
  }

  const renderChatMessages = (chatId: string) => {
    const chat = chats.find((c) => c.id === chatId);
    if (!chat) return null;

    return (
      <Box
        sx={{
          height: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* Chat Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: '#151515',
            color: 'white',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {isMobile && (
              <IconButton
                onClick={() => setSelectedChat(null)}
                sx={{ color: 'white', mr: 1 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Avatar
              sx={{
                bgcolor: '#D4AF37',
                width: 40,
                height: 40,
                fontSize: '1.2rem',
              }}
            >
              {getChatName(chat)[0]}
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: '1.1rem', fontWeight: 600 }}
              >
                {getChatName(chat)}
              </Typography>
              {chat.listingName && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    display: 'block',
                    fontSize: '0.8rem',
                  }}
                >
                  {chat.listingName}
                </Typography>
              )}
              {chat.listingId && chat.listingName && (
                <Card
                  onClick={() => navigate(`/listing/${chat.listingId}`)}
                  sx={{
                    mt: 1,
                    p: 1,
                    cursor: 'pointer',
                    bgcolor: 'rgba(212, 175, 55, 0.1)',
                    border: '1px solid rgba(212, 175, 55, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      bgcolor: 'rgba(212, 175, 55, 0.15)',
                      borderColor: 'rgba(212, 175, 55, 0.3)',
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#D4AF37',
                      fontSize: '0.9rem',
                    }}
                  >
                    {chat.listingName}
                  </Typography>
                  <LaunchIcon
                    sx={{ color: '#D4AF37', fontSize: '1rem', ml: 1 }}
                  />
                </Card>
              )}
              <Typography
                variant="caption"
                sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 1 }}
              >
                Online
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Messages Area */}
        <Box
          ref={chatContainerRef}
          sx={{
            flex: 1,
            p: { xs: 1, sm: 2 },
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1, sm: 2 },
            backgroundColor: 'transparent',
            '& > *': {
              px: { xs: 0.5, sm: 1 },
            },
            height: '100%',
          }}
        >
          {chat.messages.map((message) => (
            <Box
              key={message.id}
              sx={{
                display: 'flex',
                justifyContent:
                  message.senderId === currentUserId
                    ? 'flex-end'
                    : 'flex-start',
                mb: 1,
                position: 'relative',
                width: '100%',
                px: 2,
                '&:hover .timestamp': {
                  opacity: 1,
                },
              }}
            >
              {message.senderId !== currentUserId && (
                <Avatar
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: '#D4AF37',
                    fontSize: '0.875rem',
                    marginRight: 1,
                    alignSelf: 'flex-end',
                    mb: 0.5,
                  }}
                >
                  {getChatName(chat)[0]}
                </Avatar>
              )}
              <Box
                sx={{
                  maxWidth: '85%',
                  backgroundColor:
                    message.senderId === currentUserId
                      ? '#D4AF37'
                      : 'rgba(255, 255, 255, 0.1)',
                  color: message.senderId === currentUserId ? '#000' : '#fff',
                  borderRadius: 2,
                  p: 1.5,
                  position: 'relative',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(212, 175, 55, 0.1)',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  '&::before':
                    message.senderId === currentUserId
                      ? {
                          content: '""',
                          position: 'absolute',
                          right: -6,
                          bottom: 8,
                          width: 0,
                          height: 0,
                          borderTop: '6px solid transparent',
                          borderBottom: '6px solid transparent',
                          borderLeft: `6px solid #D4AF37`,
                        }
                      : {
                          content: '""',
                          position: 'absolute',
                          left: -6,
                          bottom: 8,
                          width: 0,
                          height: 0,
                          borderTop: '6px solid transparent',
                          borderBottom: '6px solid transparent',
                          borderRight: '6px solid rgba(255, 255, 255, 0.1)',
                        },
                  '& .MuiTypography-caption': {
                    color:
                      message.senderId === currentUserId
                        ? 'rgba(0, 0, 0, 0.7)'
                        : 'rgba(255, 255, 255, 0.7)',
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.5,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {message.text}
                </Typography>
                <Typography
                  variant="caption"
                  className="timestamp"
                  sx={{
                    display: 'block',
                    textAlign: 'right',
                    mt: 0.5,
                    fontSize: '0.75rem',
                    opacity: 0.7,
                    transition: 'opacity 0.2s ease',
                  }}
                >
                  {formatMessageTime(message.timestamp)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Message Input Area */}
        <Box
          component="form"
          onSubmit={(e) => handleSendMessage(e)}
          sx={{
            p: { xs: 1, sm: 2 },
            borderTop: '1px solid rgba(212, 175, 55, 0.1)',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1, sm: 2 },
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <Box
            component="input"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="Type a message..."
            sx={{
              flex: 1,
              border: '1px solid rgba(212, 175, 55, 0.2)',
              outline: 'none',
              padding: '12px',
              borderRadius: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              fontSize: '0.95rem',
              color: 'white',
              transition: 'all 0.2s ease',
              '&::placeholder': {
                color: 'rgba(255, 255, 255, 0.5)',
              },
              '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderColor: '#D4AF37',
              },
            }}
          />
          <IconButton
            type="submit"
            disabled={!messageInput.trim()}
            sx={{
              bgcolor: '#D4AF37',
              color: 'black',
              transition: 'all 0.2s ease',
              '&:hover': {
                bgcolor: '#b69121',
                transform: 'scale(1.05)',
              },
              '&.Mui-disabled': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                color: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!messageInput.trim() || !selectedChat) return;

    try {
      // Send to server
      await signalRService.sendMessage(selectedChat, messageInput.trim());

      // Clear input and scroll chat area
      setMessageInput('');
      scrollToBottom();
    } catch (err) {
      console.error('Failed to send message:', err);
    }
  };

  return (
    <Box>
      <Header
        title="Messages"
        subtitle="View and manage your messages"
        showBackButton={true}
        actions={<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} />}
      />
      <Box
        sx={{
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // backgroundAttachment: 'fixed', // Removed to prevent page-level scrolling
          minHeight: 'calc(100vh - 64px)',
          overflow: 'hidden', // ensures the body doesn't scroll
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
          },
        }}
      >
        <StyledContainer>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              height: {
                xs: 'calc(100vh - 100px)',
                sm: 'calc(100vh - 200px)',
              },
              width: '100%',
              position: 'relative',
              zIndex: 1,
            }}
          >
            {/* Chat History Sidebar */}
            {(!isMobile || !selectedChat) && (
              <Paper
                elevation={3}
                sx={{
                  width: { xs: '100%', sm: 320 },
                  overflow: 'auto',
                  flexShrink: 0,
                  display: {
                    xs: selectedChat ? 'none' : 'block',
                    sm: 'block',
                  },
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(212, 175, 55, 0.1)',
                  height: '100%',
                  '& .MuiListItemButton-root': {
                    '&:hover': {
                      bgcolor: 'rgba(212, 175, 55, 0.1)',
                    },
                    '&.Mui-selected': {
                      bgcolor: 'rgba(212, 175, 55, 0.2)',
                      '&:hover': {
                        bgcolor: 'rgba(212, 175, 55, 0.25)',
                      },
                    },
                  },
                }}
              >
                <List sx={{ width: '100%', color: 'white' }}>
                  {getFilteredChats().map((chat) => (
                    <Box key={chat.id}>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={selectedChat === chat.id}
                          onClick={() => setSelectedChat(chat.id)}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: '#D4AF37' }}>
                              {getChatName(chat)[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box>
                                <Typography sx={{ color: 'white' }}>
                                  {getChatName(chat)}
                                </Typography>
                                {chat.listingName && (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: 'rgba(255, 255, 255, 0.6)',
                                      fontSize: '0.75rem',
                                      display: 'block',
                                      mt: 0.5,
                                    }}
                                  >
                                    {chat.listingName}
                                  </Typography>
                                )}
                              </Box>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color: 'rgba(255, 255, 255, 0.7)',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {chat.lastMessage}
                                <Typography
                                  component="span"
                                  variant="caption"
                                  sx={{
                                    color: 'rgba(255, 255, 255, 0.5)',
                                    ml: 1,
                                    display: 'inline-block',
                                  }}
                                >
                                  · {formatChatListTime(chat.timestamp)}
                                </Typography>
                              </Typography>
                            }
                            sx={{
                              '& .MuiListItemText-primary': {
                                color: 'white',
                                fontWeight: 500,
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }}
                      />
                    </Box>
                  ))}
                </List>
              </Paper>
            )}

            {/* Main Chat Area */}
            {(!isMobile || selectedChat) && (
              <Paper
                elevation={3}
                sx={{
                  flex: 1,
                  display: {
                    xs: selectedChat ? 'flex' : 'none',
                    sm: 'flex',
                  },
                  overflow: 'hidden',
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(212, 175, 55, 0.1)',
                  height: '100%',
                  position: 'relative',
                }}
              >
                {selectedChat ? (
                  renderChatMessages(selectedChat)
                ) : (
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                    >
                      Select a chat to start messaging
                    </Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>
        </StyledContainer>
      </Box>
    </Box>
  );
};

export default MessagesPage;
