import React from 'react';
import { Box, Drawer, IconButton, Tooltip, useTheme } from '@mui/material';
import FilterContent from './FilterContent';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CategoryIcon from '@mui/icons-material/Category';

interface FilterSidebarProps {
  selectedCategoryId: string | null;
  open: boolean;
  onToggle: () => void;
}

const DRAWER_WIDTH = 320;
const COLLAPSED_WIDTH = 56;

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  selectedCategoryId,
  open,
  onToggle,
}) => {
  const theme = useTheme();

  const renderCollapsedContent = () => (
    <Box
      sx={{
        display: open ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        pt: 2,
      }}
    >
      <Tooltip title="Open Filters" placement="right">
        <IconButton
          onClick={onToggle}
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            '&:hover': {
              color: '#D4AF37',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            },
            width: 40,
            height: 40,
          }}
        >
          <TuneIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Open Categories" placement="right">
        <IconButton
          onClick={onToggle}
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            '&:hover': {
              color: '#D4AF37',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            },
            width: 40,
            height: 40,
          }}
        >
          <CategoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Search Listings" placement="right">
        <IconButton
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            '&:hover': {
              color: '#D4AF37',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            },
            width: 40,
            height: 40,
          }}
          onClick={onToggle}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
          flexShrink: 0,
          position: 'fixed',
          height: 'calc(100vh - 70px)',
          top: 70,
          zIndex: theme.zIndex.appBar - 1,
          transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          '& .MuiDrawer-paper': {
            backgroundColor: 'rgba(0, 0, 0, 0.95)',
            backgroundImage: 'none',
            width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
            height: 'calc(100vh - 70px)',
            position: 'fixed',
            top: 70,
            transition: theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard,
            }),
            borderRight: '1px solid',
            borderColor: 'rgba(212, 175, 55, 0.3)',
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        }}
      >
        {open ? (
          <FilterContent
            selectedCategoryId={selectedCategoryId}
            onClose={onToggle}
            variant="sidebar"
          />
        ) : (
          renderCollapsedContent()
        )}
      </Drawer>
    </Box>
  );
};

export default FilterSidebar;
