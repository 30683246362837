import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface DeleteConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  regionName: string;
}

const DeleteConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  regionName,
}: DeleteConfirmDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(255, 99, 71, 0.3)',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          color: 'rgba(255, 99, 71, 0.9)',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <WarningIcon sx={{ color: 'rgba(255, 99, 71, 0.9)' }} />
        <Typography variant="h6" component="span">
          Delete Region
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.9)' }}>
          <Typography>
            Are you sure you want to delete the region "{regionName}"?
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, color: 'rgba(255, 99, 71, 0.9)' }}
          >
            This action cannot be undone. All counties in this region will also be
            deleted.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
            backgroundColor: 'rgba(255, 99, 71, 0.9)',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#FF6347',
            },
          }}
        >
          Delete Region
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
