import React, { useCallback, useEffect, useId, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Slider,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Cropper, { Area, Point } from 'react-easy-crop';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import { useSnackbar } from 'notistack';
import { fileService } from '../../services/files';

interface ImageCropInputProps {
  aspectRatio: number;
  onImageChange: (imageId: string | null) => void;
  initialImageId?: string | null;
  sx?: SxProps<Theme>;
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const ImageContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.paper};

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  // Set the canvas dimensions to match the crop area
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Clear the canvas to ensure transparency is maintained
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Convert the canvas to a Blob with preserved transparency
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas is empty'));
        }
      },
      'image/webp', // Use WebP format for better compression while maintaining quality
      0.9 // Quality setting for WebP (0.0 to 1.0)
    );
  });
};

export const ImageCropInput: React.FC<ImageCropInputProps> = ({
  aspectRatio,
  onImageChange,
  initialImageId,
  sx,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const instanceId = useId(); // Generate unique ID for this instance
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    initialImageId ? `${apiUrl}/files/${initialImageId}` : null
  );

  useEffect(() => {
    if (initialImageId) {
      setPreviewUrl(`${apiUrl}/files/${initialImageId}`);
    } else {
      setPreviewUrl(null);
    }
  }, [initialImageId]);

  // Reset crop and zoom when aspect ratio changes
  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [aspectRatio]);

  // Reset state when dialog opens
  const handleOpenDialog = useCallback(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setIsDialogOpen(true);
  }, []);

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setImageSrc(reader.result);
          handleOpenDialog();
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCropSave = async () => {
    try {
      if (imageSrc && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

        // Create a temporary preview URL
        const objectUrl = URL.createObjectURL(croppedImage);
        setPreviewUrl(objectUrl);

        // Close dialog and clear source image
        setIsDialogOpen(false);
        setImageSrc(null);

        // Immediately upload the cropped image
        const formData = new FormData();
        formData.append('data', croppedImage, 'cropped-image.webp');

        try {
          let response;
          if (typeof initialImageId === 'string' && initialImageId.length > 0) {
            // Update existing file
            response = await axiosInstance.put(
              `${apiUrl}/files/${initialImageId}`,
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
          } else {
            // Create new file
            response = await axiosInstance.post(`${apiUrl}/files`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }

          const imageId = response.data.id;
          setPreviewUrl(`${apiUrl}/files/${imageId}`);
          onImageChange(imageId);

          // 2) Force update manifest once the file is uploaded
          await fileService.forceUpdateManifest();

          enqueueSnackbar('Image uploaded successfully!', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });

          // Clean up the temporary object URL
          URL.revokeObjectURL(objectUrl);
        } catch (error) {
          console.error('Error uploading image:', error);
          enqueueSnackbar('Failed to upload image', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });

          // If upload fails, clean up
          URL.revokeObjectURL(objectUrl);
          setPreviewUrl(null);
        }
      }
    } catch (error) {
      console.error('Error cropping image:', error);
      setIsDialogOpen(false);
      setImageSrc(null);
      enqueueSnackbar('Failed to crop image', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  };

  const handleRemoveImage = async () => {
    try {
      const fileId = extractFileId(previewUrl);
      await axiosInstance.delete(`${apiUrl}/files/${fileId}`);
      enqueueSnackbar('Image removed successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error) {
      console.error('Failed to remove image:', error);
      enqueueSnackbar('Failed to remove image', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } finally {
      setPreviewUrl(null);
      onImageChange(null);
    }
  };

  // Helper function to extract the file ID from the previewUrl
  const extractFileId = (url: string | null) => {
    if (!url) return '';
    return url.split('/').pop() || '';
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ width: '100%', height: '100%', margin: '0px', ...sx }}
    >
      <Grid item xs={12} sx={{ height: '100%' }}>
        <ImageContainer>
          {previewUrl ? (
            <>
              <Box
                component="img"
                src={previewUrl}
                alt="Preview"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: 1,
                  background:
                    'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddPhotoAlternateIcon />}
                  onClick={() =>
                    document
                      .getElementById(`image-input-${instanceId}`)
                      ?.click()
                  }
                  size="small"
                >
                  Change
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleRemoveImage}
                  startIcon={<DeleteIcon />}
                  size="small"
                >
                  Clear Image
                </Button>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                p: 2,
                textAlign: 'center',
              }}
            >
              <AddPhotoAlternateIcon
                sx={{ fontSize: 48, color: 'text.secondary' }}
              />
              <Button
                variant="contained"
                startIcon={<AddPhotoAlternateIcon />}
                onClick={() =>
                  document.getElementById(`image-input-${instanceId}`)?.click()
                }
              >
                Select Image
              </Button>
              <Typography variant="body2" color="text.secondary">
                Click to select or drag and drop an image here
              </Typography>
            </Box>
          )}
          <VisuallyHiddenInput
            type="file"
            id={`image-input-${instanceId}`}
            accept="image/*"
            onChange={handleFileChange}
          />
        </ImageContainer>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            height: isMobile ? '100%' : '80vh',
            backgroundColor: 'background.paper',
            borderRadius: isMobile ? 0 : 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          Crop Image
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {imageSrc && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  flex: 1,
                  bgcolor: '#333',
                  '& > div': {
                    height: '100%',
                  },
                }}
              >
                <Cropper
                  key={`${instanceId}-${aspectRatio}`}
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspectRatio}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>

              <Box
                sx={{
                  bgcolor: 'background.paper',
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  py: 4,
                }}
              >
                <Container maxWidth="md" disableGutters>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      mx: 2,
                      backgroundColor: 'background.default',
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      id="ccc"
                      sx={{ width: '100%', ml: 0 }}
                      container
                      spacing={4}
                    >
                      <Grid xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 4,
                          }}
                        >
                          <ZoomOutIcon
                            sx={{ color: 'text.secondary', fontSize: 24 }}
                          />
                          <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={(_e, value) => setZoom(value as number)}
                            sx={{
                              flex: 1,
                              '& .MuiSlider-thumb': {
                                width: 20,
                                height: 20,
                              },
                              '& .MuiSlider-rail': {
                                opacity: 0.3,
                              },
                            }}
                          />
                          <ZoomInIcon
                            sx={{ color: 'text.secondary', fontSize: 24 }}
                          />
                        </Box>
                      </Grid>

                      <Grid xs={12}>
                        <Stack direction={'row'} spacing={2}>
                          <Button
                            onClick={() => setIsDialogOpen(false)}
                            fullWidth
                            variant="outlined"
                            size={isMobile ? 'large' : 'medium'}
                            sx={{
                              borderRadius: 2,
                              textTransform: 'none',
                              fontWeight: 600,
                              height: 48,
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleCropSave}
                            variant="contained"
                            fullWidth
                            size={isMobile ? 'large' : 'medium'}
                            sx={{
                              borderRadius: 2,
                              textTransform: 'none',
                              fontWeight: 600,
                              height: 48,
                            }}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </Container>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
