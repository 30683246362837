import Box from '@mui/material/Box/Box';
import { Button, Card, CardMedia, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { NewEventStepProps } from '../index.tsx';
import { EventPlannerTypeEnum } from '../../../../types/eventPlanner.ts';

export interface EventConfiguratorStepProps extends NewEventStepProps {
  setEventConfig: (props: EventConfigCallbackProps) => void;
  eventConfig: EventConfigCallbackProps;
  eventType: EventPlannerTypeEnum;
}

export interface EventConfigCallbackProps {
  eventName: string;
  startDate: Date;
  endDate: Date;
  numberOfAttendees: number;
}

const eventTypeImages = {
  [EventPlannerTypeEnum.Wedding]:
    'https://media.istockphoto.com/photos/closeup-of-couple-holding-hands-picture-id1295707411?b=1&k=20&m=1295707411&s=170667a&w=0&h=IaIPqI7So8motTjfEIyBXrepLA0UZ-Sp9nMjsLNFCE4=',
  [EventPlannerTypeEnum.Birthday]:
    'https://th.bing.com/th/id/OIP.NtVXc8p6pDFVGV2wcmt4jQHaEi?rs=1&pid=ImgDetMain',
  [EventPlannerTypeEnum.TeamBuilding]:
    'https://www.opstart.ca/wp-content/uploads/2018/09/benefits-of-team-building.jpeg',
  [EventPlannerTypeEnum.BachelorParty]:
    'https://th.bing.com/th/id/R.c326635912b4e583e2e6423b669903e0?rik=NamSXK%2bMUK1Row&pid=ImgRaw&r=0',
  [EventPlannerTypeEnum.Seminar]:
    'https://th.bing.com/th/id/OIP.ht4qJWbTrmhrUJE8BvZUYwHaE8?rs=1&pid=ImgDetMain',
  [EventPlannerTypeEnum.Custom]:
    'https://images.unsplash.com/photo-1511578314322-379afb476865',
};

const EventConfiguratorStep: FC<EventConfiguratorStepProps> = ({
  eventConfig,
  setEventConfig,
  handleNext,
  handleBack,
  eventType,
}: EventConfiguratorStepProps) => {
  const [eventName, setEventName] = React.useState(eventConfig.eventName || '');
  const [numberOfAttendees, setNumberOfAttendees] = React.useState(
    eventConfig.numberOfAttendees || 0
  );

  // dates
  const [startDate, setStartDate] = React.useState<Dayjs>(
    dayjs(eventConfig.startDate || new Date())
  );
  const [endDate, setEndDate] = React.useState<Dayjs>(
    dayjs(eventConfig.endDate || new Date())
  );

  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    if (eventName && numberOfAttendees && startDate && endDate) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [eventName, numberOfAttendees, startDate, endDate]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setEventConfig({
      eventName,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      numberOfAttendees,
    });
    handleNext();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              border: '2px solid rgba(212, 175, 55, 0.3)',
              borderRadius: '12px',
              overflow: 'hidden',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                borderColor: '#D4AF37',
                transform: 'translateY(-4px)',
                boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
              },
            }}
          >
            <CardMedia
              sx={{
                height: 200,
                filter: 'brightness(0.8)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  filter: 'brightness(1)',
                  transform: 'scale(1.05)',
                },
              }}
              component="img"
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-expect-error
              src={eventTypeImages[eventType]}
              alt={EventPlannerTypeEnum[eventType]}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box mb={2}>
                  <TextField
                    defaultValue={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    fullWidth
                    id="event_name_field"
                    label="Name of the Event"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box mb={2}>
                  <TextField
                    type={'number'}
                    defaultValue={numberOfAttendees}
                    onChange={(e) =>
                      setNumberOfAttendees(parseInt(e.target.value))
                    }
                    fullWidth
                    id="number_of_attendies_field"
                    label="Number of attendies"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box mb={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format={'DD/MM/YYYY'}
                      onChange={(date: Dayjs | null) => {
                        if (date) setStartDate(date);
                      }}
                      value={startDate}
                      defaultValue={startDate}
                      sx={{ minWidth: '100%' }}
                      label="Start Date"
                      slotProps={{
                        popper: {
                          sx: {
                            '& .MuiPaper-root': {
                              backgroundColor: '#121212',
                              color: '#D4AF37',
                              border: '2px solid rgba(212, 175, 55, 0.3)',
                              '& .MuiPickersDay-root': {
                                color: '#D4AF37',
                                '&:hover': {
                                  backgroundColor: 'rgba(212, 175, 55, 0.2)',
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#D4AF37',
                                  color: '#000',
                                  '&:hover': {
                                    backgroundColor: '#D4AF37',
                                  },
                                },
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: 'rgba(212, 175, 55, 0.7)',
                              },
                              '& .MuiPickersCalendarHeader-label': {
                                color: '#D4AF37',
                              },
                              '& .MuiIconButton-root': {
                                color: '#D4AF37',
                              },
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box mb={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format={'DD/MM/YYYY'}
                      onChange={(date: Dayjs | null) => {
                        if (date) setEndDate(date);
                      }}
                      value={endDate}
                      defaultValue={endDate}
                      sx={{ minWidth: '100%' }}
                      label="End Date"
                      slotProps={{
                        popper: {
                          sx: {
                            '& .MuiPaper-root': {
                              backgroundColor: '#121212',
                              color: '#D4AF37',
                              border: '2px solid rgba(212, 175, 55, 0.3)',
                              '& .MuiPickersDay-root': {
                                color: '#D4AF37',
                                '&:hover': {
                                  backgroundColor: 'rgba(212, 175, 55, 0.2)',
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#D4AF37',
                                  color: '#000',
                                  '&:hover': {
                                    backgroundColor: '#D4AF37',
                                  },
                                },
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: 'rgba(212, 175, 55, 0.7)',
                              },
                              '& .MuiPickersCalendarHeader-label': {
                                color: '#D4AF37',
                              },
                              '& .MuiIconButton-root': {
                                color: '#D4AF37',
                              },
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            size={'large'}
            sx={{ mt: 2, mr: 2 }}
            disabled={isNextButtonDisabled}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </Button>
          <Button
            size={'large'}
            sx={{ mt: 2, mr: 2 }}
            color={'warning'}
            variant="contained"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </Box>
    </Box>
  );
};
export default EventConfiguratorStep;
