import React from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { CategoryServiceCard } from './CategoryServiceCard';
import {
  selectCategoryServiceError,
  selectCategoryServices,
  selectCategoryServiceStatus,
  setCurrentCategoryService,
} from '../../../../store/slices/categoryServiceSlice.ts';
import { CategoryService } from '../../../../types/states/CategoryServiceState.ts';

interface CategoryServiceProps {
  onEdit: (subCategory: CategoryService) => void;
  onDelete: (subCategory: CategoryService) => void;
}

export const CategoryServiceGrid: React.FC<CategoryServiceProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const categoryServices = useSelector(selectCategoryServices);
  const status = useSelector(selectCategoryServiceStatus);
  const error = useSelector(selectCategoryServiceError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (categoryServices.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No Category Services found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={4} padding={2}>
      {categoryServices.map((categoryService, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={categoryService.id}>
          <CategoryServiceCard
            categoryService={categoryService}
            index={index}
            onEdit={() => {
              dispatch(setCurrentCategoryService(categoryService));
              onEdit(categoryService);
            }}
            onDelete={() => onDelete(categoryService)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
