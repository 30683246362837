import Grid from '@mui/material/Grid2';
import { CompanyOptionsCard } from './CompanyOptionsCard.tsx';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ChatIcon from '@mui/icons-material/Chat';
import { useSelector } from 'react-redux';
import { selectCurrentCompany } from '../../../../store/slices/companySlice';
import { CompanyStatus } from '../../../../types/states/CompanyState';

export const CompanyOptionsGrid = () => {
  const currentCompany = useSelector(selectCurrentCompany);
  const isActive = currentCompany?.status === CompanyStatus.Active;

  const options = [
    {
      id: 0,
      name: 'Company Overview',
      description: 'View all company details in one place',
      link: '/company-overview?companyId=' + currentCompany?.id,
      icon: <VisibilityIcon sx={{ fontSize: 60 }} />,
    },
    {
      id: 1,
      name: 'Company Details',
      description: 'Edit company details and logo',
      href: 'details',
      icon: <EditIcon sx={{ fontSize: 60 }} />,
    },
    {
      id: 2,
      name: 'Listings',
      description: 'Manage users and permissions',
      href: 'listings',
      icon: <DescriptionIcon sx={{ fontSize: 60 }} />,
      disabled: !isActive,
      disabledMessage: 'Company must be active to manage listings',
    },
    {
      id: 3,
      name: 'Subscriptions',
      description: 'Manage company subscriptions',
      href: 'subscription',
      icon: <ReceiptLongIcon sx={{ fontSize: 60 }} />,
      disabled: !isActive,
      disabledMessage: 'Company must be active to manage subscriptions',
    },
    {
      id: 4,
      name: 'Messages',
      description: 'View and manage company messages',
      link: '/messages?isCompany=true&companyId=' + currentCompany?.id,
      icon: <ChatIcon sx={{ fontSize: 60 }} />,
      disabled: !isActive,
      disabledMessage: 'Company must be active to view messages',
    },
  ];

  return (
    <Grid container spacing={3}>
      {options.map((option) => (
        <Grid size={{ xs: 12, sm: 6, md: 4 }} key={option.id}>
          <CompanyOptionsCard option={option} />
        </Grid>
      ))}
    </Grid>
  );
};
