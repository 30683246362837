import {
  AppBar,
  Box,
  Container,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import MobileNavbar from './mobileNavbar.tsx';
import NavbarLogo from './navbarLogo.tsx';
import { ProfileMenu } from './profileMenu.tsx';
import { MenuItemLink, NavItemsLeft, NavItemsRight } from './renderMenu.tsx';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice.ts';
import logo from '../../../public/images/favicon.png';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme } from '../../context/ThemeContext.tsx';

const logoProps = {
  src: logo,
  alt: 'logo',
  sx: {
    width: '60px',
    height: '60px',
    padding: '4px',
    filter: 'brightness(1.2) contrast(1.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      filter: 'brightness(1.4) contrast(1.2)',
      transform: 'scale(1.05)',
    },
  },
};

export interface NavbarProps {
  leftColumnItems: MenuItemLink[];
  rightColumnItems: MenuItemLink[];
}

const Navbar = ({ leftColumnItems, rightColumnItems }: NavbarProps) => {
  const { user } = useSelector(selectAuth);
  const { mode, toggleColorMode } = useTheme();
  const handleThemeToggle = () => {
    toggleColorMode();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'rgba(0, 0, 0, 0.85)',
        backdropFilter: 'blur(8px)',
        borderBottom: '2px solid',
        borderColor: 'rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          borderColor: '#D4AF37',
          boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            minHeight: { xs: '64px', md: '70px' },
            py: 0.5,
          }}
        >
          <Grid container alignItems="center">
            {/* Desktop Logo */}
            <Grid item xs={12} md={3}>
              {/* Desktop View */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <NavbarLogo logoProps={logoProps} />
                </Stack>
              </Box>

              {/* Mobile View */}
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  width: '100%',
                  position: 'relative',
                  alignItems: 'center',
                  minHeight: '50px',
                }}
              >
                {/* Mobile Menu - Left */}
                <Box sx={{ position: 'absolute', left: 0 }}>
                  <MobileNavbar
                    leftColumnItems={leftColumnItems}
                    rightColumnItems={rightColumnItems}
                  />
                </Box>

                {/* Mobile Logo - Center */}
                <Box
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <NavbarLogo
                    logoProps={{
                      ...logoProps,
                      sx: {
                        ...logoProps.sx,
                        width: '80px',
                        height: '80px',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Navigation Items - Desktop */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <NavItemsLeft items={leftColumnItems} />
              </Box>
            </Grid>

            {/* Right Side Items - Desktop */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                {!user && <NavItemsRight items={rightColumnItems} />}
                <IconButton
                  onClick={handleThemeToggle}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      color: '#D4AF37',
                    },
                  }}
                >
                  {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
                {user && <ProfileMenu />}
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
