import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface VideoDisplayProps {
  videoLinks: string[];
}

export const VideoDisplay: React.FC<VideoDisplayProps> = ({ videoLinks }) => {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);
  const theme = useTheme();

  const handleVideoClick = (index: number) => {
    setSelectedVideoIndex(index);
  };

  const handleClose = () => {
    setSelectedVideoIndex(null);
  };

  if (!videoLinks || videoLinks.length === 0) return null;

  return (
    <Box mt={5} mb={5} component={'div'}>
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {videoLinks.map((link, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <Box
              sx={{
                position: 'relative',
                paddingTop: '56.25%', // 16:9 aspect ratio
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 1,
                overflow: 'hidden',
                cursor: 'pointer',
                border: `1px solid ${theme.palette.divider}`,
                '&:hover': {
                  '& .hover-overlay': {
                    opacity: 1,
                  },
                },
                // Always show overlay on touch devices
                '@media (hover: none)': {
                  '& .hover-overlay': {
                    opacity: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  },
                },
              }}
              onClick={() => handleVideoClick(index)}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  pointerEvents: 'none', // Prevent iframe interaction in grid view
                }}
                src={`${link}?controls=0&autoplay=0`} // Disable controls in grid view
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Box
                className="hover-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <PlayCircleOutlineIcon 
                  sx={{ 
                    color: 'white',
                    fontSize: { xs: 32, sm: 48 },
                  }} 
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: 'white',
                    textAlign: 'center',
                    px: 1,
                    display: { xs: 'block', sm: 'none' },
                  }}
                >
                  Tap to play
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={selectedVideoIndex !== null}
        onClose={handleClose}
        aria-labelledby="video-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: { xs: '100%', sm: 'auto' },
            maxWidth: '960px',
            maxHeight: { xs: '100%', sm: '90vh' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: { xs: 0, sm: 2 },
            m: { xs: 0, sm: 2 },
            outline: 'none',
            borderRadius: { xs: 0, sm: 1 },
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: { xs: 4, sm: 8 },
              top: { xs: 4, sm: 8 },
              color: 'grey.500',
              zIndex: 1,
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedVideoIndex !== null && (
            <Box 
              sx={{ 
                flexGrow: 1,
                position: 'relative',
                height: { xs: '100%', sm: 'auto' },
                '&::before': {
                  content: '""',
                  display: { xs: 'none', sm: 'block' },
                  paddingTop: '56.25%',
                },
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                }}
                src={`${videoLinks[selectedVideoIndex]}?autoplay=1`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
