import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AppDispatch } from '../../../store/store';
import { deleteProduct } from '../../../store/slices/productSlice';
import { Product } from '../../../types/product';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product | null;
}

export const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({ open, onClose, product }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = async () => {
    if (product) {
      try {
        await dispatch(deleteProduct(product.id)).unwrap();
        onClose();
      } catch (error) {
        console.error('Failed to delete product:', error);
      }
    }
  };

  if (!product) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Product</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the product "{product.name}"? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
