import React, { useState } from 'react';
import { Box, Chip, Typography, Popover } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Region } from '../../types/locations';

interface LocationDisplayProps {
  locations: Region[];
}

export const LocationDisplay: React.FC<LocationDisplayProps> = ({ locations }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeRegion, setActiveRegion] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, regionId: string) => {
    setAnchorEl(event.currentTarget);
    setActiveRegion(regionId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRegion(null);
  };

  // Filter out locations and counties that are not selected
  const selectedLocations = locations?.filter((region) => {
    const hasSelectedCounties = region.counties.some((county) => county.isSelected);
    return region.isSelected || hasSelectedCounties;
  });

  if (!selectedLocations?.length) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {selectedLocations.map((region) => {
          const selectedCounties = region.counties.filter((county) => county.isSelected);
          
          // If the entire region is selected or there are more than 5 selected counties, show a grouped chip
          if (region.isSelected || selectedCounties.length > 5) {
            return (
              <React.Fragment key={region.id}>
                <Box
                  onClick={(e) => handleClick(e, region.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Chip
                    icon={<LocationOnIcon />}
                    deleteIcon={<ExpandMoreIcon />}
                    onDelete={() => {}} // Required for the delete icon to show
                    label={`${region.name} ${region.isSelected ? '(All counties)' : `(${selectedCounties.length} counties)`}`}
                    variant={region.isSelected ? "filled" : "outlined"}
                    sx={{
                      borderColor: '#D4AF37',
                      borderWidth: '1.5px',
                      color: region.isSelected ? '#000000' : '#D4AF37',
                      backgroundColor: region.isSelected ? '#D4AF37' : 'transparent',
                      height: '32px',
                      fontWeight: 500,
                      '& .MuiSvgIcon-root': {
                        color: region.isSelected ? '#000000' : '#D4AF37',
                      },
                      '&:hover': {
                        backgroundColor: region.isSelected ? '#B49030' : 'rgba(212, 175, 55, 0.08)',
                        borderColor: region.isSelected ? '#B49030' : '#D4AF37',
                      },
                    }}
                  />
                </Box>
                <Popover
                  open={Boolean(anchorEl) && activeRegion === region.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      mt: 1,
                      p: 2,
                      maxWidth: '300px',
                      backgroundColor: '#000000',
                      border: '1px solid',
                      borderColor: 'rgba(212, 175, 55, 0.3)',
                    },
                  }}
                >
                  <Typography variant="subtitle2" sx={{ mb: 1, color: '#D4AF37' }}>
                    {region.name} Counties:
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {region.counties.map((county) => (
                      <Chip
                        key={county.id}
                        label={county.name}
                        size="small"
                        variant="outlined"
                        sx={{
                          borderColor: '#D4AF37',
                          borderWidth: '1px',
                          color: '#D4AF37',
                          height: '24px',
                          fontSize: '0.75rem',
                          backgroundColor: 'transparent',
                        }}
                      />
                    ))}
                  </Box>
                </Popover>
              </React.Fragment>
            );
          }
          
          // Show individual counties if 5 or fewer are selected
          return selectedCounties.map((county) => (
            <Chip
              key={`${region.id}-${county.id}`}
              icon={<LocationOnIcon />}
              label={`${region.name} - ${county.name}`}
              variant="outlined"
              sx={{
                borderColor: '#D4AF37',
                borderWidth: '1.5px',
                color: '#D4AF37',
                height: '32px',
                fontWeight: 500,
                '& .MuiSvgIcon-root': {
                  color: '#D4AF37',
                },
              }}
            />
          ));
        })}
      </Box>
    </Box>
  );
};
