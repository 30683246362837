import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store/store.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from '../../store/slices/authSlice.ts';
import i18next from '../../utilis/i18next.ts';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  // Get the full path including search params
  const from = location.state?.from
    ? `${location.state.from.pathname}${location.state.from.search}`
    : '/';

  const auth = useSelector((state: RootState) => state.auth);

  const getGenericErrorMessage = (error: string) => {
    if (error.includes('invalid_credentials')) {
      return i18next.t('Views.LoginPage.Errors.InvalidCredentials');
    } else if (error.includes('email_not_verified')) {
      return i18next.t('Views.LoginPage.Errors.EmailNotVerified');
    } else if (error.includes('network')) {
      return i18next.t('Views.LoginPage.Errors.NetworkError');
    } else {
      return i18next.t('Views.LoginPage.Errors.Generic');
    }
    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.status === 'failed') {
      setErrorMessage(getGenericErrorMessage(auth.error || ''));
      setShowError(true);
    } else if (auth.status === 'succeeded') {
      // Navigate to the complete URL including search params
      navigate(from);
    }
  }, [auth, navigate, from]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowError(false);
    await dispatch(
      login({
        email,
        password,
      })
    );
  };

  const handleCloseError = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          pt: { xs: 4, sm: 8 },
          pb: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          mt={10}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              onClose={handleCloseError}
              severity="error"
              sx={{
                width: '100%',
                backgroundColor: 'rgba(211, 47, 47, 0.95)',
                color: '#fff',
                backdropFilter: 'blur(10px)',
              }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: 600,
              textAlign: 'center',
              background:
                'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundSize: '200% auto',
              animation: 'shine 3s linear infinite, slideDown 0.8s ease-out',
              '@keyframes shine': {
                to: {
                  backgroundPosition: '200% center',
                },
              },
              '@keyframes slideDown': {
                from: {
                  opacity: 0,
                  transform: 'translateY(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateY(0)',
                },
              },
            }}
          >
            {i18next.t('Views.LoginPage.Title')}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleLogin}
            sx={{
              width: '100%',
              background: 'rgba(0, 0, 0, 0.6)',
              backdropFilter: 'blur(10px)',
              p: 3,
              borderRadius: 1,
              border: '1px solid rgba(212, 175, 55, 0.3)',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
              position: 'relative',
              overflow: 'hidden',
              animation: 'formAppear 0.8s ease-out 0.2s both',
              '@keyframes formAppear': {
                from: {
                  opacity: 0,
                  transform: 'scale(0.95)',
                  filter: 'blur(5px)',
                },
                to: {
                  opacity: 1,
                  transform: 'scale(1)',
                  filter: 'blur(0)',
                },
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '1px',
                background:
                  'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '1px',
                background:
                  'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
              },
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18next.t('Views.LoginPage.Email')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                    borderWidth: '1px',
                    transition: 'all 0.2s ease',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D4AF37',
                    borderWidth: '1px',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                    borderWidth: '1px',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
                mb: 2,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18next.t('Views.LoginPage.Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                    borderWidth: '1px',
                    transition: 'all 0.2s ease',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D4AF37',
                    borderWidth: '1px',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                    borderWidth: '1px',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
                mb: 1,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  sx={{
                    color: 'rgba(212, 175, 55, 0.5)',
                    '&.Mui-checked': {
                      color: '#D4AF37',
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.875rem',
                  }}
                >
                  {i18next.t('Views.LoginPage.RememberMe')}
                </Typography>
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              disabled={auth.status === 'loading'}
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                borderColor: '#D4AF37',
                color: '#D4AF37',
                fontWeight: 600,
                letterSpacing: '0.5px',
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.3s ease',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background:
                    'linear-gradient(120deg, transparent, rgba(212, 175, 55, 0.2), transparent)',
                  transform: 'translateX(-100%)',
                },
                '&:hover': {
                  borderColor: '#FFD700',
                  color: '#FFD700',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 15px rgba(212, 175, 55, 0.2)',
                  '&::before': {
                    animation: 'shimmer 1.5s infinite',
                  },
                },
                '@keyframes shimmer': {
                  '100%': {
                    transform: 'translateX(100%)',
                  },
                },
                '&:active': {
                  transform: 'translateY(0)',
                },
                '&:disabled': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  color: 'rgba(212, 175, 55, 0.3)',
                },
              }}
            >
              {auth.status === 'loading'
                ? i18next.t('Views.LoginPage.Loading')
                : i18next.t('Views.LoginPage.Login')}
            </Button>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ textAlign: { xs: 'center', sm: 'left' } }}
              >
                <Link
                  href="/forgot-password"
                  variant="body2"
                  sx={{
                    color: '#D4AF37',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    opacity: 0.8,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {i18next.t('Views.LoginPage.ForgotPassword')}
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ textAlign: { xs: 'center', sm: 'right' } }}
              >
                <Link
                  href="/register"
                  variant="body2"
                  sx={{
                    color: '#D4AF37',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    opacity: 0.8,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {i18next.t('Views.LoginPage.Register')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
