import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import authReducer from './slices/authSlice';
import categoriesReducer from './slices/categorySlice';
import subCategoriesReducer from './slices/subCategorySlice';
import companiesReducer from './slices/companySlice.ts';
import categoryServicesReducer from './slices/categoryServiceSlice.ts';
import subCategoryServicesReducer from './slices/subCategoryServiceSlice.ts';
import productsReducer from './slices/productSlice.ts';
import subscriptionReducer from './slices/subscriptionSlice.ts';
import listingsReducer from './slices/listingSlice';
import locationsReducer from './slices/locationSlice';
import chatReducer from './slices/chatSlice';
import eventsReducer from './slices/eventSlice';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'chats', 'events', 'companies', 'listings'], // Don't persist chats, events, and infiniteScroll
};

const rootReducer = {
  auth: authReducer,
  categories: categoriesReducer,
  subCategories: subCategoriesReducer,
  companies: companiesReducer,
  categoryServices: categoryServicesReducer,
  subCategoryServices: subCategoryServicesReducer,
  products: productsReducer,
  subscription: subscriptionReducer,
  listings: listingsReducer,
  locations: locationsReducer,
  chats: chatReducer,
  events: eventsReducer,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer)
);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };
export default store;
