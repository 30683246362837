import { Region } from './locations';
import { Category } from './states/CategoryState';
import { EventPlannerType } from './eventPlanner';

export enum EventPlannerType {
  None = 0,
  Wedding = 1,
  Birthday = 2,
  Corporate = 3,
  Other = 4,
}

export interface EventConfig {
  eventName: string;
  description?: string;
  startDate: string;
  endDate: string;
  budget?: number;
  numberOfAttendees: number;
}

export interface CategoryService {
  id: string;
  categoryId: string;
  name: string;
  groupName: string;
  shortDescription: string;
  isSelected: boolean;
}

export interface SubCategoryService {
  id: string;
  name: string;
  isSelected: boolean;
}

export interface CategoryData {
  subCategories: SubCategory[];
  categoryServices: CategoryService[];
}

export interface SubCategory {
  id: string;
  name: string;
  description: string;
  services: CategoryService[];
}

export interface Category {
  id: string;
  name: string;
  description: string;
  subCategories: SubCategory[];
}

export interface CategoryMap {
  [categoryUuid: string]: CategoryData;
}

export type EventStatus = 'draft' | 'pending' | 'approved' | 'rejected';

export interface Event {
  id: string;
  eventType: EventPlannerType;
  eventConfig: EventConfig;
  eventLocations: Region[];
  eventCategories: Category[];
  selectedServices: CategoryMap[];
  status: EventStatus;
  lastUpdated: string;
  createdAt: string;
}

// Helper type for new events that don't have an ID yet
export type NewEvent = Omit<Event, 'id' | 'createdAt'>;

// Helper function to create a new empty event
export const createEmptyEvent = (): NewEvent => ({
  eventType: EventPlannerType.None,
  eventConfig: {
    eventName: '',
    startDate: '',
    endDate: '',
    numberOfAttendees: 0,
  },
  eventLocations: [],
  eventCategories: [],
  selectedServices: {},
  status: 'draft',
  lastUpdated: new Date().toISOString(),
});

// Re-export all types
export type {
  Event,
  EventConfig,
  CategoryService,
  SubCategory,
  Category,
  CategorySelection,
  EventStatus,
  NewEvent,
};
