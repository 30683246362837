import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { SubCategoryService } from '../../../../types/states/SubCategoryServiceState.ts';
import { deleteSubCategoryService } from '../../../../store/slices/subCategoryServiceSlice.ts';

interface DeleteSubCategoryServiceConfirmationDialogProps {
  open: boolean;
  subCategoryService: SubCategoryService | null;
  onClose: () => void;
}

export const DeleteSubCategoryServiceConfirmationDialog: React.FC<
  DeleteSubCategoryServiceConfirmationDialogProps
> = ({ open, subCategoryService, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = async () => {
    if (subCategoryService) {
      try {
        await dispatch(
          deleteSubCategoryService(subCategoryService.id)
        ).unwrap();
        onClose();
      } catch (error) {
        console.error('Failed to delete SubCategory Service:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Sub-SubCategory</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the SubCategory Service "
          {subCategoryService?.name}"? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
