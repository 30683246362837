import { Box, Card, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectStylePreference } from '../../../../store/slices/companySlice';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export interface CompanyOptionsCardProps {
  option: {
    id: number;
    name: string;
    description: string;
    href?: string;
    link?: string;
    icon: JSX.Element;
    disabled?: boolean;
    disabledMessage?: string;
  };
}

export const CompanyOptionsCard = (props: CompanyOptionsCardProps) => {
  const styleVariant = useSelector(selectStylePreference) || 'default';
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get('companyId');

  // Shared styles
  const baseCardStyle = {
    position: 'relative',
    height: '100%',
    width: '100%',
    maxWidth: '320px',
    margin: '0 auto',
    cursor: 'pointer',
    animation: `fadeSlideIn 0.6s ease-out ${props.option.id * 0.1}s both`,
    '@keyframes fadeSlideIn': {
      from: {
        opacity: 0,
        transform: 'translateY(20px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    '& .option-icon': {
      transition: 'all 0.3s ease',
    },
  };

  // Style variants
  const cardStyles = {
    default: {
      ...baseCardStyle,
      minHeight: '280px',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(8px)',
      borderRadius: '16px',
      border: '2px solid rgba(212, 175, 55, 0.3)',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        transform: 'translateY(-8px)',
        borderColor: '#D4AF37',
        boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
        '& .option-icon': {
          transform: 'scale(1.05)',
          color: '#D4AF37',
        },
        '& .option-name': {
          color: '#D4AF37',
        },
      },
    },
    minimal: {
      ...baseCardStyle,
      minHeight: '240px',
      backgroundColor: 'transparent',
      borderRadius: '8px',
      border: '1px solid rgba(212, 175, 55, 0.15)',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(212, 175, 55, 0.05)',
        '& .option-icon': {
          filter: 'brightness(1.2)',
        },
        '& .option-name': {
          background: 'linear-gradient(to right, #D4AF37, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
    },
    elegant: {
      ...baseCardStyle,
      minHeight: '300px',
      background:
        'linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.95) 100%)',
      borderRadius: '20px',
      padding: '2px',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '20px',
        padding: '2px',
        background:
          'linear-gradient(135deg, rgba(212, 175, 55, 0.5), rgba(212, 175, 55, 0.1))',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
      },
      '&:hover': {
        transform: 'translateY(-4px)',
        '& .option-icon': {
          transform: 'scale(1.05)',
        },
        '& .option-name': {
          letterSpacing: '1px',
          color: '#D4AF37',
        },
      },
    },
    modern: {
      ...baseCardStyle,
      minHeight: '260px',
      backgroundColor: 'rgba(18, 18, 18, 0.95)',
      borderRadius: '12px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '120px',
        background:
          'linear-gradient(45deg, rgba(212, 175, 55, 0.1), rgba(255, 215, 0, 0.05))',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
      },
      '&:hover': {
        transform: 'scale(1.02)',
        '& .option-icon': {
          transform: 'scale(1.1)',
        },
        '& .option-name': {
          color: '#D4AF37',
        },
      },
    },
  };

  const iconContainerStyles = {
    default: {
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '8px',
    },
    minimal: {
      background: 'transparent',
    },
    elegant: {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '12px',
    },
    modern: {
      background: 'transparent',
      position: 'relative',
      zIndex: 1,
    },
  };

  const handleClick = () => {
    if (props.option.disabled) {
      enqueueSnackbar(props.option.disabledMessage, {
        variant: 'warning',
        preventDuplicate: true,
      });
      return;
    }
    if (props.option.link) {
      console.log('navigate to', props.option.link);
      navigate(props.option.link);
    } else if (props.option.href && companyId) {
      navigate(`/company?companyId=${companyId}&view=${props.option.href}`);
    }
  };

  const card = (
    <Card
      onClick={handleClick}
      elevation={0}
      sx={{
        ...cardStyles[styleVariant],
        opacity: props.option.disabled ? 0.6 : 1,
        cursor: props.option.disabled ? 'not-allowed' : 'pointer',
        '&:hover': {
          ...cardStyles[styleVariant]['&:hover'],
          transform: props.option.disabled
            ? 'none'
            : cardStyles[styleVariant]['&:hover'],
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
            ...iconContainerStyles[styleVariant],
          }}
        >
          <Box
            className="option-icon"
            sx={{
              color: 'rgba(212, 175, 55, 0.9)',
              fontSize: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              transition: 'all 0.3s ease',
              filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
            }}
          >
            {props.option.icon}
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            className="option-name"
            sx={{
              color:
                styleVariant === 'minimal' ? 'rgba(212, 175, 55, 0.9)' : '#fff',
              fontWeight: 500,
              mb: 1,
              transition: 'all 0.3s ease',
              textAlign: 'center',
            }}
          >
            {props.option.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              transition: 'all 0.3s ease',
            }}
          >
            {props.option.disabled
              ? props.option.disabledMessage
              : props.option.description}
          </Typography>
        </Box>
      </Box>
    </Card>
  );

  return props.option.disabled ? (
    <Tooltip title={props.option.disabledMessage} arrow placement="top">
      <Box>{card}</Box>
    </Tooltip>
  ) : (
    card
  );
};
