import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  TextField,
  useTheme,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Region } from '../../../../types/locations';

interface RegionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  region?: Region; 
  onSave: (updatedRegion: Region) => void;
  mode?: 'create' | 'edit';
}

const RegionDialog: FC<RegionDialogProps> = ({
  isOpen,
  onClose,
  region,
  onSave,
  mode = 'edit',
}) => {
  const theme = useTheme();
  const [editedRegion, setEditedRegion] = useState<Region>(() => ({
    id: region?.id ?? '',
    name: region?.name ?? '',
    counties: region?.counties ?? [],
    isSelected: region?.isSelected ?? false,
  }));
  const [newCountyName, setNewCountyName] = useState('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedRegion((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  const handleAddCounty = () => {
    if (newCountyName.trim()) {
      setEditedRegion((prev) => ({
        ...prev,
        counties: [
          ...prev.counties,
          {
            id: `temp-${Date.now()}`,
            name: newCountyName.trim(),
            regionId: prev.id,
          },
        ],
      }));
      setNewCountyName('');
    }
  };

  const handleRemoveCounty = (countyId: string) => {
    setEditedRegion((prev) => ({
      ...prev,
      counties: prev.counties.filter((county) => county.id !== countyId),
    }));
  };

  const handleSave = () => {
    if (mode === 'create') {
      // For create mode, omit the ID
      const { id, ...regionWithoutId } = editedRegion;
      onSave(regionWithoutId as Region);
    } else {
      // For edit mode, include the ID
      onSave(editedRegion);
    }
    onClose();
  };

  const isValid = editedRegion.name.trim() !== '';

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(212, 175, 55, 0.2)',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'rgba(212, 175, 55, 0.9)',
          pb: 1,
        }}
      >
        <Typography variant="h6" component="span">
          {mode === 'create' ? 'Create New Region' : 'Edit Region'}
        </Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              color: 'rgba(255, 255, 255, 0.9)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 2 }}>
        <Box sx={{ mb: 3, mt: 1 }}>
          <TextField
            fullWidth
            label="Region Name"
            value={editedRegion.name}
            onChange={handleNameChange}
            variant="outlined"
            error={!isValid}
            helperText={!isValid ? 'Region name is required' : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D4AF37',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                '&.Mui-focused': {
                  color: '#D4AF37',
                },
              },
              '& .MuiFormHelperText-root': {
                color: theme.palette.error.main,
              },
            }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'rgba(255, 255, 255, 0.9)',
              mb: 1,
            }}
          >
            Counties
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              mb: 2,
              minHeight: '48px',
            }}
          >
            {editedRegion.counties.map((county) => (
              <Chip
                key={county.id}
                label={county.name}
                onDelete={() => handleRemoveCounty(county.id)}
                sx={{
                  backgroundColor: 'rgba(212, 175, 55, 0.2)',
                  color: '#FFFFFF',
                  border: '1px solid rgba(212, 175, 55, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.3)',
                  },
                  '& .MuiChip-deleteIcon': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      color: 'rgba(255, 99, 71, 0.9)',
                    },
                  },
                }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              size="small"
              label="Add County"
              value={newCountyName}
              onChange={(e) => setNewCountyName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddCounty();
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(212, 175, 55, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D4AF37',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-focused': {
                    color: '#D4AF37',
                  },
                },
              }}
            />
            <Button
              variant="outlined"
              onClick={handleAddCounty}
              sx={{
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: 'rgba(212, 175, 55, 0.9)',
                '&:hover': {
                  borderColor: '#D4AF37',
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                },
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          pb: 2,
          borderTop: '1px solid rgba(212, 175, 55, 0.1)',
          pt: 2,
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!isValid}
          sx={{
            backgroundColor: 'rgba(212, 175, 55, 0.9)',
            color: '#000',
            '&:hover': {
              backgroundColor: '#D4AF37',
            },
            '&.Mui-disabled': {
              backgroundColor: 'rgba(212, 175, 55, 0.3)',
              color: 'rgba(0, 0, 0, 0.4)',
            },
          }}
        >
          {mode === 'create' ? 'Create Region' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegionDialog;
