import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import { selectCategories } from '../../store/slices/categorySlice';
import { Category } from '../../types/states/CategoryState';
import MinimalCategoryCard from '../../components/category/MinimalCategoryCard';
import { FC } from 'react';

export const FilterIcon = <CategoryIcon />;

interface CategoryFilterSectionProps {
  selectedCategoryId: string | null;
  isCollapsed?: boolean;
}

const CategoryFilterSection: FC<CategoryFilterSectionProps> = ({
  selectedCategoryId,
  isCollapsed = false,
}) => {
  const categories = useSelector(selectCategories) as Category[];

  const renderCategories = () => {
    return categories.map((category, i) => (
      <Grid xs={4} sm={3} md="auto" key={i}>
        <MinimalCategoryCard
          category={category}
          index={i}
          isSelected={category.id === selectedCategoryId}
        />
      </Grid>
    ));
  };

  if (isCollapsed) {
    return (
      <Tooltip title="Categories" placement="right">
        <IconButton
          sx={{
            color: 'rgba(212, 175, 55, 0.8)',
            '&:hover': {
              color: '#D4AF37',
            },
          }}
        >
          <CategoryIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (!categories || categories.length === 0) {
    return (
      <Box sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" height={200} />
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 56,
            color: '#D4AF37',
            '&.Mui-expanded': {
              minHeight: 56,
            },
          },
          '& .MuiAccordionSummary-content': {
            margin: '12px 0',
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '8px 0 16px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#D4AF37' }} />}
          sx={{
            flexDirection: 'row',
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            },
          }}
        >
          <CategoryIcon sx={{ color: '#D4AF37' }} />
          <Typography>Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            sx={{
              flexWrap: { xs: 'wrap', md: 'wrap' },
              overflowX: { xs: 'visible', md: 'visible' },
              justifyContent: 'center',
              alignItems: 'center',
              pb: { xs: 0, md: 2 },
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(212, 175, 55, 0.3)',
                borderRadius: '4px',
                '&:hover': {
                  background: 'rgba(212, 175, 55, 0.5)',
                },
              },
            }}
          >
            {renderCategories()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CategoryFilterSection;
