import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SubCategory } from '../../../../types/states/SubCategoryState.ts';
import { useSelector } from 'react-redux';
import { selectCategories } from '../../../../store/slices/categorySlice.ts';
import { Link as RouterLink } from 'react-router-dom';
import { ImageView } from '../../../../components/inputs/ImageView.tsx';

interface SubCategoryCardProps {
  subCategory: SubCategory;
  index: number;
  onEdit: (subCategory: SubCategory) => void;
  onDelete: (subCategory: SubCategory) => void;
}

export const SubCategoryCard: React.FC<SubCategoryCardProps> = ({
  subCategory,
  index,
  onEdit,
  onDelete,
}) => {
  const categories = useSelector(selectCategories);
  const category = categories.find((cat) => cat.id === subCategory.categoryId);

  return (
    <Card
      elevation={2}
      sx={{
        backgroundColor: '#1a1a1a',
        height: '100%',
        maxWidth: '280px',
        margin: '0 auto',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        transform: index % 2 === 0 ? 'rotate(-1deg)' : 'rotate(1deg)',
        '&:hover': {
          transform:
            index % 2 === 0
              ? 'translateY(-4px) rotate(0deg)'
              : 'translateY(-4px) rotate(0deg)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          '& .card-icon': {
            transform: 'scale(1.1)',
          },
          '& .card-title': {
            color: 'primary.main',
          },
        },
      }}
    >
      <Box
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {/* Category Section */}
        {category && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              p: 1,
              borderRadius: 1, // backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }}
          >
            <Avatar src={category.imageUri ? undefined : undefined}>
              <ImageView
                fileId={category.imageUri || ''}
                alt={category.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {!category.imageUri && category.name.charAt(0)}
            </Avatar>
            <Link
              component={RouterLink}
              to={`/admin/categories/${category.id}`}
              color="primary"
              sx={{
                textDecoration: 'none',
                fontSize: '0.875rem',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {category.name}
            </Link>
          </Box>
        )}

        {/* Subcategory Image */}
        {/*<Box*/}
        {/*    sx={{*/}
        {/*        width: '100%',*/}
        {/*        height: '160px',*/}
        {/*        mb: 2,*/}
        {/*        borderRadius: 1,*/}
        {/*        overflow: 'hidden',*/}
        {/*        display: 'flex',*/}
        {/*        justifyContent: 'center',*/}
        {/*        alignItems: 'center',*/}
        {/*        backgroundColor: 'rgba(0, 0, 0, 0.2)',*/}
        {/*    }}*/}
        {/*>*/}
        {/*    {subCategory.imageUri ? (*/}
        {/*        <img*/}
        {/*            src={`${apiUrl}/files/${subCategory.imageUri}`}*/}
        {/*            alt={subCategory.name}*/}
        {/*            style={{*/}
        {/*                width: '100%',*/}
        {/*                height: '100%',*/}
        {/*                objectFit: 'cover',*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    ) : (*/}
        {/*        <BrokenImageIcon*/}
        {/*            sx={{*/}
        {/*                width: '48px',*/}
        {/*                height: '48px',*/}
        {/*                color: 'text.secondary',*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    )}*/}
        {/*</Box>*/}

        {/* Subcategory Content */}
        <Typography
          variant="h6"
          className="card-title"
          sx={{
            color: 'white',
            mb: 1,
            fontWeight: 600,
            transition: 'color 0.3s ease-in-out',
          }}
        >
          {subCategory.name}
        </Typography>

        <Typography
          variant="body2"
          color="white"
          sx={{
            mb: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            minHeight: '3.6em',
          }}
        >
          {subCategory.description}
        </Typography>

        <CardActions sx={{ justifyContent: 'center', gap: 1, mt: 'auto' }}>
          <IconButton
            onClick={() => onEdit(subCategory)}
            color="primary"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => onDelete(subCategory)}
            color="error"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  );
};
