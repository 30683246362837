// src/pages/AdminCenter/AdminCategories/components/CategoryServiceList.tsx

import React from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  selectCategoryServiceError,
  selectCategoryServices,
  selectCategoryServiceStatus,
  setCurrentCategoryService,
} from '../../../../store/slices/categoryServiceSlice.ts';
import { CategoryService } from '../../../../types/states/CategoryServiceState.ts';

interface CategoryServiceListProps {
  onEdit: (categoryService: CategoryService) => void;
  onDelete: (categoryService: CategoryService) => void;
}

export const CategoryServiceList: React.FC<CategoryServiceListProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const categoryServices = useSelector(selectCategoryServices);
  const status = useSelector(selectCategoryServiceStatus);
  const error = useSelector(selectCategoryServiceError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (categoryServices.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No Category Services found
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Group Name</TableCell>
            <TableCell>Short Description</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categoryServices.map((categoryService) => (
            <TableRow key={categoryService.id}>
              <TableCell>{categoryService.name}</TableCell>
              <TableCell>{categoryService.groupName}</TableCell>
              <TableCell>{categoryService.shortDescription}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    dispatch(setCurrentCategoryService(categoryService));
                    onEdit(categoryService);
                  }}
                  color="primary"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => onDelete(categoryService)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
