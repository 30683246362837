import Header from '../../../../components/header';
import { Box, Container } from '@mui/material';
import { AppDispatch } from '../../../../store/store.ts';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  fetchListingApprovals,
  selectListings,
} from '../../../../store/slices/listingSlice.ts';
import { selectCategories } from '../../../../store/slices/categorySlice.ts';
import { selectSubCategories } from '../../../../store/slices/subCategorySlice.ts';
import { selectSubCategoryServices } from '../../../../store/slices/subCategoryServiceSlice.ts';
import ListingApprovalsCard from './ListingApprovalsCard';

const ListingApprovals = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedListings = useSelector(selectListings);
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const subCategoryServices = useSelector(selectSubCategoryServices);

  useEffect(() => {
    dispatch(fetchListingApprovals());
  }, [dispatch]);

  return (
    <Box>
      <Header
        title="Listing Approvals"
        subtitle="Review and manage listing approval requests"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box>
          <Grid container spacing={2}>
            {selectedListings.map((listing, index) => (
              <Grid item key={listing.id} xs={12} sm={6} md={4} lg={3}>
                <ListingApprovalsCard
                  listing={listing}
                  categories={categories}
                  subCategories={subCategories}
                  subCategoryServices={subCategoryServices}
                  index={index}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ListingApprovals;
