import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { AppDispatch } from '../../../../store/store.ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProducts,
  selectProducts,
} from '../../../../store/slices/productSlice.ts';
import { selectCurrentCompany } from '../../../../store/slices/companySlice.ts';
import {
  fetchSubscriptionStatus,
  selectCurrentSubscription,
} from '../../../../store/slices/subscriptionSlice.ts';
import { ProductType } from '../../../../types/product.ts';
import { SubscriptionManagement } from './SubscriptionManagement';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../../utilis/axios.ts';
import { apiUrl } from '../../../../main.tsx';

export const CompanySubscriptions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector(selectProducts);
  const company = useSelector(selectCurrentCompany);
  const currentSubscription = useSelector(selectCurrentSubscription);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [managementOpen, setManagementOpen] = useState(false);

  useEffect(() => {
    console.log('Company:', company);
    console.log('Current Subscription:', currentSubscription);

    dispatch(fetchProducts());
    console.log('Fetching subscription status');
    dispatch(fetchSubscriptionStatus(company?.activeSubscriptionId || ''));
  }, []);

  // Helper function to check if subscription is active
  const isSubscriptionActive = (product: any) => {
    return company?.activeProductId === product.id;
  };

  // Helper function to get button text for subscription plans
  const getButtonText = (
    product: any,
    isLoading: boolean,
    isActive: boolean
  ) => {
    if (isLoading) return 'Processing...';
    if (isActive) return 'Current Plan';
    if (currentSubscription && product.type === ProductType.Subscription)
      return 'Switch Plan';
    return 'Subscribe Now';
  };

  const handleSubscribe = async (productId: string) => {
    try {
      setLoading((prev) => ({ ...prev, [productId]: true }));
      const product = products.find((p) => p.id === productId);
      if (!product) return;

      const response = await axiosInstance.post(
        apiUrl + '/stripe/create-checkout-session',
        {
          productId: product.id,
          companyId: company?.id,
        }
      );

      // const { url } = response.data;
      // if (!clientSecret) {
      //   throw new Error('No client secret received from the server');
      // }

      window.location.href = response.data;
      // navigate(response.data);
      // `/company?companyId=${company.id}&view=checkout&productId=${productId}&clientSecret=${clientSecret}`
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading((prev) => ({ ...prev, [productId]: false }));
    }
  };

  const handleManageSubscription = () => {
    console.log('Current Subscription: ', currentSubscription);
    setManagementOpen(true);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        p: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: 'primary.main',
          textAlign: 'center',
          mb: 4,
          fontWeight: 600,
          textShadow: '0 2px 4px rgba(0,0,0,0.2)',
        }}
      >
        Select Your Plan
      </Typography>
      <Typography
        variant="h4"
        sx={{
          color: 'primary.main',
          mb: 4,
          textAlign: 'center',
        }}
      >
        Subscription Plans
      </Typography>

      {/* Subscriptions Section */}
      <Grid container spacing={3} sx={{ mb: 6 }}>
        {products
          .filter((product) => product.type === ProductType.Subscription)
          .map((product) => {
            const isActive = isSubscriptionActive(product);
            return (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    background: isActive
                      ? 'rgba(212, 175, 55, 0.15)'
                      : 'rgba(18, 18, 18, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 2,
                    border: isActive
                      ? '2px solid rgba(212, 175, 55, 0.5)'
                      : '1px solid rgba(212, 175, 55, 0.1)',
                    transition: 'all 0.3s ease-in-out',
                    position: 'relative',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 24px rgba(212, 175, 55, 0.15)',
                      border: isActive
                        ? '2px solid rgba(212, 175, 55, 0.7)'
                        : '1px solid rgba(212, 175, 55, 0.3)',
                    },
                  }}
                >
                  {isActive && (
                    <>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          zIndex: 2,
                        }}
                      >
                        <Button
                          onClick={handleManageSubscription}
                          sx={{
                            minWidth: 'auto',
                            p: 1,
                            color: 'rgba(212, 175, 55, 0.9)',
                            '&:hover': {
                              background: 'rgba(212, 175, 55, 0.1)',
                            },
                          }}
                        >
                          <SettingsIcon />
                        </Button>
                        <Box
                          sx={{
                            background: 'rgba(212, 175, 55, 0.9)',
                            borderRadius: '12px',
                            px: 2,
                            py: 0.5,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#000',
                              fontWeight: 600,
                            }}
                          >
                            Current Plan
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      pt: isActive ? 5 : 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        color: isActive ? '#fff' : 'primary.main',
                        fontWeight: 600,
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: isActive
                          ? 'rgba(255, 255, 255, 0.9)'
                          : 'rgba(255, 255, 255, 0.7)',
                        mb: 3,
                        flexGrow: 1,
                      }}
                    >
                      {product.description}
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: isActive ? '#fff' : 'primary.main',
                          fontWeight: 700,
                          textAlign: 'center',
                        }}
                      >
                        ${product.price}
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{
                            color: isActive
                              ? 'rgba(255, 255, 255, 0.7)'
                              : 'rgba(255, 255, 255, 0.7)',
                            ml: 1,
                          }}
                        >
                          {product.type === ProductType.OneTime ? '' : '/year'}
                        </Typography>
                      </Typography>
                      {isActive && (
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'rgba(255, 255, 255, 0.9)',
                              textAlign: 'center',
                            }}
                          >
                            {company?.eventClickTokens_Subscription} clicks
                            remaining
                          </Typography>
                          {company?.currentPeriodEnd && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                textAlign: 'center',
                                mt: 0.5,
                              }}
                            >
                              Resets on{' '}
                              {new Date(
                                company?.currentPeriodEnd
                              ).toLocaleDateString()}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading[product.id] || isActive}
                      onClick={() => handleSubscribe(product.id)}
                      sx={{
                        py: 1.5,
                        background: isActive
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
                        '&:hover': {
                          background: isActive
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'linear-gradient(45deg, #FFD700 30%, #D4AF37 90%)',
                        },
                        '&:disabled': {
                          background: isActive
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(255, 255, 255, 0.12)',
                          color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                        },
                      }}
                    >
                      {getButtonText(product, loading[product.id], isActive)}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      {/* One-Time Purchases Section */}
      <Typography
        variant="h4"
        sx={{
          color: 'primary.main',
          mb: 4,
          mt: 6,
          textAlign: 'center',
        }}
      >
        One-Time Purchases
      </Typography>

      <Grid container spacing={3}>
        {products
          .filter((product) => product.type === ProductType.OneTime)
          .map((product) => {
            const isActive = isSubscriptionActive(product);
            return (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    background: isActive
                      ? 'rgba(212, 175, 55, 0.15)'
                      : 'rgba(18, 18, 18, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 2,
                    border: isActive
                      ? '2px solid rgba(212, 175, 55, 0.5)'
                      : '1px solid rgba(212, 175, 55, 0.1)',
                    transition: 'all 0.3s ease-in-out',
                    position: 'relative',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 8px 24px rgba(212, 175, 55, 0.15)',
                      border: isActive
                        ? '2px solid rgba(212, 175, 55, 0.7)'
                        : '1px solid rgba(212, 175, 55, 0.3)',
                    },
                  }}
                >
                  {isActive && (
                    <>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          zIndex: 2,
                        }}
                      >
                        <Button
                          onClick={() => setManagementOpen(true)}
                          sx={{
                            minWidth: 'auto',
                            p: 1,
                            color: 'rgba(212, 175, 55, 0.9)',
                            '&:hover': {
                              background: 'rgba(212, 175, 55, 0.1)',
                            },
                          }}
                        >
                          <SettingsIcon />
                        </Button>
                        <Box
                          sx={{
                            background: 'rgba(212, 175, 55, 0.9)',
                            borderRadius: '12px',
                            px: 2,
                            py: 0.5,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#000',
                              fontWeight: 600,
                            }}
                          >
                            Current Plan
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      pt: isActive ? 5 : 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        color: isActive ? '#fff' : 'primary.main',
                        fontWeight: 600,
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: isActive
                          ? 'rgba(255, 255, 255, 0.9)'
                          : 'rgba(255, 255, 255, 0.7)',
                        mb: 3,
                        flexGrow: 1,
                      }}
                    >
                      {product.description}
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: isActive ? '#fff' : 'primary.main',
                          fontWeight: 700,
                          textAlign: 'center',
                        }}
                      >
                        ${product.price}
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{
                            color: isActive
                              ? 'rgba(255, 255, 255, 0.7)'
                              : 'rgba(255, 255, 255, 0.7)',
                            ml: 1,
                          }}
                        >
                          {product.type === ProductType.OneTime ? '' : '/year'}
                        </Typography>
                      </Typography>
                      {isActive && currentSubscription && (
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'rgba(255, 255, 255, 0.9)',
                              textAlign: 'center',
                            }}
                          >
                            {company?.eventClickTokens_Subscription} clicks
                            remaining
                          </Typography>
                          {currentSubscription.periodEnd && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                textAlign: 'center',
                                mt: 0.5,
                              }}
                            >
                              Resets on{' '}
                              {new Date(
                                currentSubscription.periodEnd
                              ).toLocaleDateString()}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading[product.id] || isActive}
                      onClick={() => handleSubscribe(product.id)}
                      sx={{
                        py: 1.5,
                        background: isActive
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
                        '&:hover': {
                          background: isActive
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'linear-gradient(45deg, #FFD700 30%, #D4AF37 90%)',
                        },
                        '&:disabled': {
                          background: isActive
                            ? 'rgba(255, 255, 255, 0.1)'
                            : 'rgba(255, 255, 255, 0.12)',
                          color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                        },
                      }}
                    >
                      {getButtonText(product, loading[product.id], isActive)}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      {currentSubscription && (
        <SubscriptionManagement
          subscription={currentSubscription}
          open={managementOpen}
          onClose={() => setManagementOpen(false)}
        />
      )}
    </Box>
  );
};
