import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';
import { AppDispatch } from '../../../store/store';
import {
  createProduct,
  selectCurrentProduct,
  updateProduct,
} from '../../../store/slices/productSlice';
import {
  CreateProductRequest,
  Currency,
  ProductType,
  UpdateProductRequest,
} from '../../../types/product';

interface ProductDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentProduct = useSelector(selectCurrentProduct);
  const [formData, setFormData] = React.useState({
    name: '',
    description: '',
    price: '',
    isActive: true,
    type: ProductType.OneTime,
    currency: Currency.USD,
    listingsTokens: 0,
    eventClickTokens: 0,
  });

  React.useEffect(() => {
    if (currentProduct) {
      setFormData({
        name: currentProduct.name,
        description: currentProduct.description,
        price: currentProduct.price.toString(),
        isActive: currentProduct.isActive,
        type: currentProduct.type,
        currency: currentProduct.currency,
        listingsTokens: currentProduct.listingsTokens,
        eventClickTokens: currentProduct.eventClickTokens,
      });
    } else {
      setFormData({
        name: '',
        description: '',
        price: '',
        isActive: true,
        type: ProductType.OneTime,
        currency: Currency.USD,
        listingsTokens: 0,
        eventClickTokens: 0,
      });
    }
  }, [currentProduct, open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      isActive: event.target.checked,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (currentProduct) {
        const updateData: UpdateProductRequest = {
          id: currentProduct.id,
          name: formData.name,
          description: formData.description,
          price: parseFloat(formData.price),
          isActive: formData.isActive,
          type: formData.type,
          currency: formData.currency,
          listingsTokens: parseInt(formData.listingsTokens.toString()),
          eventClickTokens: parseInt(formData.eventClickTokens.toString()),
        };
        await dispatch(updateProduct(updateData)).unwrap();
      } else {
        const createData: CreateProductRequest = {
          name: formData.name,
          description: formData.description,
          price: parseFloat(formData.price),
          isActive: formData.isActive,
          type: formData.type,
          currency: formData.currency,
          listingsTokens: parseInt(formData.listingsTokens.toString()),
          eventClickTokens: parseInt(formData.eventClickTokens.toString()),
        };
        await dispatch(createProduct(createData)).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Failed to save product:', error);
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      description: '',
      price: '',
      isActive: true,
      type: ProductType.OneTime,
      currency: Currency.USD,
      listingsTokens: 0,
      eventClickTokens: 0,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {currentProduct ? 'Edit Product' : 'Add New Product'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
          />
          <TextField
            name="price"
            label="Price"
            value={formData.price}
            onChange={handleChange}
            type="number"
            inputProps={{ step: '0.01' }}
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                name="isActive"
                checked={formData.isActive}
                onChange={handleSwitchChange}
              />
            }
            label="Active"
          />
          <TextField
            select
            name="type"
            label="Type"
            value={formData.type}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value={ProductType.OneTime}>One Time</MenuItem>
            <MenuItem value={ProductType.Subscription}>Subscription</MenuItem>
          </TextField>
          <TextField
            select
            name="currency"
            label="Currency"
            value={formData.currency}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value={Currency.USD}>USD</MenuItem>
            <MenuItem value={Currency.EUR}>EUR</MenuItem>
            <MenuItem value={Currency.NOK}>NOK</MenuItem>
            <MenuItem value={Currency.SEK}>SEK</MenuItem>
            <MenuItem value={Currency.DKK}>DKK</MenuItem>
          </TextField>
          <TextField
            name="listingsTokens"
            label="Number of Listings"
            value={formData.listingsTokens}
            onChange={handleChange}
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
          />
          <TextField
            name="eventClickTokens"
            label="Interest Tokens"
            value={formData.eventClickTokens}
            onChange={handleChange}
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!formData.name || !formData.price}
        >
          {currentProduct ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
