import { Outlet, useLocation } from 'react-router-dom';
import { FC } from 'react';
import Navbar from '../navbar';
import Footer from '../footer';
import { MenuItemLink } from '../navbar/renderMenu.tsx';
import { Box } from '@mui/material';

const Layout: FC = () => {
  const location = useLocation();
  const hideFooter = location.pathname.includes('/messages');

  const leftColumnItems: MenuItemLink[] = [
    { link: '/', text: 'Layout.TopBar.Home' },
    { link: 'Routes.Advertise', text: 'Layout.TopBar.Advertise' },
    { link: 'Routes.Contact', text: 'Layout.TopBar.Contact' },
  ];
  const rightColumnItems: MenuItemLink[] = [
    { link: 'Routes.Login', text: 'Layout.TopBar.Login' },
  ];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box component="header">
        <Navbar
          leftColumnItems={leftColumnItems}
          rightColumnItems={rightColumnItems}
        />
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xs: '64px', md: '70px' }, // Match navbar heights
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Outlet />
      </Box>

      {!hideFooter && (
        <Box component="footer">
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default Layout;
