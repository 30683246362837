import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store.ts';
import {
  selectListings,
  selectListingStatus,
} from '../../../../store/slices/listingSlice.ts';
import {
  selectCurrentSubscription,
  selectSubscriptionStatus,
} from '../../../../store/slices/subscriptionSlice.ts';
import { apiUrl } from '../../../../main.tsx';
import {
  Company,
  CompanyStatus,
} from '../../../../types/states/CompanyState.ts';

const statusDisplayMap: Record<number, { text: string; gradient: string }> = {
  [CompanyStatus.Active]: {
    text: 'Active',
    gradient: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
  },
  [CompanyStatus.AwaitingApproval]: {
    text: 'Awaiting Approval',
    gradient: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  },
  [CompanyStatus.Deleted]: {
    text: 'Deleted',
    gradient: 'linear-gradient(45deg, #EF5350 30%, #E57373 90%)',
  },
};

export interface CompanyCardProps {
  company: Company;
  index: number;
  variant?: 'default' | 'minimal' | 'elegant' | 'modern';
}

export const CompanyApprovalsCard = (props: CompanyCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [imageError, setImageError] = useState(false);
  const { variant = 'default' } = props;
  console.log('Company Card Props:', props);
  const listings = useSelector(selectListings);
  const listingStatus = useSelector(selectListingStatus);
  const subscription = useSelector(selectCurrentSubscription);
  const subscriptionStatus = useSelector(selectSubscriptionStatus);

  useEffect(() => {
    console.log('Listings:', listings);
    console.log('Listing Status:', listingStatus);
    console.log('Subscription:', subscription);
    console.log('Subscription Status:', subscriptionStatus);
  }, [listings, listingStatus, subscription, subscriptionStatus]);

  console.log('Company Status:', props.company.status);
  console.log(
    'Status Gradient:',
    statusDisplayMap[props.company.status]?.gradient
  );

  // Shared styles
  const baseCardStyle = {
    position: 'relative',
    height: '100%',
    width: '100%',
    maxWidth: '320px',
    margin: '0 auto',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    animation: `fadeSlideIn 0.6s ease-out ${props.index * 0.1}s both`,
    '@keyframes fadeSlideIn': {
      from: {
        opacity: 0,
        transform: 'translateY(20px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    '& .company-logo': {
      borderRadius: '10px',
    },
  };

  // Style variants
  const cardStyles = {
    default: {
      ...baseCardStyle,
      minHeight: '280px',
      bgcolor: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(8px)',
      borderRadius: '16px',
      border: '2px solid rgba(212, 175, 55, 0.3)',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        transform: 'translateY(-8px)',
        borderColor: '#D4AF37',
        boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
        '& .company-logo': {
          transform: 'scale(1.05)',
        },
        '& .company-name': {
          color: '#D4AF37',
        },
      },
    },
    minimal: {
      ...baseCardStyle,
      minHeight: '240px',
      bgcolor: 'transparent',
      borderRadius: '8px',
      border: '1px solid rgba(212, 175, 55, 0.15)',
      transition: 'all 0.2s ease',
      '&:hover': {
        bgcolor: 'rgba(212, 175, 55, 0.05)',
        '& .company-logo': {
          filter: 'brightness(1.2)',
        },
        '& .company-name': {
          background: 'linear-gradient(to right, #D4AF37, #FFD700)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
    },
    elegant: {
      ...baseCardStyle,
      minHeight: '300px',
      background:
        'linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.95) 100%)',
      borderRadius: '20px',
      padding: '2px',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '20px',
        padding: '2px',
        background:
          'linear-gradient(135deg, rgba(212, 175, 55, 0.5), rgba(212, 175, 55, 0.1))',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
      },
      '&:hover': {
        transform: 'translateY(-4px)',
        '& .company-logo': {
          transform: 'scale(1.05)',
        },
        '& .company-name': {
          letterSpacing: '1px',
          color: '#D4AF37',
        },
      },
    },
    modern: {
      ...baseCardStyle,
      minHeight: '260px',
      bgcolor: 'rgba(18, 18, 18, 0.95)',
      borderRadius: '12px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '120px',
        background:
          'linear-gradient(45deg, rgba(212, 175, 55, 0.1), rgba(255, 215, 0, 0.05))',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
      },
      '&:hover': {
        transform: 'scale(1.02)',
        '& .company-logo': {
          transform: 'scale(1.1)',
        },
        '& .company-name': {
          color: '#D4AF37',
        },
      },
    },
  };

  const logoContainerStyles = {
    default: {
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '8px',
    },
    minimal: {
      background: 'transparent',
    },
    elegant: {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '12px',
    },
    modern: {
      background: 'transparent',
      position: 'relative',
      zIndex: 1,
    },
  };

  return (
    <>
      <Card elevation={0} sx={cardStyles[variant]}>
        <Box
          onClick={() =>
            navigate(`/company-overview?companyId=${props.company.id}`)
          }
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              ...logoContainerStyles[variant],
            }}
          >
            {props.company && !imageError ? (
              <Box
                component="img"
                src={`${apiUrl}/files/${props.company.logoImageUri}`}
                alt={`${props?.company?.name} logo`}
                onError={() => setImageError(true)}
                className="company-logo"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  transition: 'all 0.3s ease',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                }}
              />
            ) : (
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(212, 175, 55, 0.3)',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                }}
              >
                {props?.company?.name.charAt(0)}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              className="company-name"
              sx={{
                color:
                  variant === 'minimal' ? 'rgba(212, 175, 55, 0.9)' : '#fff',
                fontWeight: 500,
                mb: 1,
                transition: 'all 0.3s ease',
                textAlign: 'center',
              }}
            >
              {props?.company?.name}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Chip
                variant="filled"
                label={
                  statusDisplayMap[props.company.status]?.text || 'Unknown'
                }
                size="small"
                sx={{
                  background:
                    statusDisplayMap[props.company.status]?.gradient ||
                    'linear-gradient(45deg, #9E9E9E 30%, #BDBDBD 90%)',
                  color: '#fff',
                  fontWeight: 500,
                  textTransform: 'none',
                  '& .MuiChip-label': {
                    px: 2,
                  },
                  opacity: 0.9,
                  '&:hover': {
                    opacity: 1,
                  },
                  transition: 'all 0.2s ease-in-out',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                }}
              />
            </Box>

            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                textAlign: 'center',
                lineHeight: 1.6,
                mt: variant === 'elegant' ? 2 : 1,
              }}
            >
              {props.company.shortDescription}
            </Typography>
          </Box>
        </Box>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            padding: '16px',
            borderTop: '1px solid rgba(212, 175, 55, 0.1)',
            bgcolor: 'rgba(0, 0, 0, 0.2)',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            onClick={() => {
              navigate(
                `/company-overview?companyId=${props.company.id}&mode=review`
              );
            }}
            startIcon={<VisibilityIcon />}
            sx={{
              color: 'rgba(212, 175, 55, 0.7)',
              '&:hover': {
                color: '#D4AF37',
                bgcolor: 'rgba(212, 175, 55, 0.1)',
              },
            }}
            size="small"
          >
            Review
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
