import { Region } from '../../../../types/locations.ts';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import RegionDialog from './RegionDialog';
import DeleteConfirmDialog from './DeleteConfirmDialog';

interface RegionCardProps {
  region: Region;
  onUpdate?: (updatedRegion: Region) => void;
  onDelete?: (regionId: string) => void;
}

const RegionCard = ({ region, onUpdate, onDelete }: RegionCardProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const displayedCounties = region.counties.slice(0, 3);
  const remainingCount = region.counties.length - displayedCounties.length;

  const handleEdit = () => {
    setIsDialogOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete(region.id);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleSave = (updatedRegion: Region) => {
    if (onUpdate) {
      // Preserve the original ID when updating
      onUpdate({
        ...updatedRegion,
        id: region.id,
      });
    }
    setIsDialogOpen(false);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          position: 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          height: '100%',
          width: '100%',
          minHeight: '180px',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          backdropFilter: 'blur(8px)',
          border: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.3)',
          '&:hover': {
            transform: 'translateY(-8px)',
            borderColor: '#D4AF37',
            boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
            '& .region-title': {
              background:
                'linear-gradient(to right, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundSize: '200% auto',
              animation: 'shine 3s linear infinite',
            },
            '&::after': {
              opacity: 1,
            },
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(135deg, rgba(212, 175, 55, 0.1) 0%, rgba(212, 175, 55, 0) 100%)',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            pointerEvents: 'none',
          },
          '@keyframes shine': {
            to: {
              backgroundPosition: '200% center',
            },
          },
        }}
      >
        <CardContent
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 2,
            }}
          >
            <Typography
              className="region-title"
              variant="h6"
              component="h2"
              sx={{
                color: 'rgba(212, 175, 55, 0.9)',
                fontWeight: 600,
                fontSize: '1.25rem',
                transition: 'all 0.3s ease-in-out',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
              }}
            >
              {region.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <IconButton
                size="small"
                onClick={handleEdit}
                sx={{
                  color: 'rgba(212, 175, 55, 0.9)',
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.1)',
                    color: '#D4AF37',
                  },
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleDelete}
                sx={{
                  color: 'rgba(255, 99, 71, 0.9)',
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 99, 71, 0.1)',
                    color: '#FF6347',
                  },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          <Typography
            variant="subtitle2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 2,
              fontSize: '0.875rem',
            }}
          >
            {region.counties.length} {region.counties.length === 1 ? 'County' : 'Counties'}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              mt: 'auto',
            }}
          >
            {displayedCounties.map((county) => (
              <Chip
                key={county.id}
                label={county.name}
                size="small"
                sx={{
                  backgroundColor: 'rgba(212, 175, 55, 0.2)',
                  color: '#FFFFFF',
                  border: '1px solid rgba(212, 175, 55, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(212, 175, 55, 0.3)',
                  },
                }}
              />
            ))}
            {remainingCount > 0 && (
              <Chip
                label={`+${remainingCount} more`}
                size="small"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: '#FFFFFF',
                  border: '1px solid rgba(255, 255, 255, 0.2)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <RegionDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        region={region}
        onSave={handleSave}
        mode="edit"
      />

      <DeleteConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        regionName={region.name}
      />
    </>
  );
};

export default RegionCard;
