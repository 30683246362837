import { Box, Button, Container, Grid } from '@mui/material';
import Header from '../../../components/header';
import { useEffect, useState } from 'react';
import {
  createRegion,
  deleteRegion,
  fetchLocations,
  selectRegions,
  updateRegion,
} from '../../../store/slices/locationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store.ts';
import RegionCard from './components/RegionCard.tsx';
import RegionDialog from './components/RegionDialog.tsx';
import AddIcon from '@mui/icons-material/Add';
import { Region } from '../../../types/locations.ts';
import {
  CreateRegionRequest,
  UpdateRegionRequest,
} from '../../../types/requests/locations.ts';
import { SectionHeader } from '../../../components/dashboard/SectionHeader.tsx';
import PlaceIcon from '@mui/icons-material/Place';

const AdminRegions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const regions = useSelector(selectRegions);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  const handleCreateRegion = async (newRegion: Omit<Region, 'id'>) => {
    const request: CreateRegionRequest = {
      name: newRegion.name,
      counties: newRegion.counties.map((county) => ({ name: county.name })),
    };

    try {
      await dispatch(createRegion(request)).unwrap();
      setIsCreateDialogOpen(false);
    } catch (error) {
      console.error('Failed to create region:', error);
    }
  };

  const handleUpdateRegion = async (updatedRegion: Region) => {
    const request: UpdateRegionRequest = {
      name: updatedRegion.name,
      counties: updatedRegion.counties.map((county) => ({ name: county.name })),
    };

    try {
      await dispatch(
        updateRegion({
          id: updatedRegion.id.replace('regions/', ''),
          request,
        })
      ).unwrap();
    } catch (error) {
      console.error('Failed to update region:', error);
    }
  };

  const handleDeleteRegion = async (regionId: string) => {
    try {
      // Extract the ID part after "regions/"
      const id = regionId.replace('regions/', '');
      await dispatch(deleteRegion(id)).unwrap();
    } catch (error) {
      console.error('Failed to delete region:', error);
    }
  };
  const buttonStyle = {
    backgroundColor: 'rgba(212, 175, 55, 0.05)',
    border: '2px solid',
    borderColor: 'rgba(212, 175, 55, 0.3)',
    color: '#000',
    padding: '10px 24px',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#D4AF37',
      borderColor: '#D4AF37',
      color: '#000',
      boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
      transform: 'translateY(-1px)',
    },
    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
    },
  };

  return (
    <Box>
      <Header
        title="Admin Regions"
        subtitle="Manage regions"
        showBackButton={true}
        actions={[
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsCreateDialogOpen(true)}
            sx={{
              ...buttonStyle,
            }}
          >
            Create Region
          </Button>,
        ]}
      />
      <Container maxWidth="xl">
        <SectionHeader
          title="Regions"
          actions={<> </>}
          key={'regions'}
          icon={
            <PlaceIcon sx={{ fontSize: 32, color: 'primary.main', mr: 1 }} />
          }
        />
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'flex-end',*/}
        {/*    mb: 3,*/}
        {/*    mt: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    startIcon={<AddIcon />}*/}
        {/*    onClick={() => setIsCreateDialogOpen(true)}*/}
        {/*    sx={{*/}
        {/*      backgroundColor: 'rgba(212, 175, 55, 0.9)',*/}
        {/*      color: '#000',*/}
        {/*      '&:hover': {*/}
        {/*        backgroundColor: '#D4AF37',*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Create Region*/}
        {/*  </Button>*/}
        {/*</Box>*/}

        <Grid container spacing={3}>
          {regions.map((region) => (
            <Grid item xs={12} sm={6} md={4} key={region.id}>
              <RegionCard
                region={region}
                onUpdate={handleUpdateRegion}
                onDelete={handleDeleteRegion}
              />
            </Grid>
          ))}
        </Grid>

        <RegionDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onSave={handleCreateRegion}
          mode="create"
        />
      </Container>
    </Box>
  );
};

export default AdminRegions;
