import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { deleteCategory } from '../../../../store/slices/categorySlice';
import { Category } from '../../../../types/states/CategoryState';

interface DeleteConfirmationDialogProps {
  open: boolean;
  category: Category | null;
  onClose: () => void;
}

export const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({ open, category, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = async () => {
    if (category) {
      try {
        await dispatch(deleteCategory(category.id)).unwrap();
        onClose();
      } catch (error) {
        console.error('Failed to delete category:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the category "{category?.name}"? This
          action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
