import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { selectSubCategories } from '../../../../store/slices/subCategorySlice.ts';
import { Link as RouterLink } from 'react-router-dom';
import { SubCategoryService } from '../../../../types/states/SubCategoryServiceState.ts';
import { ImageView } from '../../../../components/inputs/ImageView.tsx';

interface SubCategoryServiceCardProps {
  subCategoryService: SubCategoryService;
  index: number;
  onEdit: (subCategoryService: SubCategoryService) => void;
  onDelete: (subCategoryService: SubCategoryService) => void;
}

export const SubCategoryServiceCard: React.FC<SubCategoryServiceCardProps> = ({
  subCategoryService,
  index,
  onEdit,
  onDelete,
}) => {
  const subCategories = useSelector(selectSubCategories);
  const subCategory = subCategories.find(
    (cat) => cat.id === subCategoryService.subCategoryId
  );

  return (
    <Card
      elevation={2}
      sx={{
        backgroundColor: '#1a1a1a',
        height: '100%',
        maxWidth: '280px',
        margin: '0 auto',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        transform: index % 2 === 0 ? 'rotate(-1deg)' : 'rotate(1deg)',
        '&:hover': {
          transform:
            index % 2 === 0
              ? 'translateY(-4px) rotate(0deg)'
              : 'translateY(-4px) rotate(0deg)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          '& .card-icon': {
            transform: 'scale(1.1)',
          },
          '& .card-title': {
            color: 'primary.main',
          },
        },
      }}
    >
      <Box
        sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {/* SubCategory Section */}
        {subCategory && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              p: 1,
              borderRadius: 1, // backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }}
          >
            <Avatar src={subCategory.imageUri ? undefined : undefined}>
              <ImageView
                fileId={subCategory.imageUri || ''}
                alt={subCategory.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {/*<FileImage*/}
              {/*  fileId={subCategory.imageUri || ''}*/}
              {/*  alt={subCategory.name}*/}
              {/*  style={{*/}
              {/*    width: '100%',*/}
              {/*    height: '100%',*/}
              {/*    objectFit: 'cover',*/}
              {/*  }}*/}
              {/*/>*/}
              {!subCategory.imageUri && subCategory.name.charAt(0)}
            </Avatar>
            <Link
              component={RouterLink}
              to={`/admin/subCategories/${subCategory.id}`}
              color="primary"
              sx={{
                textDecoration: 'none',
                fontSize: '0.875rem',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {subCategory.name}
            </Link>
          </Box>
        )}

        {/* SubsubCategory Content */}
        <Typography
          color={'white'}
          variant="h6"
          className="card-title"
          sx={{
            mb: 1,
            fontWeight: 600,
            transition: 'color 0.3s ease-in-out',
          }}
        >
          {subCategoryService.name}
        </Typography>

        <Typography
          variant="body2"
          color="white"
          sx={{
            mb: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            minHeight: '3.6em',
          }}
        >
          {subCategoryService.groupName}
        </Typography>

        <Typography
          variant="body2"
          color="white"
          sx={{
            mb: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            minHeight: '3.6em',
          }}
        >
          {subCategoryService.shortDescription}
        </Typography>

        <CardActions sx={{ justifyContent: 'center', gap: 1, mt: 'auto' }}>
          <IconButton
            onClick={() => onEdit(subCategoryService)}
            color="primary"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => onDelete(subCategoryService)}
            color="error"
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  );
};
