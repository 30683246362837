export const formatMessageTime = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  // Format time
  const timeString = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  // If message is from today
  if (diffInDays === 0) {
    return timeString;
  }

  // If message is from yesterday
  if (diffInDays === 1) {
    return `Yesterday ${timeString}`;
  }

  // If message is from this week (up to 7 days ago)
  if (diffInDays < 7) {
    return date.toLocaleDateString('en-US', { weekday: 'short' }) + ' ' + timeString;
  }

  // If message is older than a week
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  }) + ' ' + timeString;
};

export const formatChatListTime = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  // If message is from today
  if (diffInDays === 0) {
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  }

  // If message is from yesterday
  if (diffInDays === 1) {
    return 'Yesterday';
  }

  // If message is from this week
  if (diffInDays < 7) {
    return date.toLocaleDateString('en-US', { weekday: 'short' });
  }

  // If message is from this year
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }

  // If message is older
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};
