import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { clearAuthState, selectAuth } from '../../store/slices/authSlice.ts';
import { AppDispatch } from '../../store/store.ts';
import i18next from '../../utilis/i18next.ts';

const goldGradientStyle = {
  background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const menuItemStyle = {
  color: 'rgba(255, 255, 255, 0.9)',
  transition: 'all 0.3s ease',
  py: 1.5,
  px: 2,
  '&:hover': {
    backgroundColor: 'rgba(212, 175, 55, 0.1)',
    ...goldGradientStyle,
  },
};

export const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  return (
    <Box
      sx={{
        flexGrow: 0,
        display: { xs: 'flex', md: 'flex' },
      }}
    >
      <Tooltip title="Open settings">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{
            p: 0.5,
            border: '2px solid transparent',
            transition: 'all 0.3s ease',
            '&:hover': {
              border: '2px solid #D4AF37',
              boxShadow: '0 0 10px rgba(212, 175, 55, 0.3)',
            },
          }}
        >
          <Avatar
            alt={auth.user?.firstName}
            sx={{
              width: 40,
              height: 40,
              bgcolor: 'rgba(212, 175, 55, 0.2)',
              color: '#D4AF37',
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: '45px',
          '& .MuiPaper-root': {
            bgcolor: 'rgba(0, 0, 0, 0.95)',
            border: '1px solid rgba(212, 175, 55, 0.3)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
            minWidth: '200px',
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box sx={{ py: 1 }}>
          {auth.user && (
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate('/messages');
              }}
              sx={menuItemStyle}
            >
              <Typography textAlign="center">Messages</Typography>
            </MenuItem>
          )}
          {/*Admin Dashboard*/}
          {auth.user?.roles.includes('Admin') && (
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate('/admin');
              }}
              sx={menuItemStyle}
            >
              <Typography textAlign="center">Admin Dashboard</Typography>
            </MenuItem>
          )}

          {/*Profile*/}
          <MenuItem
            key="Profile"
            onClick={() => {
              handleCloseUserMenu();
              navigate('/profile');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Profile')}
            </Typography>
          </MenuItem>

          {/*Companies*/}
          <MenuItem
            key="Companies"
            onClick={() => {
              handleCloseUserMenu();
              navigate('/companies');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Companies')}
            </Typography>
          </MenuItem>

          {/*Logout*/}
          <MenuItem
            key="Logout"
            onClick={() => {
              handleCloseUserMenu();
              dispatch(clearAuthState());
              navigate('/');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Logout')}
            </Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
