import { Theme } from '@mui/material';
import { ListingStatus } from '../types/states/ListingState';

export const getStatusChipStyle = (theme: Theme, status: ListingStatus) => {
  const getColors = () => {
    switch (status) {
      case ListingStatus.DRAFT:
        return {
          backgroundColor: theme.palette.grey[600],
          color: theme.palette.grey.contrastText,
        };
      case ListingStatus.PENDING_APPROVAL:
        return {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        };
      case ListingStatus.APPROVED:
        return {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        };
      case ListingStatus.REJECTED:
        return {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        };
      default:
        return {
          backgroundColor: theme.palette.grey[600],
          color: theme.palette.getContrastText(theme.palette.grey[600]),
        };
    }
  };

  const colors = getColors();
  const bgColor = colors.backgroundColor;
  const textColor = colors.color;

  return {
    '&.MuiChip-root.MuiChip-filled': {
      background: `${bgColor} !important`,
      color: textColor,
      '&:hover': {
        background: `${bgColor} !important`,
        opacity: 0.9,
      },
    },
  };
};
