// src/pages/AdminCenter/AdminSubCategories/components/SubCategoryServiceList.tsx

import React from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  selectSubCategoryServiceError,
  selectSubCategoryServices,
  selectSubCategoryServiceStatus,
  setCurrentSubCategoryService,
} from '../../../../store/slices/subCategoryServiceSlice.ts';
import { SubCategoryService } from '../../../../types/states/SubCategoryServiceState.ts';

interface SubCategoryServiceListProps {
  onEdit: (subCategoryService: SubCategoryService) => void;
  onDelete: (subCategoryService: SubCategoryService) => void;
}

export const SubCategoryServiceList: React.FC<SubCategoryServiceListProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const status = useSelector(selectSubCategoryServiceStatus);
  const error = useSelector(selectSubCategoryServiceError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (subCategoryServices.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No SubCategory Services found
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Group Name</TableCell>
            <TableCell>Short Description</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subCategoryServices.map((subCategoryService) => (
            <TableRow key={subCategoryService.id}>
              <TableCell>{subCategoryService.name}</TableCell>
              <TableCell>{subCategoryService.groupName}</TableCell>
              <TableCell>{subCategoryService.shortDescription}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    dispatch(setCurrentSubCategoryService(subCategoryService));
                    onEdit(subCategoryService);
                  }}
                  color="primary"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => onDelete(subCategoryService)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
