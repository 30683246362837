import { Button, Container, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import i18next from '../../../utilis/i18next.ts';

const goldGradientStyle = {
  background: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const EventPlannerSection = () => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.98) 100%)',
        padding: { xs: '60px 0', md: '80px 0' },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background:
            'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '1px',
          background:
            'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
        },
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={4} alignItems="center">
          <Typography
            variant="h2"
            align="center"
            sx={{
              ...goldGradientStyle,
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 600,
              mb: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
          >
            {i18next.t('Views.HomePage.EventPlannerSection.Headline')}
          </Typography>

          <Box
            sx={{
              maxWidth: '800px',
              px: { xs: 2, md: 4 },
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{
                color: 'rgba(255, 255, 255, 0.9)',
                lineHeight: 1.8,
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              {i18next.t('Views.HomePage.EventPlannerSection.Description')}
            </Typography>
          </Box>

          <Button
            size="large"
            component="a"
            href="/planner"
            sx={{
              mt: 4,
              px: 6,
              py: 1.5,
              fontSize: '1.1rem',
              backgroundColor: 'transparent',
              border: '2px solid #D4AF37',
              color: '#D4AF37',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(212, 175, 55, 0.1)',
                borderColor: '#FFD700',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 20px rgba(212, 175, 55, 0.3)',
              },
            }}
          >
            {i18next.t('Views.HomePage.EventPlannerSection.Button')}
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default EventPlannerSection;
