import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import StyledContainer from '../../components/StyledContainer.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectEvents, 
  selectEventStatus, 
  selectEventError,
  fetchEvents,
  deleteEventAsync,
} from '../../store/slices/eventSlice';
import { useEffect } from 'react';
import { AppDispatch } from '../../store/store';
import { useTheme } from '@mui/material/styles';
import { formatDate } from '../../utils/date';

const PlannerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector(selectEvents);
  const status = useSelector(selectEventStatus);
  const error = useSelector(selectEventError);
  const theme = useTheme();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchEvents());
    }
  }, [status, dispatch]);

  const handleAddEvent = () => {
    navigate('/planner/event');
  };

  const handleEditEvent = (eventId: string) => {
    navigate(`/planner/event?id=${eventId}`);
  };

  const getStatusColor = (status: string | undefined): string => {
    if (!status) return theme.palette.info.main;
    
    switch (status.toLowerCase()) {
      case 'approved':
        return theme.palette.success.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'rejected':
        return theme.palette.error.main;
      case 'draft':
        return theme.palette.info.main;
      default:
        return theme.palette.info.main;
    }
  };

  const getStatusLabel = (status: string | undefined): string => {
    if (!status) return 'Draft';
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  const renderEventList = () => {
    if (!events || events.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography variant="body1" color="textSecondary">
            No events found. Create your first event to get started!
          </Typography>
        </Box>
      );
    }

    return events.map((event) => (
      <Grid item xs={12} sm={6} md={4} key={event.id}>
        <Card 
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 6,
              transform: 'scale(1.02)',
              transition: 'all 0.2s ease-in-out'
            }
          }}
          onClick={() => handleEditEvent(event.id)}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
              <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                {event.eventConfig.eventName || 'Untitled Event'}
              </Typography>
              <Chip
                label={getStatusLabel(event.status)}
                size="small"
                sx={{
                  backgroundColor: `${getStatusColor(event.status)}15`,
                  color: getStatusColor(event.status),
                  fontWeight: 'medium',
                  ml: 1
                }}
              />
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Start Date: {formatDate(event.eventConfig.startDate)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Attendees: {event.eventConfig.numberOfAttendees}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="caption" color="text.secondary">
                Last updated: {formatDate(event.lastUpdated)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const buttonStyle = {
    backgroundColor: 'rgba(212, 175, 55, 0.05)',
    border: '2px solid',
    borderColor: 'rgba(212, 175, 55, 0.3)',
    color: '#D4AF37',
    padding: '10px 24px',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#D4AF37',
      borderColor: '#D4AF37',
      color: '#000',
      boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
      transform: 'translateY(-1px)',
    },
    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
    },
  };

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected') {
    return (
      <Box>
        <Header
          title="Event Planner"
          subtitle="Plan and manage your events"
          showBackButton={true}
        />
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          minHeight="200px"
          p={3}
        >
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(fetchEvents())}
            sx={{ mt: 2 }}
          >
            Retry
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Header
        title="Event Planner"
        subtitle="Plan and manage your events"
        showBackButton={true}
        actions={
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              startIcon={<AddIcon sx={{ fontSize: '1.2rem' }} />}
              onClick={handleAddEvent}
              sx={buttonStyle}
            >
              Add Event
            </Button>
          </Box>
        }
      />
      <StyledContainer>
        <Grid container spacing={3}>
          {renderEventList()}
        </Grid>
      </StyledContainer>
    </Box>
  );
};

export default PlannerPage;
