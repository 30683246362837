import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CategoryIcon from '@mui/icons-material/Category';
import { Category } from '../../../../types/states/CategoryState';
import {
  fetchSubCategories,
  selectSubCategories,
} from '../../../../store/slices/subCategorySlice.ts';
import { AppDispatch } from '../../../../store/store.ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCategoryServices,
  selectCategoryServices,
} from '../../../../store/slices/categoryServiceSlice.ts';
import {
  fetchSubCategoryServices,
  selectSubCategoryServices,
} from '../../../../store/slices/subCategoryServiceSlice.ts';

interface SubCategory {
  id: string;
  name: string;
  categoryId: string;
  services: Service[];
  isSelected?: boolean;
}

interface Service {
  id: string;
  name: string;
  isSelected?: boolean;
}

interface CategoryService {
  id: string;
  name: string;
  categoryId: string;
  isSelected?: boolean;
}

interface CategoryDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  category: Category;
  onSave: (
    categoryId: string,
    selectedItems: {
      subCategories: SubCategory[];
      categoryServices: CategoryService[];
    }
  ) => void;
  initialSelections?: {
    subCategories: SubCategory[];
    categoryServices: CategoryService[];
  };
}

export const CategoryDialog: FC<CategoryDialogProps> = ({
  isOpen,
  setIsOpen,
  category,
  onSave,
  initialSelections,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);

  // Get data from Redux store
  const allSubCategories = useSelector(selectSubCategories);
  const allCategoryServices = useSelector(selectCategoryServices);
  const allSubCategoryServices = useSelector(selectSubCategoryServices);

  // Local state for selections
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    SubCategory[]
  >([]);
  const [selectedCategoryServices, setSelectedCategoryServices] = useState<
    CategoryService[]
  >([]);

  // Fetch data when dialog opens
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          dispatch(fetchSubCategories()),
          dispatch(fetchCategoryServices()),
          dispatch(fetchSubCategoryServices()),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, dispatch]);

  // Initialize local state when data is loaded
  useEffect(() => {
    if (allSubCategories && allCategoryServices && allSubCategoryServices) {
      if (initialSelections) {
        // If we have initial selections, merge them with the current data
        const categorySubCategories = allSubCategories
          .filter((sub) => sub.categoryId === category.id)
          .map((sub) => {
            const savedSubCategory = initialSelections.subCategories.find(
              (saved) => saved.id === sub.id
            );

            const subCategoryServices = allSubCategoryServices
              .filter((service) => service.subCategoryId === sub.id)
              .map((service) => {
                const savedService = savedSubCategory?.services.find(
                  (saved) => saved.id === service.id
                );
                return {
                  id: service.id,
                  name: service.name,
                  isSelected: savedService?.isSelected || false,
                };
              });

            return {
              ...sub,
              isSelected: savedSubCategory?.isSelected || false,
              services: subCategoryServices,
            };
          });

        const categoryServices = allCategoryServices
          .filter((service) => service.categoryId === category.id)
          .map((service) => {
            const savedService = initialSelections.categoryServices.find(
              (saved) => saved.id === service.id
            );
            return {
              ...service,
              isSelected: savedService?.isSelected || false,
            };
          });

        setSelectedSubCategories(categorySubCategories);
        setSelectedCategoryServices(categoryServices);
      } else {
        // No initial selections, initialize with current data
        const categorySubCategories = allSubCategories
          .filter((sub) => sub.categoryId === category.id)
          .map((sub) => ({
            ...sub,
            isSelected: false,
            services: allSubCategoryServices
              .filter((service) => service.subCategoryId === sub.id)
              .map((service) => ({
                id: service.id,
                name: service.name,
                isSelected: false,
              })),
          }));

        const categoryServices = allCategoryServices
          .filter((service) => service.categoryId === category.id)
          .map((service) => ({
            ...service,
            isSelected: false,
          }));

        setSelectedSubCategories(categorySubCategories);
        setSelectedCategoryServices(categoryServices);
      }
    }
  }, [
    allSubCategories,
    allCategoryServices,
    allSubCategoryServices,
    category.id,
    initialSelections,
  ]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    console.log(
      'Saving selections:',
      selectedSubCategories,
      selectedCategoryServices
    );
    onSave(category.id, {
      subCategories: selectedSubCategories,
      categoryServices: selectedCategoryServices,
    });
    handleClose();
  };

  const toggleSubCategory = (subCategoryId: string) => {
    setSelectedSubCategories((prev) =>
      prev.map((sub) => {
        if (sub.id === subCategoryId) {
          const newIsSelected = !sub.isSelected;
          return {
            ...sub,
            isSelected: newIsSelected,
            services: sub.services.map((service) => ({
              ...service,
              isSelected: newIsSelected,
            })),
          };
        }
        return sub;
      })
    );
  };

  const toggleService = (subCategoryId: string, serviceId: string) => {
    setSelectedSubCategories((prev) =>
      prev.map((sub) => {
        if (sub.id === subCategoryId) {
          const updatedServices = sub.services.map((service) =>
            service.id === serviceId
              ? { ...service, isSelected: !service.isSelected }
              : service
          );
          return {
            ...sub,
            isSelected: updatedServices.some((service) => service.isSelected),
            services: updatedServices,
          };
        }
        return sub;
      })
    );
  };

  const toggleCategoryService = (serviceId: string) => {
    setSelectedCategoryServices((prev) =>
      prev.map((service) =>
        service.id === serviceId
          ? { ...service, isSelected: !service.isSelected }
          : service
      )
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: 'background.paper',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{category.name}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {selectedSubCategories.length > 0 && (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Sub Categories
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                  {selectedSubCategories.map((subCategory) => (
                    <Card
                      key={subCategory.id}
                      sx={{
                        flex: '0 1 calc(33.33% - 16px)',
                        minWidth: '250px',
                        backgroundColor: theme.palette.background.paper,
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: theme.shadows[8],
                        },
                        border: subCategory.isSelected
                          ? `2px solid ${theme.palette.primary.main}`
                          : 'none',
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                          }}
                        >
                          <Typography variant="subtitle1">
                            {subCategory.name}
                          </Typography>
                          <Chip
                            icon={<CategoryIcon />}
                            label={
                              subCategory.isSelected ? 'Selected' : 'Select All'
                            }
                            onClick={() => toggleSubCategory(subCategory.id)}
                            color={
                              subCategory.isSelected ? 'primary' : 'default'
                            }
                            sx={{ cursor: 'pointer' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {subCategory.services.map((service) => (
                            <Chip
                              key={service.id}
                              label={service.name}
                              onClick={() =>
                                toggleService(subCategory.id, service.id)
                              }
                              color={service.isSelected ? 'primary' : 'default'}
                              variant={
                                service.isSelected ? 'filled' : 'outlined'
                              }
                              size="small"
                              sx={{
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                '&:hover': {
                                  backgroundColor: service.isSelected
                                    ? theme.palette.primary.dark
                                    : theme.palette.action.hover,
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>
            )}

            {selectedCategoryServices.length > 0 && (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Category Services
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedCategoryServices.map((service) => (
                    <Chip
                      key={service.id}
                      label={service.name}
                      onClick={() => toggleCategoryService(service.id)}
                      color={service.isSelected ? 'primary' : 'default'}
                      variant={service.isSelected ? 'filled' : 'outlined'}
                      sx={{
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: service.isSelected
                            ? theme.palette.primary.dark
                            : theme.palette.action.hover,
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
