import React from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import {
  selectCategories,
  selectCategoryError,
  selectCategoryStatus,
  setCurrentCategory,
} from '../../../../store/slices/categorySlice';
import { Category } from '../../../../types/states/CategoryState';
import { CategoryCard } from './CategoryCard';

interface CategoryGridProps {
  onEdit: (category: Category) => void;
  onDelete: (category: Category) => void;
}

export const CategoryGrid: React.FC<CategoryGridProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector(selectCategories);
  const status = useSelector(selectCategoryStatus);
  const error = useSelector(selectCategoryError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (categories.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No categories found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={4} padding={2}>
      {categories.map((category, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={category.id}>
          <CategoryCard
            category={category}
            index={index}
            onEdit={() => {
              dispatch(setCurrentCategory(category));
              onEdit(category);
            }}
            onDelete={() => onDelete(category)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
