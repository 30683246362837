import {
  Box,
  Button,
  Card,
  CardActions,
  Chip,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Listing, ListingStatus } from '../../../../types/states/ListingState';
import { Category } from '../../../../types/states/CategoryState';
import { SubCategory } from '../../../../types/states/SubCategoryState';
import { SubCategoryService } from '../../../../types/states/SubCategoryServiceState';
import { ImageView } from '../../../../components/inputs/ImageView.tsx';

const statusDisplayMap: Record<number, { text: string; gradient: string }> = {
  [ListingStatus.ACTIVE]: {
    text: 'Active',
    gradient: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
  },
  [ListingStatus.PENDING_APPROVAL]: {
    text: 'Awaiting Approval',
    gradient: 'linear-gradient(45deg, #D4AF37 30%, #FFD700 90%)',
  },
  [ListingStatus.DELETED]: {
    text: 'Deleted',
    gradient: 'linear-gradient(45deg, #EF5350 30%, #E57373 90%)',
  },
};

export interface ListingApprovalsCardProps {
  listing: Listing;
  categories: Category[];
  subCategories: SubCategory[];
  subCategoryServices: SubCategoryService[];
  index: number;
  variant?: 'default' | 'minimal' | 'elegant' | 'modern';
}

const ListingApprovalsCard = (props: ListingApprovalsCardProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { variant = 'default' } = props;
  const [imageError, setImageError] = useState(false);

  // Base card styles
  const baseCardStyle = {
    position: 'relative',
    height: '100%',
    width: '100%',
    maxWidth: '320px',
    margin: '0 auto',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    animation: `fadeSlideIn 0.6s ease-out ${props.index * 0.1}s both`,
    '@keyframes fadeSlideIn': {
      from: {
        opacity: 0,
        transform: 'translateY(20px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
  };

  // Style variants
  const cardStyles = {
    default: {
      ...baseCardStyle,
      minHeight: '280px',
      bgcolor: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(8px)',
      borderRadius: '16px',
      border: '2px solid rgba(212, 175, 55, 0.3)',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        transform: 'translateY(-8px)',
        borderColor: '#D4AF37',
        boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
      },
    },
    minimal: {
      ...baseCardStyle,
      minHeight: '240px',
      bgcolor: 'transparent',
      borderRadius: '8px',
      border: '1px solid rgba(212, 175, 55, 0.15)',
      transition: 'all 0.2s ease',
      '&:hover': {
        bgcolor: 'rgba(212, 175, 55, 0.05)',
      },
    },
    elegant: {
      ...baseCardStyle,
      minHeight: '300px',
      background:
        'linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.95) 100%)',
      borderRadius: '20px',
      padding: '2px',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '20px',
        padding: '2px',
        background:
          'linear-gradient(135deg, rgba(212, 175, 55, 0.5), rgba(212, 175, 55, 0.1))',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
      },
      '&:hover': {
        transform: 'translateY(-4px)',
      },
    },
    modern: {
      ...baseCardStyle,
      minHeight: '260px',
      bgcolor: 'rgba(18, 18, 18, 0.95)',
      borderRadius: '12px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '120px',
        background:
          'linear-gradient(45deg, rgba(212, 175, 55, 0.1), rgba(255, 215, 0, 0.05))',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
      },
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
  };

  return (
    <Card elevation={0} sx={cardStyles[variant]}>
      <Box
        onClick={() => navigate(`/listing/${props.listing.id}`)}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ImageView
          fileId={props.listing.cardImageUri}
          alt={props.listing.name}
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            borderRadius: '8px',
            marginBottom: '16px',
          }}
          sx={{
            transition: 'all 0.3s ease',
            filter: 'brightness(0.9)',
            '&:hover': {
              filter: 'brightness(1.1)',
            },
          }}
        />
        {/*<FileImage*/}
        {/*  fileId={props.listing.cardImageUri}*/}
        {/*  alt={props.listing.name}*/}
        {/*  style={{*/}
        {/*    width: '100%',*/}
        {/*    height: '200px',*/}
        {/*    objectFit: 'cover',*/}
        {/*    borderRadius: '8px',*/}
        {/*    marginBottom: '16px',*/}
        {/*  }}*/}
        {/*  sx={{*/}
        {/*    transition: 'all 0.3s ease',*/}
        {/*    filter: 'brightness(0.9)',*/}
        {/*    '&:hover': {*/}
        {/*      filter: 'brightness(1.1)',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*/>*/}

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontWeight: 500,
              mb: 1,
              transition: 'all 0.3s ease',
            }}
          >
            {props.listing.name}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <Chip
              variant="filled"
              label={statusDisplayMap[props.listing.status]?.text || 'Unknown'}
              size="small"
              sx={{
                background:
                  statusDisplayMap[props.listing.status]?.gradient ||
                  'linear-gradient(45deg, #9E9E9E 30%, #BDBDBD 90%)',
                color: '#fff',
                fontWeight: 500,
                textTransform: 'none',
                '& .MuiChip-label': {
                  px: 2,
                },
                opacity: 0.9,
                '&:hover': {
                  opacity: 1,
                },
                transition: 'all 0.2s ease-in-out',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
              }}
            />
          </Box>

          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              lineHeight: 1.5,
            }}
          >
            {props.listing.shortDescription}
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {props.categories.find(
              (cat) => cat.id === props.listing.categoryId
            ) && (
              <Chip
                label={
                  props.categories.find(
                    (cat) => cat.id === props.listing.categoryId
                  )?.name
                }
                size="small"
                sx={{
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                  color: theme.palette.primary.contrastText,
                  border: '1px solid rgba(212, 175, 55, 0.3)',
                }}
              />
            )}
            {props.subCategories.find(
              (subCat) => subCat.id === props.listing.subCategoryId
            ) && (
              <Chip
                label={
                  props.subCategories.find(
                    (subCat) => subCat.id === props.listing.subCategoryId
                  )?.name
                }
                size="small"
                sx={{
                  backgroundColor: 'rgba(212, 175, 55, 0.05)',
                  color: theme.palette.primary.contrastText,
                  border: '1px solid rgba(212, 175, 55, 0.2)',
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <CardActions
        sx={{
          justifyContent: 'flex-end',
          padding: '16px',
          borderTop: '1px solid rgba(212, 175, 55, 0.1)',
          bgcolor: 'rgba(0, 0, 0, 0.2)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          onClick={() => {
            navigate(`/listing/${props.listing.id}?mode=review`);
          }}
          startIcon={<VisibilityIcon />}
          sx={{
            color: 'rgba(212, 175, 55, 0.7)',
            '&:hover': {
              color: '#D4AF37',
              bgcolor: 'rgba(212, 175, 55, 0.1)',
            },
          }}
          size="small"
        >
          Review
        </Button>
      </CardActions>
    </Card>
  );
};

export default ListingApprovalsCard;
