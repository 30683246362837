import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store.ts';
import {
  fetchCompaniesByUser,
  selectCompanies,
} from '../../../store/slices/companySlice.ts';
import { CompanyCard } from './CompanyCard.tsx';
import { useEffect } from 'react';

interface CompanyGridProps {
  styleVariant?: 'default' | 'minimal' | 'elegant' | 'modern';
}

export const CompanyGrid = ({ styleVariant = 'default' }: CompanyGridProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const companies = useSelector(selectCompanies);
  useEffect(() => {
    dispatch(fetchCompaniesByUser());
  }, []);
  return (
    <Grid container spacing={3}>
      {companies.map((company, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
          <CompanyCard company={company} index={index} variant={styleVariant} />
        </Grid>
      ))}
    </Grid>
  );
};
