import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  useTheme,
} from '@mui/material';
import { Listing } from '../../types/states/ListingState';
import { Category } from '../../types/states/CategoryState';
import { SubCategory } from '../../types/states/SubCategoryState.ts';
import { SubCategoryService } from '../../types/states/SubCategoryServiceState.ts';
import { ImageView } from '../../components/inputs/ImageView.tsx';

interface ListingCardProps {
  listing: Listing;
  categories: Category[];
  subCategories: SubCategory[];
  subCategoryServices: SubCategoryService[];
}

export const ListingCard = ({
  listing,
  categories,
  subCategories,
  subCategoryServices,
}: ListingCardProps) => {
  const theme = useTheme();

  const handleCardClick = () => {
    window.location.href = `/listing/${listing.id}`;
  };
  return (
    <Card
      onClick={handleCardClick}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.85)',
        backdropFilter: 'blur(8px)',
        borderRadius: 2,
        border: '2px solid',
        borderColor: 'rgba(212, 175, 55, 0.3)',
        transition: 'all 0.3s ease-in-out',
        animation: 'fadeIn 0.8s ease-out',
        '@keyframes fadeIn': {
          from: { opacity: 0, transform: 'translateY(20px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        '&:hover': {
          borderColor: '#D4AF37',
          transform: 'translateY(-5px)',
          boxShadow: '0 4px 20px rgba(212, 175, 55, 0.2)',
        },
      }}
    >
      <ImageView
        fileId={listing.cardImageUri}
        alt={listing.name}
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
        }}
        sx={{
          transition: 'all 0.3s ease',
          filter: 'brightness(0.9)',
          '&:hover': {
            filter: 'brightness(1.1)',
          },
        }}
      />
      {/*<FileImage*/}
      {/*  fileId={listing.cardImageUri}*/}
      {/*  alt={listing.name}*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    height: '200px',*/}
      {/*    objectFit: 'cover',*/}
      {/*  }}*/}
      {/*  sx={{*/}
      {/*    transition: 'all 0.3s ease',*/}
      {/*    filter: 'brightness(0.9)',*/}
      {/*    '&:hover': {*/}
      {/*      filter: 'brightness(1.1)',*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}
      <CardContent
        sx={{
          flexGrow: 1,
          background:
            'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92))',
          pb: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{
            color: '#D4AF37',
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            lineHeight: 1.2,
            height: '2.4em',
            m: 0,
          }}
        >
          {listing.name}
        </Typography>
        <Typography
          sx={{
            color: 'rgba(255, 255, 255, 0.9)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            lineHeight: 1.5,
            height: '4.5em',
          }}
        >
          {listing.shortDescription}
        </Typography>

        {/* Category and SubCategory Tags */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            minHeight: '32px',
            maxHeight: '68px',
            overflow: 'hidden',
          }}
        >
          {categories.find((cat) => cat.id === listing.categoryId) && (
            <Chip
              label={
                categories.find((cat) => cat.id === listing.categoryId)?.name
              }
              sx={{
                backgroundColor: 'rgba(212, 175, 55, 0.1)',
                color: theme.palette.primary.contrastText,
                border: '1px solid rgba(212, 175, 55, 0.3)',
                height: '28px',
              }}
            />
          )}
          {subCategories.find(
            (subCat) => subCat.id === listing.subCategoryId
          ) && (
            <Chip
              label={
                subCategories.find(
                  (subCat) => subCat.id === listing.subCategoryId
                )?.name
              }
              sx={{
                backgroundColor: 'rgba(212, 175, 55, 0.05)',
                color: theme.palette.primary.contrastText,
                border: '1px solid rgba(212, 175, 55, 0.2)',
                height: '28px',
              }}
            />
          )}
        </Box>

        {/* Services Tags */}
        {listing.subCategoryServiceIds &&
          listing.subCategoryServiceIds.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                minHeight: '24px',
                maxHeight: '52px',
                overflow: 'hidden',
              }}
            >
              {listing.subCategoryServiceIds.map((serviceId, index) => {
                const service = subCategoryServices.find(
                  (s) => s.id === serviceId
                );
                return service ? (
                  <Chip
                    key={index}
                    label={service.name}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(212, 175, 55, 0.03)',
                      color: theme.palette.primary.contrastText,
                      border: '1px solid rgba(212, 175, 55, 0.15)',
                      height: '24px',
                    }}
                  />
                ) : null;
              })}
            </Box>
          )}

        {/* Price */}
        <Box
          sx={{
            mt: 'auto',
            pt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: '1px solid rgba(212, 175, 55, 0.1)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#D4AF37',
              fontWeight: 600,
              fontSize: '1.1rem',
            }}
          >
            {listing.price} Kr
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
