import React from 'react';
import { Dialog } from '@mui/material';
import FilterContent from './FilterContent';

interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  selectedCategoryId: string | null;
}

const FilterDialog: React.FC<FilterDialogProps> = ({
  open,
  onClose,
  selectedCategoryId,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'rgba(0, 0, 0, 0.95)',
          backgroundImage: 'none',
        },
      }}
    >
      <FilterContent
        selectedCategoryId={selectedCategoryId}
        onClose={onClose}
        variant="dialog"
      />
    </Dialog>
  );
};

export default FilterDialog;
