import axiosInstance from '../utilis/axios.ts';

interface FileReference {
  originalFileName: string;
  referenceCreated: string;
  referenceContext: string;
}

interface FileEntry {
  blobFileId: string;
  fileName: string;
  contentType: string;
  contentLength: number;
  shA256Hash: string;
  references: FileReference[];
}

interface FileManifest {
  containerName: string;
  createdAt: string;
  lastModified: string;
  files: FileEntry[];
}

interface CachedFile {
  url: string;
  hash: string;
  lastChecked: number;
}

interface BlobFile {
  id: string;
}

class FileService {
  private static instance: FileService;
  private fileCache: Map<string, CachedFile>;
  private manifest: FileManifest | null = null;
  private manifestLastChecked: number = 0;
  private manifestCheckInterval = 30000; // 30 seconds
  private readonly apiUrl = import.meta.env.VITE_API_URL || '';
  private readonly CACHE_KEY = 'fileServiceCache';
  private readonly CACHE_NAME = 'file-cache-v1';

  private constructor() {
    // Load cache from localStorage
    this.fileCache = this.loadCache();

    // Start periodic manifest check
    this.startManifestCheck();
  }

  public static getInstance(): FileService {
    if (!FileService.instance) {
      FileService.instance = new FileService();
    }
    return FileService.instance;
  }

  public async forceUpdateManifest(): Promise<void> {
    try {
      await this.updateManifestIfNeeded(true);
      console.log('Manifest forcefully updated');
    } catch (error) {
      console.error('Error forcing manifest update:', error);
      throw error;
    }
  }

  public async getImageUrl(
    fileId: string,
    forceUpdate: boolean = false
  ): Promise<string> {
    try {
      // Always get fresh manifest when force update is true
      const manifest = await this.updateManifestIfNeeded(forceUpdate);
      const fileEntry = manifest.files.find((f) => f.blobFileId === fileId);
      console.log('fileEntry:', fileEntry);

      if (!fileEntry) {
        throw new Error(`File ${fileId} not found in manifest`);
      }

      // Check if we need to invalidate cache
      const cached = this.fileCache.get(fileId);
      console.log('cached:', cached);
      if (cached && cached.hash != fileEntry.shA256Hash) {
        console.log(`Cache invalidated for ${fileId} due to hash mismatch`);
        this.fileCache.delete(fileId);
        await this.deleteCachedFile(fileId);
        this.saveCache();
      }

      // If force update or no valid cache, fetch new file
      if (forceUpdate || !cached || cached.hash !== fileEntry.shA256Hash) {
        console.log(`Fetching new file for ${fileId}`);
        const response = await axiosInstance.get(
          `${this.apiUrl}/files/${fileId}`,
          {
            responseType: 'blob',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
            },
          }
        );

        if (!response.data) {
          throw new Error(`Failed to fetch file ${fileId}`);
        }

        // Create blob from response data
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        // Calculate hash
        const hash = await this.calculateSHA256(blob);
        console.log('Calculated hash:', hash);
        console.log('Manifest hash:', fileEntry.shA256Hash);

        // Cache the new file with calculated hash
        await this.cacheFile(fileId, blob, hash);

        // Create URL from the blob
        return URL.createObjectURL(blob);
      }

      // Use cached file if hash matches
      const cachedFile = await this.getCachedFile(fileId);
      if (cachedFile) {
        const blob = await cachedFile.blob();
        return URL.createObjectURL(blob);
      }

      // Fallback to fetching if cache is invalid
      console.log(`Cache miss for ${fileId}, fetching fresh`);
      const response = await axiosInstance.get(
        `${this.apiUrl}/files/${fileId}`,
        {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        }
      );

      if (!response.data) {
        throw new Error(`Failed to fetch file ${fileId}`);
      }

      // Create blob from response data
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      // Calculate hash
      const hash = await this.calculateSHA256(blob);
      console.log('Calculated hash:', hash);
      console.log('Manifest hash:', fileEntry.shA256Hash);

      // Cache with calculated hash
      await this.cacheFile(fileId, blob, hash);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error in getImageUrl:', error);
      throw error;
    }
  }

  public async checkImageUpdate(fileId: string): Promise<boolean> {
    try {
      const manifest = await this.updateManifestIfNeeded(true);
      const fileEntry = manifest.files.find((f) => f.blobFileId === fileId);

      if (!fileEntry) return false;

      // Check both memory cache and file cache
      const cached = this.fileCache.get(fileId);
      if (!cached || cached.hash !== fileEntry.shA256Hash) {
        return true;
      }

      const cachedFile = await this.getCachedFile(fileId);
      return !cachedFile;
    } catch (err) {
      console.error('Error checking image update:', err);
      return false;
    }
  }

  public async uploadFile(
    file: Blob | File,
    fileName: string = 'file.jpg'
  ): Promise<string> {
    const formData = new FormData();
    formData.append('data', file, fileName);

    try {
      const response = await axiosInstance.post<{ id: string }>(
        `${this.apiUrl}/files`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      // Force update manifest to get the new file
      await this.forceUpdateManifest();

      return response.data.id;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  public async updateFile(
    fileId: string,
    file: Blob | File,
    fileName: string = 'file.jpg'
  ): Promise<string> {
    const formData = new FormData();
    formData.append('data', file, fileName);

    try {
      const response = await axiosInstance.put<BlobFile>(
        `${this.apiUrl}/files/${fileId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      // Force update manifest to get the updated file
      await this.forceUpdateManifest();

      return response.data.id;
    } catch (error) {
      console.error('Error updating file:', error);
      throw error;
    }
  }

  public async deleteFile(fileId: string): Promise<void> {
    try {
      await axiosInstance.delete(`${this.apiUrl}/files/${fileId}`);

      // Force update manifest to reflect the deletion
      await this.forceUpdateManifest();

      // Clear file from cache if it exists
      this.fileCache.delete(fileId);
      await this.deleteCachedFile(fileId);
      this.saveCache();
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  }

  public async getFileHash(fileId: string): Promise<string | null> {
    const manifest = await this.updateManifestIfNeeded();
    const fileEntry = manifest.files.find((f) => f.blobFileId === fileId);
    return fileEntry?.shA256Hash || null;
  }

  private loadCache(): Map<string, CachedFile> {
    try {
      const cached = localStorage.getItem(this.CACHE_KEY);
      if (cached) {
        return new Map(JSON.parse(cached));
      }
    } catch (error) {
      console.error('Error loading cache:', error);
    }
    return new Map();
  }

  private saveCache(): void {
    try {
      localStorage.setItem(
        this.CACHE_KEY,
        JSON.stringify(Array.from(this.fileCache.entries()))
      );
    } catch (error) {
      console.error('Error saving cache:', error);
    }
  }

  private async getCacheStorage(): Promise<Cache> {
    return await caches.open(this.CACHE_NAME);
  }

  private async cacheFile(
    fileId: string,
    blob: Blob,
    hash: string
  ): Promise<void> {
    console.log(`Caching file ${fileId} with hash ${hash}`);
    try {
      const cache = await this.getCacheStorage();
      // First delete any existing cache
      await cache.delete(fileId);
      // Create a new Response from the blob for caching
      const response = new Response(blob.slice(0));
      // Then add new cache
      await cache.put(fileId, response);

      // Update memory cache with hash
      this.fileCache.set(fileId, {
        url: URL.createObjectURL(blob),
        hash: hash,
        lastChecked: Date.now(),
      });
      this.saveCache();
    } catch (error) {
      console.error('Error caching file:', error);
      throw error;
    }
  }

  private async getCachedFile(fileId: string): Promise<Response | undefined> {
    const cache = await this.getCacheStorage();
    return await cache.match(fileId);
  }

  private async deleteCachedFile(fileId: string): Promise<void> {
    const cache = await this.getCacheStorage();
    await cache.delete(fileId);
  }

  private startManifestCheck() {
    // Initial check
    this.checkManifestUpdates();

    // Set up periodic check
    this.manifestCheckInterval = window.setInterval(() => {
      this.checkManifestUpdates();
    }, this.manifestCheckInterval);
  }

  private async checkManifestUpdates() {
    try {
      const newManifest = await this.fetchManifest();

      // If we don't have a previous manifest, just store it
      if (!this.manifest) {
        this.manifest = newManifest;
        this.manifestLastChecked = Date.now();
        return;
      }

      // Check for changes in files
      let cacheChanged = false;
      for (const newFile of newManifest.files) {
        const oldFile = this.manifest?.files.find(
          (f) => f.blobFileId === newFile.blobFileId
        );
        if (oldFile && oldFile.shA256Hash !== newFile.shA256Hash) {
          // Hash changed, invalidate cache
          const cached = this.fileCache.get(newFile.blobFileId);
          if (cached) {
            this.fileCache.delete(newFile.blobFileId);
            await this.deleteCachedFile(newFile.blobFileId);
            cacheChanged = true;
            console.log(
              `Cache invalidated for file ${newFile.blobFileId} due to hash change`
            );
          }
        }
      }

      if (cacheChanged) {
        this.saveCache();
      }

      this.manifest = newManifest;
      this.manifestLastChecked = Date.now();
    } catch (error) {
      console.error('Error checking manifest updates:', error);
    }
  }

  private async updateManifestIfNeeded(
    force: boolean = false
  ): Promise<FileManifest> {
    const now = Date.now();
    if (
      force ||
      !this.manifest ||
      now - this.manifestLastChecked > this.manifestCheckInterval
    ) {
      try {
        const response = await axiosInstance.get<FileManifest>(
          `${this.apiUrl}/files/manifest`,
          {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
            },
          }
        );

        // Compare with current manifest to detect changes
        if (this.manifest) {
          const currentFiles = this.manifest.files;
          const newFiles = response.data.files;

          // Check for changed files
          for (const newFile of newFiles) {
            const currentFile = currentFiles.find(
              (f) => f.blobFileId === newFile.blobFileId
            );
            if (currentFile && currentFile.shA256Hash !== newFile.shA256Hash) {
              // Hash changed - invalidate cache
              console.log(
                `Hash changed for file ${newFile.blobFileId}, invalidating cache`
              );
              this.fileCache.delete(newFile.blobFileId);
              await this.deleteCachedFile(newFile.blobFileId);
            }
          }
        }

        this.manifest = response.data;
        this.manifestLastChecked = now;
        return this.manifest;
      } catch (error) {
        console.error('Error fetching manifest:', error);
        throw error;
      }
    }
    return this.manifest;
  }

  private async fetchManifest(): Promise<FileManifest> {
    try {
      const response = await axiosInstance.get<FileManifest>(
        `${this.apiUrl}/files/manifest`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching manifest:', error);
      throw error;
    }
  }

  private async calculateSHA256(blob: Blob): Promise<string> {
    // Convert blob to array buffer
    const arrayBuffer = await blob.arrayBuffer();
    // Calculate hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    // Convert to base64
    const hashArray = new Uint8Array(hashBuffer);
    const base64 = btoa(String.fromCharCode(...hashArray));
    return base64;
  }
}

// Export singleton instance
export const fileService = FileService.getInstance();
