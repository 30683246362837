import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Typography } from '@mui/material';
import { Category } from '../../../../types/states/CategoryState.ts';
import CategoryDialog from './CategoryDialog.tsx';
import { ImageView } from '../../../../components/inputs/ImageView.tsx';

interface CategoryCardProps {
  category: Category;
  index: number;
  onSelectionChange?: (
    categoryId: string,
    selections: {
      subCategories: SubCategory[];
      categoryServices: CategoryService[];
    }
  ) => void;
  savedSelections?: {
    subCategories: SubCategory[];
    categoryServices: CategoryService[];
  };
}

interface SubCategory {
  id: string;
  name: string;
  categoryId: string;
  services: Service[];
  isSelected?: boolean;
}

interface Service {
  id: string;
  name: string;
  isSelected?: boolean;
}

interface CategoryService {
  id: string;
  name: string;
  categoryId: string;
  isSelected?: boolean;
}

export const EventCategoryCard: React.FC<CategoryCardProps> = ({
  category,
  index,
  onSelectionChange,
  savedSelections,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<{
    subCategories: SubCategory[];
    categoryServices: CategoryService[];
  }>({
    subCategories: [],
    categoryServices: [],
  });

  // Update selectedItems when savedSelections changes
  useEffect(() => {
    if (savedSelections) {
      setSelectedItems({
        subCategories: savedSelections.subCategories || [],
        categoryServices: savedSelections.categoryServices || [],
      });
    } else {
      setSelectedItems({
        subCategories: [],
        categoryServices: [],
      });
    }
  }, [savedSelections]);

  const handleSave = (
    categoryId: string,
    items: {
      subCategories: SubCategory[];
      categoryServices: CategoryService[];
    }
  ) => {
    const safeItems = {
      subCategories: items.subCategories || [],
      categoryServices: items.categoryServices || [],
    };
    console.log('Saving selections:', safeItems);
    setSelectedItems(safeItems);
    onSelectionChange?.(categoryId, safeItems);
  };

  const getSelectedServices = () => {
    const services: string[] = [];

    // Add selected subcategory services
    selectedItems.subCategories?.forEach((subCategory) => {
      if (subCategory?.isSelected) {
        services.push(subCategory.name);
        subCategory.services?.forEach((service) => {
          if (service?.isSelected) {
            services.push(service.name);
          }
        });
      }
    });

    // Add selected category services
    selectedItems.categoryServices?.forEach((service) => {
      if (service?.isSelected) {
        services.push(service.name);
      }
    });

    return services;
  };

  return (
    <>
      <Card
        onClick={() => setIsDialogOpen(true)}
        elevation={0}
        sx={{
          position: 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          height: '100%',
          width: '100%',
          minHeight: '240px',
          maxWidth: '320px',
          margin: '0 auto',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          backdropFilter: 'blur(8px)',
          border: '2px solid',
          borderColor: 'rgba(212, 175, 55, 0.3)',
          animation: `fadeSlideIn 0.6s ease-out ${index * 0.1}s both`,
          '@keyframes fadeSlideIn': {
            from: {
              opacity: 0,
              transform: 'translateY(20px)',
            },
            to: {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },
          '&:hover': {
            transform: 'translateY(-8px)',
            borderColor: '#D4AF37',
            boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
            '& .category-image': {
              transform: 'scale(1.1)',
              borderColor: '#D4AF37',
            },
            '& .category-title': {
              background:
                'linear-gradient(to right, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundSize: '200% auto',
              animation: 'shine 3s linear infinite',
            },
            '&::after': {
              opacity: 1,
            },
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(135deg, rgba(212, 175, 55, 0.1) 0%, rgba(212, 175, 55, 0) 100%)',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            pointerEvents: 'none',
          },
          '@keyframes shine': {
            to: {
              backgroundPosition: '200% center',
            },
          },
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            className="category-image"
            sx={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              mb: 2.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              border: '2px solid rgba(212, 175, 55, 0.3)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              overflow: 'hidden',
            }}
          >
            <ImageView
              fileId={category.imageUri}
              alt={category.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            {/*{category?.imageUri ? (*/}
            {/*  <FileImage*/}
            {/*    fileId={category.imageUri}*/}
            {/*    alt={category.name}*/}
            {/*    style={{*/}
            {/*      width: '100%',*/}
            {/*      height: '100%',*/}
            {/*      objectFit: 'cover',*/}
            {/*    }}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <BrokenImageIcon*/}
            {/*    sx={{*/}
            {/*      fontSize: 40,*/}
            {/*      color: '#D4AF37',*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
          </Box>

          <Typography
            className="category-title"
            variant="h6"
            component="h2"
            align="center"
            sx={{
              color: 'rgba(212, 175, 55, 0.9)',
              fontWeight: 600,
              fontSize: '1rem',
              transition: 'all 0.3s ease-in-out',
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              mb: 2,
            }}
          >
            {category?.name}
          </Typography>

          {getSelectedServices().length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                justifyContent: 'center',
                mb: 2,
                px: 2,
                maxHeight: '80px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'rgba(0, 0, 0, 0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(212, 175, 55, 0.5)',
                  borderRadius: '4px',
                },
              }}
            >
              {getSelectedServices().map((service, idx) => (
                <Chip
                  key={idx}
                  label={service}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(212, 175, 55, 0.2)',
                    color: '#FFFFFF',
                    border: '1px solid rgba(212, 175, 55, 0.3)',
                    '&:hover': {
                      backgroundColor: 'rgba(212, 175, 55, 0.3)',
                    },
                  }}
                />
              ))}
            </Box>
          )}

          <Typography
            variant="body2"
            align="center"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              transition: 'all 0.3s ease-in-out',
              opacity: 0.8,
              maxWidth: '85%',
              mx: 'auto',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            {category?.shortDescription}
          </Typography>
        </Box>
      </Card>

      <CategoryDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        category={category}
        onSave={handleSave}
        initialSelections={selectedItems}
      />
    </>
  );
};
