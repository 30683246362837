import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Currency, Product, ProductType } from '../../../types/product';
import {
  selectProductError,
  selectProducts,
  selectProductStatus,
} from '../../../store/slices/productSlice';

interface ProductGridProps {
  onEdit: (product: Product) => void;
  onDelete: (product: Product) => void;
}

export const ProductGrid: React.FC<ProductGridProps> = ({
  onEdit,
  onDelete,
}) => {
  const products = useSelector(selectProducts);
  const status = useSelector(selectProductStatus);
  const error = useSelector(selectProductError);

  if (status === 'loading' && !products.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} key={product.id}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography variant="h6" gutterBottom>
                  {product.name}
                </Typography>
                <Box>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      onClick={() => onEdit(product)}
                      disabled={status === 'loading'}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={() => onDelete(product)}
                      disabled={status === 'loading'}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Typography variant="body2" color="textSecondary" paragraph>
                {product.description}
              </Typography>
              <Typography variant="h6" color="primary">
                {Currency[product.currency]} {product.price.toFixed(2)}
              </Typography>
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  Listing Tokens: {product.listingsTokens}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Event Click Tokens: {product.eventClickTokens}
                </Typography>
              </Box>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  color={product.isActive ? 'success.main' : 'error.main'}
                >
                  {product.isActive ? 'Active' : 'Inactive'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.type === ProductType.OneTime
                    ? 'One Time'
                    : 'Subscription'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
