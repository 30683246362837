import React from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { SubCategoryServiceCard } from './SubCategoryServiceCard.tsx';
import {
  selectSubCategoryServiceError,
  selectSubCategoryServices,
  selectSubCategoryServiceStatus,
  setCurrentSubCategoryService,
} from '../../../../store/slices/subCategoryServiceSlice.ts';
import { SubCategoryService } from '../../../../types/states/SubCategoryServiceState.ts';

interface SubCategoryServiceProps {
  onEdit: (subSubCategory: SubCategoryService) => void;
  onDelete: (subSubCategory: SubCategoryService) => void;
}

export const SubCategoryServiceGrid: React.FC<SubCategoryServiceProps> = ({
  onEdit,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const subCategoryServices = useSelector(selectSubCategoryServices);
  const status = useSelector(selectSubCategoryServiceStatus);
  const error = useSelector(selectSubCategoryServiceError);

  if (status === 'pending') {
    return (
      <Box display="flex" justifyContent="center" m={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === 'rejected' && error) {
    return (
      <Box m={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (subCategoryServices.length === 0) {
    return (
      <Box textAlign="center" m={3}>
        <Typography variant="h6" color="textSecondary">
          No SubCategory Services found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={4} padding={2}>
      {subCategoryServices.map((subCategoryService, index) => (
        <Grid
          size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
          key={subCategoryService.id}
        >
          <SubCategoryServiceCard
            subCategoryService={subCategoryService}
            index={index}
            onEdit={() => {
              dispatch(setCurrentSubCategoryService(subCategoryService));
              onEdit(subCategoryService);
            }}
            onDelete={() => onDelete(subCategoryService)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
