import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          gap: 3,
        }}
      >
        <Typography variant="h1" sx={{ fontSize: '6rem', color: '#D4AF37' }}>
          500
        </Typography>
        <Typography variant="h4" gutterBottom>
          Internal Server Error
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Something went wrong. Please try again later.
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          sx={{
            borderColor: 'rgba(212, 175, 55, 0.3)',
            color: '#D4AF37',
            '&:hover': {
              borderColor: '#D4AF37',
              backgroundColor: 'rgba(212, 175, 55, 0.1)',
            },
          }}
        >
          Go Home
        </Button>
      </Box>
    </Container>
  );
};

export default Error;
