import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { Category } from '../../types/states/CategoryState';
import background from '/public/images/banner.jpg';
import { apiUrl } from '../../main';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface CategoryHeaderProps {
  category: Category;
  showBackButton?: boolean;
  actions?: React.ReactNode;
}

const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  category,
  showBackButton = false,
  actions,
}) => {
  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: 'rgba(212, 175, 55, 0.05)',
    border: '2px solid',
    borderColor: 'rgba(212, 175, 55, 0.3)',
    color: '#D4AF37',
    padding: '10px 24px',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '4px',
    letterSpacing: '0.5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#D4AF37',
      borderColor: '#D4AF37',
      color: '#000',
      boxShadow: '0 4px 8px rgba(212, 175, 55, 0.3)',
      transform: 'translateY(-1px)',
    },
    '&:active': {
      transform: 'translateY(1px)',
      boxShadow: '0 2px 4px rgba(212, 175, 55, 0.2)',
    },
  };

  return (
    <Box
      component="header"
      sx={{
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 40% -20%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          zIndex: 2,
          py: { xs: 6, md: 8 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Box
            className="category-image"
            sx={{
              width: '120px',
              height: '120px',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '3px solid',
              borderColor: 'rgba(212, 175, 55, 0.3)',
              mb: 3,
              transition: 'all 0.3s ease',
            }}
          >
            <img
              src={`${apiUrl}/files/${category.imageUri}`}
              alt={category.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>

          <Typography
            variant="h3"
            component="h1"
            className="category-title"
            sx={{
              color: '#D4AF37',
              textAlign: 'center',
              fontWeight: 700,
              mb: 2,
              textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            {category.name}
          </Typography>

          {category.description && (
            <Typography
              variant="h6"
              sx={{
                color: 'rgba(255, 255, 255, 0.8)',
                textAlign: 'center',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
              }}
            >
              {category.description}
            </Typography>
          )}

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {showBackButton && (
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={buttonStyle}
              >
                Back
              </Button>
            )}
            {actions}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CategoryHeader;
