import React from 'react';
import { Box, Typography } from '@mui/material';

interface SectionHeaderProps {
  title: string;
  icon: React.ReactElement | undefined;
  actions?: React.ReactNode;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  icon,
  actions,
}) => {
  return (
    <Box
      mt={5}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3,
        pb: 2,
        borderBottom: '2px solid',
        borderColor: 'primary.main',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover .section-icon': {
            transform: 'scale(1.1) rotate(10deg)',
          },
        }}
      >
        <Box
          className="section-icon"
          sx={{
            transition: 'transform 0.3s ease-in-out',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            color: 'primary.main',
            fontSize: '1.25rem',
          }}
        >
          {title}
        </Typography>
      </Box>
      {actions && <Box sx={{ ml: 2 }}>{actions}</Box>}
    </Box>
  );
};
