export enum SubscriptionStatus {
  Active = 0,
  Cancelled = 1,
  PastDue = 2,
  Incomplete = 3,
  IncompleteExpired = 4,
  Unpaid = 5,
}

export interface PaymentRecord {
  id: string;
  companyId: string;
  productId: string;
  stripeCustomerId: string;
  stripeProductId: string;
  stripePriceId: string;
  amount: number;
  currency: string;
  createdAt: string;
  updatedAt: string;
}

export interface Payment extends PaymentRecord {
  stripePaymentIntentId: string;
  stripeChargeId: string;
  paymentStatus: string;
  tokenAmount: number;
  expiryDate?: string;
}

export interface Subscription extends PaymentRecord {
  stripeSubscriptionId: string;
  status: SubscriptionStatus;
  periodStart: string;
  periodEnd: string;
  cancelAt?: string;
  cancelledAt?: string;
  endedAt?: string;
  isActive: boolean;
  isCancelled: boolean;
}

export interface SubscriptionState {
  subscriptions: Subscription[];
  currentSubscription: Subscription | null;
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: string | null;
}
