import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { ImageCropInput } from '../../../../components/inputs/ImageCropInput';
import {
  createSubCategory,
  selectCurrentSubCategory,
  updateSubCategory,
} from '../../../../store/slices/subCategorySlice.ts';
import {
  CreateSubCategoryRequest,
  UpdateSubCategoryRequest,
} from '../../../../types/requests/SubCategoryRequests.ts';
import {
  fetchCategories,
  selectCategories,
} from '../../../../store/slices/categorySlice';

interface SubCategoryFormProps {
  onClose: () => void;
}

export const SubCategoryForm: React.FC<SubCategoryFormProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentSubCategory = useSelector(selectCurrentSubCategory);
  const categories = useSelector(selectCategories);
  const [imageId, setImageId] = useState<string | null>(
    currentSubCategory?.imageUri || null
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(
    currentSubCategory?.categoryId || ''
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryId: currentSubCategory?.categoryId || '',
      name: currentSubCategory?.name || '',
      shortDescription: currentSubCategory?.shortDescription || '',
      description: currentSubCategory?.description || '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const subCategoryData = {
        ...data,
        imageUri: imageId,
        categoryId: selectedCategoryId, // Ensure categoryId is explicitly set
      };

      if (currentSubCategory) {
        const updateRequest: UpdateSubCategoryRequest = {
          id: currentSubCategory.id,
          ...subCategoryData,
        };
        await dispatch(updateSubCategory(updateRequest)).unwrap();
      } else {
        const createRequest: CreateSubCategoryRequest = {
          ...subCategoryData,
        };
        await dispatch(createSubCategory(createRequest)).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Failed to save sub-category:', error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography variant="h6" gutterBottom>
        {currentSubCategory ? 'Edit Sub-Category' : 'Create New Sub-Category'}
      </Typography>

      <Stack spacing={3}>
        <FormControl fullWidth error={!!errors.categoryId}>
          <InputLabel id="category-select-label">Category *</InputLabel>
          <Controller
            name="categoryId"
            control={control}
            rules={{ required: 'Please select a category first' }}
            render={({ field }) => (
              <Select
                {...field}
                labelId="category-select-label"
                label="Category *"
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategoryId(e.target.value as string);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select a category</em>
                </MenuItem>
                {categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>
            {(errors.categoryId?.message as string) ||
              (!selectedCategoryId &&
                'Please select a category to enable other fields')}
          </FormHelperText>
        </FormControl>

        <TextField
          fullWidth
          label="Name"
          disabled={!selectedCategoryId}
          {...register('name', {
            required: 'Name is required',
            maxLength: {
              value: 100,
              message: 'Name cannot exceed 100 characters',
            },
          })}
          error={!!errors.name}
          helperText={errors.name?.message as string}
        />

        <TextField
          fullWidth
          label="Short Description"
          disabled={!selectedCategoryId}
          {...register('shortDescription')}
        />
        {/*{...register('shortDescription', {*/}
        {/*    required: 'Short description is required',*/}
        {/*    maxLength: {*/}
        {/*        value: 200,*/}
        {/*        message: 'Short description cannot exceed 200 characters'*/}
        {/*    }*/}
        {/*})}*/}
        {/*error={!!errors.shortDescription}*/}
        {/*helperText={errors.shortDescription?.message as string}*/}
        {selectedCategoryId && (
          <Box>
            <FormLabel sx={{ mb: 1, display: 'block' }}>
              Category Image
            </FormLabel>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <ImageCropInput
                aspectRatio={1 / 1}
                onImageChange={setImageId}
                initialImageId={currentSubCategory?.imageUri}
                sx={{ width: '100%', maxWidth: '300px' }}
              />
            </Box>
          </Box>
        )}

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          disabled={!selectedCategoryId}
          {...register('description')}
        />
        {/*{...register('description', {*/}
        {/*    required: 'Description is required'*/}
        {/*})}*/}
        {/*error={!!errors.description}*/}
        {/*helperText={errors.description?.message as string}*/}
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            {currentSubCategory ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
