import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Event, EventStatus } from '../../types/event';
import { EventState } from '../../types/states/EventState';
import { eventService } from '../../services/eventService';

// Async thunks
export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async (_, { rejectWithValue }) => {
    try {
      return await eventService.getEvents();
    } catch (error) {
      return rejectWithValue('Failed to fetch events');
    }
  }
);

export const fetchEventById = createAsyncThunk<Event, string, { rejectValue: string }>(
  'events/fetchById',
  async (id, { rejectWithValue }) => {
    try {
      console.log('Fetching event by id:', id);
      const response = await eventService.getEventById(id);
      console.log('Fetched event:', response);
      return response;
    } catch (error) {
      console.error('Error fetching event:', error);
      return rejectWithValue('Failed to fetch event');
    }
  }
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (event: Event, { rejectWithValue }) => {
    try {
      return await eventService.createEvent(event);
    } catch (error) {
      return rejectWithValue('Failed to create event');
    }
  }
);

export const updateEventAsync = createAsyncThunk(
  'events/updateEvent',
  async (event: Event, { rejectWithValue }) => {
    try {
      return await eventService.updateEvent(event);
    } catch (error) {
      return rejectWithValue('Failed to update event');
    }
  }
);

export const deleteEventAsync = createAsyncThunk(
  'events/deleteEvent',
  async (id: string, { rejectWithValue }) => {
    try {
      await eventService.deleteEvent(id);
      return id;
    } catch (error) {
      return rejectWithValue('Failed to delete event');
    }
  }
);

const initialState: EventState = {
  events: [],
  status: 'idle',
  error: null,
  currentEvent: null,
  tempEvent: null,
};

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // Current event management
    setCurrentEvent: (state, action: PayloadAction<Event | null>) => {
      state.currentEvent = action.payload;
    },
    clearCurrentEvent: (state) => {
      state.currentEvent = null;
    },
    
    // Temporary event management
    setTempEvent: (state, action: PayloadAction<Event | null>) => {
      state.tempEvent = action.payload;
    },
    clearTempEvent: (state) => {
      state.tempEvent = null;
    },
    
    // Event status management
    updateEventStatus: (state, action: PayloadAction<{ id: string; status: EventStatus }>) => {
      const event = state.events.find(event => event.id === action.payload.id);
      if (event) {
        event.status = action.payload.status;
        event.lastUpdated = new Date().toISOString();
      }
    },
    
    // State management
    clearEventState: (state) => {
      state.events = [];
      state.status = 'idle';
      state.error = null;
      state.currentEvent = null;
      state.tempEvent = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch events
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.events = action.payload;
        state.error = null;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload as string;
      })
      
    // Fetch event by id
      .addCase(fetchEventById.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.currentEvent = action.payload;
        // Update the event in the events array if it exists
        const index = state.events.findIndex(event => event.id === action.payload.id);
        if (index !== -1) {
          state.events[index] = action.payload;
        } else {
          state.events.push(action.payload);
        }
        state.error = null;
      })
      .addCase(fetchEventById.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload as string;
      })
      
    // Create event
      .addCase(createEvent.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.events.push(action.payload);
        state.currentEvent = action.payload;
        state.error = null;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload as string;
      })
      
    // Update event
      .addCase(updateEventAsync.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(updateEventAsync.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        const index = state.events.findIndex(event => event.id === action.payload.id);
        if (index !== -1) {
          state.events[index] = action.payload;
        }
        state.currentEvent = action.payload;
        state.error = null;
      })
      .addCase(updateEventAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload as string;
      })
      
    // Delete event
      .addCase(deleteEventAsync.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(deleteEventAsync.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.events = state.events.filter(event => event.id !== action.payload);
        if (state.currentEvent?.id === action.payload) {
          state.currentEvent = null;
        }
        state.error = null;
      })
      .addCase(deleteEventAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload as string;
      });
  },
});

// Export actions
export const {
  setCurrentEvent,
  clearCurrentEvent,
  setTempEvent,
  clearTempEvent,
  updateEventStatus,
  clearEventState,
} = eventSlice.actions;

// Selectors
export const selectEvents = (state: RootState) => state.events.events;
export const selectEventStatus = (state: RootState) => state.events.status;
export const selectEventError = (state: RootState) => state.events.error;
export const selectCurrentEvent = (state: RootState) => state.events.currentEvent;
export const selectTempEvent = (state: RootState) => state.events.tempEvent;
export const selectEventById = (state: RootState, id: string) => 
  state.events.events.find(event => event.id === id);

export default eventSlice.reducer;
