import React from 'react';
import { Box, Card, Tooltip } from '@mui/material';
import { Category } from '../../types/states/CategoryState';
import { apiUrl } from '../../main';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface MinimalCategoryCardProps {
  category: Category;
  index: number;
  isSelected?: boolean;
}

export const MinimalCategoryCard: React.FC<MinimalCategoryCardProps> = ({
  category,
  index,
  isSelected = false,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNavigate = () => {
    if (isSelected) {
      // If already selected, clear the filter
      searchParams.delete('categoryId');
    } else {
      // Otherwise, set the new category filter
      searchParams.set('categoryId', category.id);
    }
    setSearchParams(searchParams);
  };

  return (
    <Tooltip
      title={category.name}
      placement="bottom"
      enterDelay={200}
      sx={{
        '& .MuiTooltip-tooltip': {
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          color: '#D4AF37',
          fontSize: '0.875rem',
          border: '1px solid rgba(212, 175, 55, 0.3)',
          borderRadius: '4px',
        },
      }}
    >
      <Card
        onClick={handleNavigate}
        elevation={0}
        sx={{
          position: 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          height: '100%',
          width: '100%',
          minHeight: '90px',
          maxWidth: '90px',
          margin: '0 auto',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          backdropFilter: 'blur(8px)',
          border: '2px solid',
          borderColor: isSelected ? '#D4AF37' : 'rgba(212, 175, 55, 0.3)',
          animation: `fadeSlideIn 0.6s ease-out ${index * 0.1}s both`,
          cursor: 'pointer',
          '@keyframes fadeSlideIn': {
            from: {
              opacity: 0,
              transform: 'translateY(20px)',
            },
            to: {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },
          '&:hover': {
            transform: 'translateY(-4px)',
            borderColor: '#D4AF37',
            boxShadow: '0 8px 24px rgba(212, 175, 55, 0.2)',
            '& .category-image': {
              transform: 'scale(1.1)',
            },
          },
        }}
      >
        <Box
          sx={{
            p: 0.75,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box
            className="category-image"
            sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              opacity: isSelected ? 1 : 0.8,
            }}
          >
            <img
              src={`${apiUrl}/files/${category.imageUri}`}
              alt={category.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Box>
      </Card>
    </Tooltip>
  );
};

export default MinimalCategoryCard;
