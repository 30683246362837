import { FC, ReactNode } from 'react';
import { Box, Container } from '@mui/material';

interface StyledContainerProps {
  children?: ReactNode;
}

const StyledContainer: FC<StyledContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.95)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
          opacity: 0.7,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '2px',
          background:
            'linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, rgba(212, 175, 55, 0.3) 50%, rgba(212, 175, 55, 0) 100%)',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 6, md: 8 },
          position: 'relative',
          zIndex: 1,
          animation: 'fadeIn 0.8s ease-out',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(20px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default StyledContainer;
