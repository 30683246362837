import React from 'react';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { SectionHeader } from './SectionHeader';
import { DashboardCard } from './DashboardCard';
import { AdminCenteItem } from '../../pages/AdminCenter';

interface DashboardSectionProps {
  title: string;
  icon: React.ReactElement;
  items: AdminCenteItem[];
}

export const DashboardSection: React.FC<DashboardSectionProps> = ({
  title,
  icon,
  items,
}) => {
  return (
    <Box sx={{ mb: 6 }}>
      <SectionHeader title={title} icon={icon} />
      <Grid container spacing={1}>
        {items.map((item, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 3 }} key={item.name}>
            <DashboardCard
              name={item.name}
              link={item.link}
              icon={item.icon}
              description={item.description}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 4 }} />
    </Box>
  );
};
