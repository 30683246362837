import Header from '../../../../components/header';
import { Box, Container } from '@mui/material';
import { AppDispatch } from '../../../../store/store.ts';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchCompanyApprovals,
  selectCompanies,
} from '../../../../store/slices/companySlice.ts';
import { CompanyApprovalsCard } from './CompanyApprovalsCard.tsx';
import Grid from '@mui/material/Grid';

const CompanyApprovals = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedCompanies = useSelector(selectCompanies);
  useEffect(() => {
    dispatch(fetchCompanyApprovals());
  }, []);
  return (
    <Box>
      <Header
        title="Company Approvals"
        subtitle="Review and manage company approval requests"
        showBackButton={true}
      />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box>
          <Grid container spacing={2}>
            {selectedCompanies.map((company, index) => (
              <Grid item key={company.id} xs={12} sm={6} md={4} lg={3}>
                <CompanyApprovalsCard company={company} index={index} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
export default CompanyApprovals;
